import styled from "styled-components";

import ArticleSource from "../../../../../common/ArticleSource";
import { INTENT_SUB_EVENT } from "../../../../../../constants/events";

const SourcesContainer = styled.div`
  display: grid;
  grid-column-template: 1fr;
  width: 100%;
  margin-top: 1.25rem;
  row-gap: 0.25rem;
  overflow-x: hidden;
`;

function Sources({ articles, query }) {
  articles = Array.isArray(articles)
    ? articles
    : [];

  const filterCallback = (a) =>
    a?.url || !Number.isNaN(parseFloat(a?.version));
  const validArticles = articles.filter(filterCallback);

  const sourceRenderCallback = (article) => (
    <ArticleSource
      key={`${article.articleId || article.aid}-${article.chunk_id}`}
      query={query}
      eventSubtype={INTENT_SUB_EVENT}
      article={article} />
  );

  return validArticles?.length > 0 && (
    <SourcesContainer>
      {validArticles.map(sourceRenderCallback)}
    </SourcesContainer>
  );
}

export default Sources;
