import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";

import { Provider } from "react-redux";
import { store } from "./redux/store";

import App from "./App";

import reset from "./reset.css";

const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

ReactDOM.render(
  <>
    <Provider store={store}>
      <GlobalStyle />
      <App />
    </Provider>
  </>,
  document.getElementById('root')
);
