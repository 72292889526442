import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTransition, animated } from "@react-spring/web";

import Navbar from "./Navbar";
import MainContent from "./MainContent";
import { WIDGET_SIZE_EXPANDED } from "../../constants/widget";
import { DEFAULT_WIDGET_OFFSET } from "../../constants/defaults";
import { WEB } from "../../constants/platform";

const WIDGET_RISE = 24;

const WidgetContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  ${({ orientation }) => orientation}: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: ${({ theme }) => theme.fontFamily};
  transition: padding-${({ orientation }) => orientation} 200ms ease-in-out;
`;

const WidgetContainerForWeb = styled(WidgetContainer)`
  padding: 1rem ${DEFAULT_WIDGET_OFFSET.right}px ${
    DEFAULT_WIDGET_OFFSET.bottom + WIDGET_RISE
  }px ${DEFAULT_WIDGET_OFFSET.left}px;
  ${({ isWidgetExpanded, orientation }) =>
    isWidgetExpanded
      ? `padding-${orientation}: 2px;`
      : ""
  }
`;

const ShadowDiv = styled(animated.div)`
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  ${({ theme, platform }) => platform?.startsWith("mobile") || platform?.startsWith("tablet")
    ? ""
    : `border-radius: ${
      theme.widgetBorderRadius
    };box-shadow: 0 0 0.25rem rgba(0,0,0,0.25);` }
  overflow: hidden;
  transform-origin: bottom ${({ orientation }) => orientation};
`;

function ChatWidget({ isWidgetOpen, toggleWidgetOpen }) {
  const platform = useSelector((state) => state?.org?.platform);
  const widgetSize = useSelector((state) => state?.content?.widgetSize);
  const orientation = useSelector((state) => state?.content?.widgetOrientation);

  const openCloseTransistion = useTransition(isWidgetOpen, {
    from: { scale: 0, opacity: 0 },
    enter: { scale: 1, opacity: 1 },
    leave: { scale: 0, opacity: 0 }
  });

  const isWidgetExpanded = widgetSize === WIDGET_SIZE_EXPANDED;
  const Container = platform === WEB
    ? WidgetContainerForWeb
    : WidgetContainer;

  return (
    <Container
      isWidgetExpanded={isWidgetExpanded}
      orientation={orientation}
    >
      {openCloseTransistion((style, isWidgetOpen) => (
        isWidgetOpen && (
          <ShadowDiv style={style} orientation={orientation} platform={platform}>
            <Navbar toggleWidgetOpen={toggleWidgetOpen} />
            <MainContent isWidgetOpen={isWidgetOpen} />
          </ShadowDiv>
        )
      ))}
    </Container>
  );
}

export default ChatWidget;
