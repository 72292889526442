import { useEffect, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useSpring, animated, config } from "@react-spring/web";

import { markMessageAsRead } from "../../../../../../redux/actions/chatActions";
import { pSBC } from "../../../../../../lib/pSBC";
import Replies from "./Replies";

const Container = styled.div`
  display: flex;
  align-self: ${({ alignment }) => alignment};
  flex-direction: column;
  ${({alignment}) => alignment === "center"
    ? `
    max-width: 96%;
    min-width: 96%;
    ` : "max-width: 90%;"}
  row-gap: 0.5rem;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: ${({ rtl }) => rtl ? "row-reverse" : "row"};
  column-gap: 0.5rem;
  justify-content: ${({ alignment }) => alignment};
  align-items: center;
  width: 100%;
`;

const Avatar = styled.img`
  align-self: flex-end;
  width: 2rem;
  max-height: 2.75rem;
  flex-shrink: 0;
`;

const MessageContent = styled(animated.div)`
  box-sizing: border-box;
  max-width: min(100%, 580px);
  ${({ alignment }) => alignment !== "center"
    ? "padding: 1rem 0.75rem;"
    : "box-shadow: 0 0 0.5rem rgba(0,0,0,0.15);"}
  border-radius: 1rem;
  line-height: 1.5;
  font-size: ${({ theme }) => theme.md};
  background-color: ${({ alignment, theme }) => {
    switch(alignment) {
      case "center":
        return "transparent";
      case "flex-start":
        return theme.secondaryColor;
      default:
        return pSBC(0.8, theme.primaryColor);
    }
  }};

  transform-origin: bottom ${({ alignment }) => {
    switch(alignment) {
      case "flex-start":
        return "left";
      case "flex-end":
        return "right";
      default:
        return "center";
    }
  }};
  ${({ alignment }) => alignment === "center"
    ? "flex-grow: 1;"
    : ""}
`;

const RepliesHelpText = styled.p`
  box-sizing: border-box;
  padding-left: 2.5rem;
  text-align: center;
  color: ${({ theme }) => theme.lightGrey};
  font-size: ${({ theme }) => theme.sm};
  line-height: 1.25;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LATEST_MESSAGE_DURATION = 500; //ms

function Message({
  rtl,
  messageId,
  containerRef,
  alignment,
  avatar,
  timestamp,
  replies,
  repliesHelpText,
  localizeText,
  children
}) {
  const unreadMessages = useSelector((state) => state?.chat?.unreadMessages);
  const [springs, api] = useSpring(() => ({}));

  const messageRef = useRef(null);

  const dispatch = useDispatch();

  const observeUnreadMessage = () => {
    if (!messageId || !containerRef.current || !messageRef.current) {
      return;
    }
    if (!unreadMessages.some((msg) => msg.id === messageId)) {
      return;
    }

    const intersectionThreshold = 0.05;
    const callback = (entries, observer) => {
      if (entries.length === 0) {
        return;
      }
      if (entries[0].intersectionRatio >= intersectionThreshold) {
        dispatch(markMessageAsRead(messageId));
        observer.unobserve(messageRef.current)
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: containerRef.current,
      rootMargin: "0px",
      threshold: intersectionThreshold
    });
    observer.observe(messageRef.current);

    return () => observer.disconnect();
  };

  useEffect(observeUnreadMessage, [messageId, unreadMessages.length]);

  useEffect(() => {
    if (Number.isNaN(timestamp)) {
      return;
    }
    const timeNow = Date.now();
    if (timeNow - timestamp <= LATEST_MESSAGE_DURATION) {
      api.start({
        from: {
          opacity: 0,
          scale: 0
        },
        to: {
          opacity: 1,
          scale: 1
        },
        config: config.default
      });
    }
  }, [timestamp, api]);

  return (
    <Container alignment={alignment} ref={messageRef}>
      <MessageContainer rtl={rtl} alignment={alignment}>
        {avatar && (
          <Avatar src={avatar} />
        )}
        <MessageContent alignment={alignment} style={springs}>
          {children}
        </MessageContent>
      </MessageContainer>
      {replies?.length > 0 && (
        <>
          {repliesHelpText && (
            <RepliesHelpText>
              {localizeText(repliesHelpText)}
            </RepliesHelpText>
          )}
          <Replies replies={replies} localizeText={localizeText} />
        </>
      )}
    </Container>
  );
}

const MemoizedMessage = memo(Message);

export default MemoizedMessage;
