export const STRING_FIELD = "string";
export const EMAIL_FIELD = "email";
export const PHONE_NUMBER_FIELD = "phone number";
export const BOOL_FIELD = "boolean";
export const MULTIPLE_FIELD = "multiple";
export const NAME_FIELD = "name";
export const ADDRESS_FIELD = "address";
export const DATE_FIELD = "date";
export const TIME_FIELD = "time";
export const NUMBER_FIELD = "number";
export const TEXTAREA_FIELD = "text";
export const DATE_TIME_GRID = "datetime"

export const VALID_FIELD_TYPES = [
  STRING_FIELD,
  EMAIL_FIELD,
  PHONE_NUMBER_FIELD,
  BOOL_FIELD,
  MULTIPLE_FIELD,
  NAME_FIELD,
  ADDRESS_FIELD,
  DATE_FIELD,
  TIME_FIELD,
  NUMBER_FIELD,
  TEXTAREA_FIELD,
  DATE_TIME_GRID
];
