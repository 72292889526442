import { useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import useLocalization from "../../../../../../../hooks/localization";

const Container = styled.div`
  position: relative;
`;

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  padding: 0.5rem;
  transform: translate(-50%, -50%);
  border-radius: 1.5rem;
  background-color: rgba(0,0,0,0.25);
  z-index: 1;
`;

const iconStyle = {
  color: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  fontSize: "1.75rem",
  padding: "0.25rem",
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
  backgroundColor: "rgba(0,0,0,0.25)",
  zIndex: 1,
  cursor: "pointer"
};

function Image({ status, url, name }) {
  const tooltips = useSelector(
    (state) => state?.org?.customization?.tooltips?.attachments
  );

  const { localizeTooltips } = useLocalization();
  const localizedTooltips = localizeTooltips(tooltips);

  const isLoading = status?.isLoading;
  const failed = status?.failed;

  const download = () => {
    if (isLoading || failed) {
      return;
    }
    try {
      const anchor = document.createElement("a");
      anchor.setAttribute("href", url);
      anchor.setAttribute("download", name);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to download uploaded file!");
    }
  };

  return (
    <Container>
      <StyledImg src={url} alt={name} onClick={download} />
      {isLoading && (
        <IconContainer>
          <CircularProgress size={28} sx={{ color: "#fff" }} />
        </IconContainer>
      )}
      {failed && (
        <Tooltip title={localizedTooltips?.uploadFailed}>
          <ErrorOutlineRoundedIcon sx={iconStyle} />
        </Tooltip>
      )}
      {(!failed && !isLoading) && (
        <Tooltip title={localizedTooltips?.download}>
          <FileDownloadRoundedIcon sx={iconStyle} />
        </Tooltip>
      )}
    </Container>
  );
}

export default Image;
