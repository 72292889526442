import styled from "styled-components";

import {
  StyledTextField,
  StyledLabel,
  FieldContainer
} from "../../../../../../common/formFields";
import FieldError from "../../../../../../common/FieldError";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.75rem;
`;

function DateField({ data, value, error, disabled, updateFieldState }) {
  const handleChange = (event) => {
    updateFieldState(data?.field, event.target.value);
  };

  return (
    <Container>
      <StyledLabel htmlFor={data?.field}>
        {data?.message || prepareLabel(data?.field)}
      </StyledLabel>
      <FieldContainer>
        <StyledTextField
          id={data?.field}
          type={"date"}
          value={value}
          placeholder={data?.message || ""}
          disabled={disabled}
          onChange={handleChange} />
        {error?.length > 0 && (
          <FieldError message={error} />
        )}
      </FieldContainer>
    </Container>
  );
}

export default DateField;
