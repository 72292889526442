import { useState, useEffect, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

import StringField from "./StringField";
import BooleanField from "./BooleanField";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";
import NameField from "./NameField";
import SelectField from "./SelectField";
import AddressField from "./AddressField";
import DateField from "./DateField";
import TimeField from "./TimeField";
import NumberField from "./NumberField";
import TextareaField from "./TextareaField";
import DateTimeGrid from "./DateTimeGrid";
import {
  StyledCancelButton,
  StyledSubmitButton
} from "../../../../../common/formFields";
import FieldError from "../../../../../common/FieldError";

import { WebSocketContext } from "../../../../../../App";
import {
  clearActiveForm,
  setFormFields,
  setFormState
} from "../../../../../../redux/actions/formActions";
import {
  addConversation,
  logEventRecord,
  setBotTyping,
  workflowExecutionFinished
} from "../../../../../../redux/actions/chatActions";
import convertTime from "../../../../../../lib/convertTime";
import {
  getConfigValueByPersona
} from "../../../../../../lib/chat/getConfigValue";
import {
  ADDRESS_FIELD,
  BOOL_FIELD,
  EMAIL_FIELD,
  MULTIPLE_FIELD,
  NAME_FIELD,
  PHONE_NUMBER_FIELD,
  STRING_FIELD,
  DATE_FIELD,
  TIME_FIELD,
  NUMBER_FIELD,
  TEXTAREA_FIELD,
  VALID_FIELD_TYPES,
  DATE_TIME_GRID
} from "../../../../../../constants/form";
import {
  stringFieldValidator,
  numberFieldValidator,
  emailFieldValidator,
  phoneFieldValidator,
  multipleFieldValidator,
  dateFieldValidator,
  timeFieldValidator,
  textareaFieldValidator,
  dateTimeGridValidator
} from "../../../../../../lib/form/validators";
import {
  FORM_CANCEL_EVENT,
  FORM_SUBMIT_EVENT,
  WORKFLOW_SUB_EVENT
} from "../../../../../../constants/events";
import { AI_BOT } from "../../../../../../constants/user";
import { MESSAGE_RECEIVED } from "../../../../../../constants/chat";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1rem;
  width: 100%;
`;

const FormContainer = styled(Container)`
  padding: ${({ noHeader }) => noHeader ? "1.25rem" : "0"} 1rem 1rem;
  row-gap: 1.5rem;
`;

const ButtonContainer = styled(Container)`
  padding-bottom: 1rem;
`;

const Title = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  column-gap: 0.25rem;
  padding: 0.5rem 0.875rem;
  color: white;
  background-color: black;
  font-size: ${({ theme }) => theme.lg};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
  border-radius: 1rem 1rem 0 0;
  user-select: none;
`;

const EmptyDiv = styled.div`
  user-select: none;
`;

const ERROR_MESSAGE = "Something's not quite right. Please double-check your input and try submitting again.";

const NO_VALIDATOR = () => {};

const FIELDS = {
  [BOOL_FIELD]: {
    component: BooleanField,
    validator: NO_VALIDATOR,
    defaultValue: false
  },
  [EMAIL_FIELD]: {
    component: EmailField,
    validator: emailFieldValidator,
    defaultValue: ""
  },
  [MULTIPLE_FIELD]: {
    component: SelectField,
    validator: multipleFieldValidator,
    defaultValue: ""
  },
  [PHONE_NUMBER_FIELD]: {
    component: PhoneField,
    validator: phoneFieldValidator,
    defaultValue: ""
  },
  [STRING_FIELD]: {
    component: StringField,
    validator: stringFieldValidator,
    defaultValue: ""
  },
  [DATE_FIELD]: {
    component: DateField,
    validator: dateFieldValidator,
    defaultValue: ""
  },
  [TIME_FIELD]: {
    component: TimeField,
    validator: timeFieldValidator,
    defaultValue: ""
  },
  [NUMBER_FIELD]: {
    component: NumberField,
    validator: numberFieldValidator,
    defaultValue: ""
  },
  [TEXTAREA_FIELD]: {
    component: TextareaField,
    validator: textareaFieldValidator,
    defaultValue: ""
  },
  [DATE_TIME_GRID]: {
    validator: dateTimeGridValidator
  },
  [ADDRESS_FIELD]: {
    validator: NO_VALIDATOR
  },
  [NAME_FIELD]: {
    validator: NO_VALIDATOR
  }
}

function DynamicForm({ options }) {
  const [errorState, setErrorState] = useState({});

  const chatConfig = useSelector(
    (state) => state?.org?.customizations?.chatConfig
  );
  const workflowConfig = useSelector(
    (state) => state?.org?.customizations?.workflowConfig
  );
  const endUser = useSelector((state) => state?.chat?.endUser);
  const activeForm = useSelector((state) => state?.form?.activeForm);
  const forms = useSelector((state) => state?.form?.forms);
  const orgDetails = useSelector((state) => state?.org?.details);
  const currentWorkflow = useSelector((state) => state?.chat?.currentWorkflow);

  const { sendJsonMessage } = useContext(WebSocketContext);

  const dispatch = useDispatch();

  const { fields, state } = forms?.[options?.id] ?? {};
  const isFormInactive = activeForm !== options?.id;
  const formsConfig = workflowConfig?.forms;

  const initializeForm = () => {
    if (forms[options?.id]) {
      return;
    }
    const filterCallback =
      (f) => VALID_FIELD_TYPES.some((ft) => ft === f?.type?.toLowerCase());
    dispatch(setFormFields(
      options.id,
      options?.fields?.filter(filterCallback),
      formsConfig?.autoPopulate
        ? endUser
        : {}
    ));
  };

  useEffect(initializeForm, [options?.id]);

  const checkFormForErrors = () => {
    const errors = {};

    for (const f of fields) {
      const ftype = f?.type?.toLowerCase();
      const validation = f?.validation || { isOptional: false };

      errors[f?.field] = FIELDS?.[ftype]?.validator(
        state?.[f?.field] ?? "",
        validation
      );
    }

    return errors;
  };

  const logEvent = (eventType, title = "") => {
    const meta = {
      id: currentWorkflow?.id,
      title: currentWorkflow?.title,
      type: currentWorkflow?.type,
      version: currentWorkflow?.version
    };
    dispatch(logEventRecord(
      "",
      null,
      uuid(),
      eventType,
      WORKFLOW_SUB_EVENT,
      true,
      title,
      null,
      meta
    ));
  };

  const submitForm = () => {
    const errors = checkFormForErrors();

    setErrorState(errors);
    const filterCallback = (e) => !!errors?.[e]?.length;
    if (Object.keys(errors)?.filter(filterCallback)?.length > 0) {
      return;
    }

    let formState = {
      ...state
    };

    for (const f in formState) {
      if (typeof(formState[f]) !== "string") {
        continue;
      }
      formState[f] = formState[f].trim();
    }

    for (const f of fields) {
      switch (f?.type?.toLowerCase()) {
        case DATE_FIELD:
          formState = {
            ...formState,
            [f?.field]: formState?.[f?.field]
              ? formState?.[f?.field]
              : "N/A",
            [`${f?.field}_timezone`]: f?.timezone ?? "N/A",
            [`${f?.field}_user_locale`]:
              Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "N/A"
          };
          break;
        case TIME_FIELD:
          formState = {
            ...formState,
            [f?.field]: formState?.[f?.field]
              ? convertTime(formState?.[f?.field] ?? "")
              : "N/A",
            [`${f?.field}_timezone`]: f?.timezone ?? "N/A",
            [`${f?.field}_user_locale`]:
              Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "N/A"
          };
          break;
        case EMAIL_FIELD:
          formState[f?.field] = (formState?.[f?.field] ?? "").toLowerCase();
          break;
        default:
          break;
      }
    };

    const jsonMessage = {
      action: "sendMessage",
      connected_to: options?.connectionId,
      orgid: orgDetails?.id,
      type: "stateMachineReply",
      workflowId: currentWorkflow?.id,
      endUser,
      message: {
        replies: formState
      }
    };

    const recordTitle = `Workflow Name: ${
      currentWorkflow?.title || "<Missing Title>"
    }\nForm Name: ${options?.name || "Secure Form"}`;

    sendJsonMessage(jsonMessage);
    dispatch(clearActiveForm());
    dispatch(setBotTyping(true));
    logEvent(FORM_SUBMIT_EVENT, recordTitle);
  };

  const displayCancellationMsg = () => {
    const onWorkflowCancelled = getConfigValueByPersona(
      chatConfig,
      "onWorkflowCancelled",
      endUser?.personas
    );
    const opener = getConfigValueByPersona(
      chatConfig,
      "opener",
      endUser?.personas
    );
    const replies = Array.isArray(onWorkflowCancelled?.replies)
      ? onWorkflowCancelled.replies
      : [];
    dispatch(addConversation({
      type: AI_BOT,
      subtype: MESSAGE_RECEIVED,
      message: onWorkflowCancelled?.phrase,
      repliesHelpText: onWorkflowCancelled?.repliesHelpText,
      replies: onWorkflowCancelled?.showInitialPrompts && !replies?.length
        ? opener?.replies
        : replies
    }));
  };

  const cancelWorkflow = () => {
    const jsonMessage = {
      action: "stopStateMachine",
      connected_to: options?.connectionId,
      execution_arn: options?.executionArn,
      orgid: orgDetails?.id,
      workflowId: currentWorkflow?.id,
      endUser,
      message: {
        replies: state
      }
    };
    const recordTitle = `Workflow Name: ${
      currentWorkflow?.title || "<Missing Title>"
    }\nForm Name: ${options?.name || "Secure Form"}`

    sendJsonMessage(jsonMessage);
    dispatch(setBotTyping(true));
    dispatch(clearActiveForm());
    dispatch(workflowExecutionFinished());
    setTimeout(() => {
      dispatch(setBotTyping(false));
      displayCancellationMsg();
    }, 1_000);
    logEvent(FORM_CANCEL_EVENT, recordTitle);
  };

  const updateFieldState = useCallback((fieldName, newValue) => {
    if (errorState?.[fieldName]) {
      const errors = {
        ...errorState
      };
      delete errors[fieldName];

      setErrorState(errors);
    }
    dispatch(setFormState(options?.id, { [fieldName]: newValue }));
  }, [errorState, options?.id]);

  return (
    <Container>
      {formsConfig?.showHeader && (
        <Title>
          {formsConfig?.showIcon
            ? (
              <HttpsOutlinedIcon sx={{ fontSize: "inherit" }} />
            ) : (
              <EmptyDiv>&nbsp;</EmptyDiv>
            )}
          {formsConfig?.showTitle
            ? (
              <span>Secure Form</span>
            ) : (
              <EmptyDiv>&nbsp;</EmptyDiv>
            )}
          <EmptyDiv>&nbsp;</EmptyDiv>
        </Title>
      )}
      <FormContainer noHeader={!formsConfig?.showHeader}>
        {fields?.map((f) => {
          const ftype = f?.type?.toLowerCase();

          switch (ftype) {
            case NAME_FIELD:
              if (f?.fields?.length !== 2) {
                return null;
              }
              return (
                <NameField
                  key={"Name Fields"}
                  id={options?.id}
                  data={f?.fields}
                  formState={state || {}}
                  errorState={errorState || {}}
                  disabled={isFormInactive} />
              );
            case ADDRESS_FIELD:
              if (f?.fields?.length !== 5) {
                return null;
              }
              return (
                <AddressField
                  key={"Address fields"}
                  id={options?.id}
                  data={f?.fields}
                  formState={state || {}}
                  errorState={errorState || {}}
                  disabled={isFormInactive} />
              );
            case DATE_TIME_GRID:
              return (
                <DateTimeGrid
                  key={f?.field}
                  data={f}
                  formState={state || {}}
                  error={errorState?.[f?.field] || {}}
                  disabled={isFormInactive}
                  updateFieldState={updateFieldState} />
              );
            default:
              const {
                component: FieldComponent,
                defaultValue
              } = FIELDS?.[ftype] ?? {};

              if (!FieldComponent) {
                return null;
              }

              return (
                <FieldComponent
                  key={f?.field}
                  data={f}
                  endUser={endUser}
                  fieldsConfig={formsConfig?.fields}
                  value={state?.[f?.field] || defaultValue}
                  error={errorState?.[f?.field]}
                  disabled={isFormInactive}
                  updateFieldState={updateFieldState} />
              );
          }
        })}
        {!isFormInactive && (
          <ButtonContainer>
            <div style={{ width: "100%" }}>
              <StyledSubmitButton onClick={submitForm}>
                Submit
              </StyledSubmitButton>
              {Object.keys(errorState)?.length > 0 && (
                <FieldError message={ERROR_MESSAGE} />
              )}
            </div>
            {options?.executionArn?.length > 0 && (
              <StyledCancelButton onClick={cancelWorkflow}>
                Cancel
              </StyledCancelButton>
            )}
          </ButtonContainer>
        )}
      </FormContainer>
    </Container>
  );
}

export default DynamicForm;
