import uuid from "react-uuid";

import { uploadAttachmentBySystem } from "../../api";
import { addConversation } from "./chatActions";
import { END_USER } from "../../constants/user";
import { MESSAGE_SENT } from "../../constants/chat";

export const attachmentActions = {
  RESET_ATTACHMENTS: "RESET_ATTACHMENTS",

  UPLOAD_ATTACHMENT_BEGIN: "UPLOAD_ATTACHMENT_BEGIN",
  UPLOAD_ATTACHMENT_SUCCESS: "UPLOAD_ATTACHMENT_SUCCESS",
  UPLOAD_ATTACHMENT_ERROR: "UPLOAD_ATTACHMENT_ERROR"
};

export const resetAttachments = () => {
  return { type: attachmentActions.RESET_ATTACHMENTS };
};

export const uploadAttachment = (file) => {
  return (dispatch, getState) => {
    const messageId = uuid();

    const onError = () => {
      console.warn(`Readyly ChatAssist: failed to upload attachment "${file?.name}"`);
      dispatch({
        type: attachmentActions.UPLOAD_ATTACHMENT_ERROR,
        payload: { id: messageId }
      });
    };

    try {
      const reduxState = getState();
      const orgDetails = reduxState?.org?.details;
      const chatMedium = reduxState?.chat?.chatMedium;
      const conversationId = reduxState?.chat?.chatRecordId;

      const fileReader = new FileReader();

      fileReader.onerror = onError;
      fileReader.onloadend = async () => {
        const b64String = fileReader.result;
        const index = b64String.indexOf(";base64,") + 8;

        try {
          const response = await uploadAttachmentBySystem(
            orgDetails?.id,
            conversationId,
            chatMedium,
            b64String.slice(index),
            file.type,
            file.name,
            {
              orgName: reduxState?.org?.subdomain,
              conversationId,
              parentOrigin: reduxState?.org?.parentOrigin,
              platform: reduxState?.org?.platform,
              profile: reduxState?.org?.profile
            }
          );
          dispatch({
            type: attachmentActions.UPLOAD_ATTACHMENT_SUCCESS,
            payload: { id: messageId }
          });
        } catch(error) {
          onError();
        }
      };

      dispatch({
        type: attachmentActions.UPLOAD_ATTACHMENT_BEGIN,
        payload: { id: messageId }
      });
      fileReader.readAsDataURL(file);
      dispatch(addConversation({
        id: messageId,
        type: END_USER,
        subtype: MESSAGE_SENT,
        message: file.name,
        attachment: {
          url: URL.createObjectURL(file),
          mimeType: file.type,
          name: file.name,
          local: true
        }
      }));
    } catch(error) {
      onError();
    }
  };
};
