const SLACK_PERSON_EMOJIS = [
  ":person_in_tuxedo:",
  ":bearded_person:",
  ":person_with_headscarf:",
  ":curly_haired_person:",
  ":red_haired_person:",
  ":white_haired_person:",
  ":person_with_blond_hair:",
]

export function getEndUserSlackIcon() {
  return SLACK_PERSON_EMOJIS[
    Math.floor(Math.random() * SLACK_PERSON_EMOJIS.length)
  ];
}
