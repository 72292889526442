import { AGENT } from "../../constants/user";

function formatDate(date) {
  const str = `${date.getUTCFullYear()}-${
    String(date.getUTCMonth() + 1).padStart(2, '0')
  }-${String(date.getUTCDate()).padStart(2, '0')}`;
  return str;
}

function formatTime(date) {
  const str = `${String(date.getUTCHours()).padStart(2, '0')}:${
    String(date.getUTCMinutes()).padStart(2, '0')
  }`;
  return str;
}

export function prepareChatHeader(user) {
  return `Chat Assist Conversation ${user} ${formatDate(new Date())}`;
}

export function stringifyMessage(message) {
  const str = `${formatTime(new Date(message.timestamp))}   ${message?.type}`;
  let content = message?.message;

  if (message?.sources?.length > 0) {
    content = `${content}\n${
      message.sources
        .filter((s) => !!(s?.url || s?.title))
        .map((s, i) => `${i + 1}. ${s.url || s?.title}`)
        .join("\n")
    }`;
  }
  if (message?.type === AGENT
    && typeof(message?.realName) === "string"
    && message.realName.length > 0
  ) {
    return `${str} : [${message.realName}] : ${content}`;
  }

  return `${str} : ${content}`;
}

export function mergeChatRecords(aiChat, agentChat, leftDetails) {
  let record = aiChat;

  if (agentChat?.length) {
    record += `\n\n${agentChat}`;
  }
  if (leftDetails?.length) {
    record += `\n\n${leftDetails}`;
  }

  return record;
}

export function endUserDetails(endUser) {
  if (!endUser || typeof(endUser) !== "object") {
    return "";
  }

  const { email, firstName, lastName, personas, fullName, ...rest } = endUser;
  let str = `Chat Assist Conversation End User Details\nEmail: ${
    email || ""
  }\nFirst Name: ${firstName || ""}\nLast Name: ${lastName || ""}`;

  for (const f in rest) {
    str += `\n${f}: ${rest[f]}`;
  }

  return str;
}
