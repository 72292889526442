import styled from "styled-components";
import { animated } from "@react-spring/web";

import InfoIcon from "@mui/icons-material/HelpOutlineRounded";
import WarningIcon from "@mui/icons-material/WarningAmberRounded";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";

const Container = styled(animated.div)`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  top: 0.75rem;
  left: 0.5rem;
  max-width: 80%;
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme, level }) => {
    switch(level) {
      case "error":
      case "warning":
      case "info":
      default:
        return theme.mediumGrey;
    }
  }};
  overflow: hidden;
  border-radius: 1rem;
  font-size: ${({ theme }) => theme.sm};
  color: #fff;
  line-height: 1.25;
  z-index: 1;
  transform-origin: left center;
`;

function Notification({ styles, message, level }) {
  const renderIcon = () => {
    const styles = {fontSize: "inherit", color: "inherit", alignSelf: "flex-start"};
    switch(level) {
      case "error":
        return (
          <ErrorIcon sx={styles} />
        );
      case "warning":
        return (
          <WarningIcon sx={styles} />
        );
      case "info":
      default:
        return (
          <InfoIcon sx={styles} />
        );
    }
  };

  return (
    <Container style={styles} level={level}>
      {renderIcon()}
      {message}
    </Container>
  );
}

export default Notification;