import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTheme } from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import { useSpring, animated, config } from "@react-spring/web";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';

import {
  LIKE,
  DISLIKE,
  NO_FEEDBACK
} from "../../../constants/feedback";
import useLocalization from "../../../hooks/localization";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: ${({ theme }) => theme.xs};
  color: ${({ theme }) => theme.mediumGrey};
  font-size: ${({ theme }) => theme.reg};
  line-height: 1.25;
`;

const LikeButtonContainer = styled(animated.div)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.green};
  }
`;

const DislikeButtonContainer = styled(animated.div)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.red};
  }
`;

function FeedbackBar({ value, setFeedback }) {
  const [likeAnimation, likeApi] = useSpring(() => {});
  const [dislikeAnimation, dislikeApi] = useSpring(() => {});

  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.feedback
  );

  const theme = useTheme();
  const { localizeText } = useLocalization();

  const animate = (api, translateY, rotate) => {
    api.start({
      from: {
        transform: `scale(1.1) translateY(${translateY}) rotate(${rotate})`,
        opacity: "0.75"
      },
      to: {
        transform: "scale(1) translateY(0) rotate(0deg)",
        opacity: "1.0"
      },
      config: config.wobbly
    });
  };

  const submitFeedback = (newValue, eventType) => {
    if (newValue === value) {
      if (newValue === LIKE) {
        animate(likeApi, "5px", "20deg");
      }
      if (newValue === DISLIKE) {
        animate(dislikeApi, "-5px", "20deg");
      }
      setFeedback(NO_FEEDBACK, eventType);
      return;
    }

    setFeedback(newValue, eventType);

    if (newValue === LIKE) {
      animate(likeApi, "-5px", "-20deg");
    }
    if (newValue === DISLIKE) {
      animate(dislikeApi, "5px", "-20deg");
    }
  };

  return (
    <Container>
      <div>
        {localizeText(tooltips?.cta)}
      </div>
      <Tooltip title={localizeText(tooltips?.like)} placement="top">
        <LikeButtonContainer
          style={likeAnimation}
          onClick={() => submitFeedback(LIKE, "like")}
        >
          {value === 1
            ? (
              <ThumbUpIcon sx={{ fontSize: theme.md, color: theme.green }} />
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: theme.md, color: "inherit" }} />
            )}
        </LikeButtonContainer>
      </Tooltip>
      <Tooltip title={localizeText(tooltips?.dislike)} placement="top">
        <DislikeButtonContainer
          style={dislikeAnimation}
          onClick={() => submitFeedback(DISLIKE, "dislike")}
        >
          {value === -1
            ? (
              <ThumbDownIcon sx={{ fontSize: theme.md, color: theme.red }} />
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: theme.md, color: "inherit" }} />
            )}
        </DislikeButtonContainer>
      </Tooltip>
    </Container>
  );
};

export default FeedbackBar;
