import { useEffect } from "react";
import styled from "styled-components";

import {
  HiddenLabel,
  StyledTextField,
  FieldContainer
} from "../../../../../../common/formFields";
import FieldError from "../../../../../../common/FieldError";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

import { EMAIL_REGEX_STRING } from "../../../../../../../constants/regex";

const regex = new RegExp(EMAIL_REGEX_STRING);

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.75rem;
`;

function EmailField({
  data,
  value,
  error,
  disabled,
  endUser,
  fieldsConfig,
  updateFieldState
}) {
  const config = fieldsConfig?.email;
  const email = typeof(endUser?.email) === "string"
    ? endUser.email
    : "";
  const noEdit = regex.test(email) && config?.noEdit;

  const handleChange = (event) => {
    if (noEdit) {
      updateFieldState(data?.field, endUser?.email);
      return;
    }
    updateFieldState(data?.field, event.target.value);
  };

  useEffect(() => {
    if (noEdit && value !== email) {
      updateFieldState(data?.field, email);
    }
  }, [value, email]);

  return (
    <Container>
      <HiddenLabel htmlFor={data?.field}>
        {prepareLabel(data?.field)}
      </HiddenLabel>
      <FieldContainer>
        <StyledTextField
          id={data?.field}
          type={"email"}
          value={value}
          placeholder={data?.message || prepareLabel(data?.field)}
          disabled={disabled || noEdit}
          maxLength={255}
          onChange={handleChange} />
        {error?.length > 0 && (
          <FieldError message={error} />
        )}
      </FieldContainer>
    </Container>
  );
}

export default EmailField;
