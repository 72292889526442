import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { CHAT_ON_SLACK, CHAT_ON_SUNSHINE } from "../../../../../../../constants/chat";
import { downloadSlackAttachment } from "../../../../../../../api";
import { base64ToBlob } from "../../../../../../../lib/base64ToBlob";
import useLocalization from "../../../../../../../hooks/localization";

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.75rem;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const CardContent = styled.div`
  font-size: ${({ theme }) => theme.reg};
  color: ${({ theme }) => theme.fontColor};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function IncomingAttachment({ system, url, name, mimeType }) {
  const subdomain = useSelector((state) => state?.org?.subdomain);
  const chatRecordId = useSelector((state) => state?.chat?.chatRecordId);

  const orgId = useSelector((state) => state?.org?.details?.id);
  const tooltips = useSelector(
    (state) => state?.org?.customization?.tooltips?.attachments
  );

  const { localizeTooltips } = useLocalization();
  const localizedTooltips = localizeTooltips(tooltips);

  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(null);

  const theme = useTheme();

  const download = async () => {
    setDownloading(true);
    setError(null);

    const startDownload = (href, name = "download") => {
      const anchor = document.createElement("a");
      anchor.setAttribute("href", href);
      anchor.setAttribute("download", name);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    try {
      switch(system) {
        case CHAT_ON_SLACK: {
          const slackResponse = await downloadSlackAttachment(
            orgId,
            url,
            mimeType,
            name,
            {
              orgName: subdomain,
              conversationId: chatRecordId
            }
          );
          const b64String = slackResponse?.data?.b64String;
          const blob = base64ToBlob(b64String, mimeType);
          const objectUrl = URL.createObjectURL(blob);
          startDownload(objectUrl, name);
          URL.revokeObjectURL(objectUrl);
          break;
        }
        case CHAT_ON_SUNSHINE: {
          const response = await fetch(url);
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          startDownload(objectUrl, name);
          URL.revokeObjectURL(objectUrl);
          break;
        }
        default:
          throw new Error(`Cannot download file from "${system}"!`);
      }
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to download shared file!");
      setError(error.message);
    }

    setDownloading(false);
  };

  return (
    <Card onClick={download}>
      <InsertDriveFileRoundedIcon sx={{color: "#d62828", fontSize: "2rem"}} />
      <CardContent>
        {name}
      </CardContent>
      {downloading && (
        <CircularProgress size={24} sx={{color: theme.darkGrey}} />
      )}
      {error && (
        <Tooltip title={localizedTooltips?.downloadFailed} placement="top-end">
          <ErrorOutlineRoundedIcon sx={{color: theme.darkGrey, fontSize: "1.5rem"}} />
        </Tooltip>
      )}
      {(!downloading && !error) && (
        <Tooltip title={localizedTooltips?.download} placement="top-end">
          <FileDownloadRoundedIcon sx={{color: theme.darkGrey, fontSize: "1.5rem"}} />
        </Tooltip>
      )}
    </Card>
  );
}

export default IncomingAttachment;
