export const CHAT_WITH_BOT = "bot";
export const CHAT_WITH_AGENT = "agent";
export const CHAT_WITH_QNA_BOT = "qna";

export const MESSAGE_RECEIVED = "received";
export const MESSAGE_SENT = "sent";
export const MESSAGE_IS_FORM = "form";

export const CHAT_ON_SLACK = "slack";
export const CHAT_ON_FASTTRACK = "fasttrack";
export const CHAT_ON_AMAZON_CONNECT = "amazon_connect";
export const CHAT_ON_SUNSHINE = "sunshine";

export const INTERNAL_LIVE_CHAT_SYSTEMS = [CHAT_ON_SUNSHINE, CHAT_ON_SLACK];

export const NO_INTERACTION = 100;
export const ALTERNATE_INTERACTION = 105;
export const INTERACTED_WITH_AI = 110;
export const TRIED_SWITCH_TO_AGENT = 200;
export const INTERACTED_WITH_AGENT = 210;
export const NO_TICKET_CREATED = 300;
export const TRIED_CREATING_TICKET = 310;
export const TICKET_CREATED = 320;
export const TICKET_SKIPPED = TICKET_CREATED;

export const TICKET_TIMEOUT = 10 * 60 * 1000; // ms
export const RESET_TIMEOUT = 10.5 * 60 * 1000; // ms

export const AMZ_CONNECT_MESSAGE = "MESSAGE";
export const AMZ_CONNECT_EVENT = "EVENT";
export const AMZ_CONNECT_CUSTOMER_ROLE = "CUSTOMER";
export const AMZ_CONNECT_AGENT_ROLE = "AGENT";
export const AMZ_CONNECT_SYSTEM_ROLE = "SYSTEM";
export const AMZ_CONNECT_JOIN_EVENT = "application/vnd.amazonaws.connect.event.participant.joined";
export const AMZ_CONNECT_LEAVE_EVENT = "application/vnd.amazonaws.connect.event.participant.left";
export const AMZ_CONNECT_CHAT_ENDED_EVENT = "application/vnd.amazonaws.connect.event.chat.ended";
export const AMZ_CONNECT_TYPING_EVENT = "application/vnd.amazonaws.connect.event.typing";
export const AMZ_CONNECT_TEXT_PLAIN = "text/plain";
export const AMZ_CONNECT_TEXT_MARKDOWN = "text/markdown";
export const AMZ_CONNECT_SUPPORTED_TYPES = [AMZ_CONNECT_TEXT_PLAIN, AMZ_CONNECT_TEXT_MARKDOWN];

export const LIVE_CHAT_MESSAGE = "message";
export const LIVE_CHAT_CONVERSATION_SUCCESS = "create_conversation_success";
export const LIVE_CHAT_CONVERSATION_FAILED = "create_conversation_failed";
export const LIVE_CHAT_CONVERSATION_END = "end_conversation";
export const LIVE_CHAT_CONVERSATION_JOIN = "joined_conversation";
export const LIVE_CHAT_CONVERSATION_LEFT = "left_conversation";
export const LIVE_CHAT_FILE_SHARE = "file_share";
export const LIVE_CHAT_EVENT_SUCCESS = "event_success";
export const LIVE_CHAT_EVENT_FAILED = "event_failed";

export const CONVERSATION_TYPE_AI = "Resolved";
export const CONVERSATION_TYPE_AGENT = "Handoff";
export const CONVERSATION_TYPE_TICKET = "Ticket";

export const WORKFLOWS_ENABLED = true;
export const AUTO_EXECUTE_WORKFLOW = false;
export const MIN_WORKFLOW_CONFIDENCE = 0.80;
export const WORKFLOW_MATCH_CONFIDENCE = 0.90;

export const MIN_SOURCE_CONFIDENCE = 0.75;

export const WORKFLOW_START_NODE = "start";
export const WORKFLOW_END_NODE = "end";
export const WORKFLOW_MESSAGE_NODE = "message";
export const WORKFLOW_ERROR_NODE = "error";

export const SKIP_AUTO_TICKET_DEFAULT = false;

export const BUSINESS_HOURS_FROM = "00:00:00 GMT";
export const BUSINESS_HOURS_TO = "23:59:59 GMT";

export const TITLE_SEARCH_KB_SYSTEM = "title_search";
export const WEB_KB_SYSTEM = "webpage";

export const PICK_THE_LANGUAGE = "other";
export const AUTO_LANGUAGE_DETECTION = "auto";

export const ACCEPTED_ATTACHMENT_TYPES = [
  "image/png",
  "image/jpeg",
  "image/svg",
  "image/svg+xml",
  "image/webp",
  "image/gif",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/x-msword",
  "application/x-msexcel",
  "application/x-mspowerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
];

export const MAX_ATTACHMENT_SIZE = 3_000_000; // 3 MB
export const MAX_ATTACHMENT_DOWNLOAD_SIZE = 4_000_000; // 4 MB
