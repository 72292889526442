export const formActions = {
  SET_FORM_FIELDS: "SET_FORM_FIELDS",
  SET_FORM_STATE: "SET_FORM_STATE",
  CLEAR_ACTIVE_FORM: "CLEAR_ACTIVE_FORM"
};

export const setFormFields = (id, fields, initialValues = {}) => {
  return {
    type: formActions.SET_FORM_FIELDS,
    payload: {
      id,
      fields,
      initialValues
    }
  };
};

export const setFormState = (id, state) => {
  return {
    type: formActions.SET_FORM_STATE,
    payload: {
      id,
      state
    }
  };
};

export const clearActiveForm = () => {
  return {
    type: formActions?.CLEAR_ACTIVE_FORM
  };
};
