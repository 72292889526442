import produce from "immer";

import { mediaRecorderActions } from "../actions/mediaRecorderActions";

const initialState = {
  triedRecordingAudio: false,
  recordingAudio: false,
  canAccessDevices:
    typeof(navigator?.mediaDevices?.getUserMedia) === "function"
};

const mediaRecorderReducer = produce((draft, action) => {
  switch(action.type) {
    case mediaRecorderActions.AUDIO_RECORDING_BEGIN:
      draft.recordingAudio = true;
      draft.triedRecordingAudio = false;
      return;
    case mediaRecorderActions.AUDIO_RECORDING_SUCCESS:
      draft.recordingAudio = false;
      draft.triedRecordingAudio = false;
      return;
    case mediaRecorderActions.AUDIO_RECORDING_ERROR:
      draft.recordingAudio = false;
      draft.triedRecordingAudio = false;
      return;
    case mediaRecorderActions.AUDIO_RECORDING_ABORT:
      draft.recordingAudio = false;
      draft.triedRecordingAudio = false;
      return;
    case mediaRecorderActions.TRIED_AUDIO_RECORDING:
      draft.triedRecordingAudio = true;
      return;
    default:
      return;
  }
}, initialState);

export default mediaRecorderReducer;
