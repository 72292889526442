const DEFAULT_PERSONA = "default";
const RESERVED_NAMES = [
  DEFAULT_PERSONA,
  "phrase",
  "dynamicPhrase",
  "replies",
  "query",
  "title",
  "response",
  "userDetails",
  "workflowId",
  "versions",
  "linearGradient",
  "fontColor",
  "linkTo"
];

export function getConfigValueByPersona(config, name, personas) {
  if (!config || typeof(name) !== "string" || !config[name]) {
    return;
  }
  if (typeof(config[name]) !== "object" || Array.isArray(config[name])) {
    return config[name];
  }

  personas = Array.isArray(personas)
    ? personas
    : [];

  for (const p of personas) {
    if (RESERVED_NAMES.some((n) => n === `${p}`)) {
      console.warn(`FastTrack Assist: persona ${p} conflicts with a reserved name!`);
      continue;
    }

    for (const key of Object.keys(config[name])) {
      const values = key.split(" ");
      if (values.some((v) => v === p)) {
        return config[name][key];
      }
    }
  }

  return (DEFAULT_PERSONA in config[name])
    ? config[name][DEFAULT_PERSONA]
    : config[name];
}
