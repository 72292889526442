export const VALID_URL_REGEX = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/;

export const GPT_MISHIT_REGEX = /^(I'm not sure|I don't (know|understand)|Can you (rephrase|clarify)|I'm unsure|Please provide more information|The article does not|I can't (find|provide)|I could not (find|provide)|I couldn't (find|provide)|It's unclear).*/;

export const GPT_ASK_AGAIN_REGEX = /^I'm sorry, I don't have that information\.$/;

export const SUPPORTED_IFRAME_DOCS = /\.pdf(#page=\d+)?$/; // /\.(pdf|docx|xlsx)$/

export const MARKDOWN_LINKS = /\[([^\]]+)\]\(([^\)]+)\)/g;

export const MARKDOWN_BOLD = /\*\*([^\*]+)\*\*/g;

export const MARKDOWN_ITALIC = /__([^_]+)__/g;

export const MARKDOWN_EMPHASIS = /(___([^_]+)___|\*\*\*([^\*]+)\*\*\*)/g;

export const EMAIL_REGEX_STRING = '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const LINEAR_GRADIENT_REGEX = /^linear-gradient\(.+\)$/;

export const UTC_TIME_STRING_REGEX = /^\d{2}:\d{2}:\d{2}\sGMT$/;

export const DYNAMIC_VARIABLE_REGEX = /\{\{([^}{]+)\}\}/g;

export const VALID_LANGUAGE_REGEX_STRING = "^[a-zA-Z]{2,16}$";
