import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import useLocalization from "../../../../../../../hooks/localization";

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.75rem;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const CardContent = styled.div`
  font-size: ${({ theme }) => theme.reg};
  color: ${({ theme }) => theme.fontColor};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function DocumentCard({ status, url, name, local }) {
  const tooltips = useSelector(
    (state) => state?.org?.customization?.tooltips?.attachments
  );

  const theme = useTheme();
  const { localizeTooltips } = useLocalization();

  const localizedTooltips = localizeTooltips(tooltips);

  const isLoading = status?.isLoading;
  const failed = status?.failed;

  const download = () => {
    if (isLoading || failed) {
      return;
    }
    const anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", name);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    return;
  };

  return (
    <Card onClick={download}>
      <InsertDriveFileRoundedIcon sx={{color: "#d62828", fontSize: "2rem"}} />
      <CardContent>
        {name}
      </CardContent>
      {isLoading && (
        <CircularProgress size={24} sx={{color: theme.darkGrey}} />
      )}
      {failed && (
        <Tooltip title={localizedTooltips?.downloadFailed} placement="top-end">
          <ErrorOutlineRoundedIcon sx={{color: theme.darkGrey, fontSize: "1.5rem"}} />
        </Tooltip>
      )}
      {(!isLoading && !failed) && (
        <Tooltip title={localizedTooltips?.download} placement="top-end">
          <FileDownloadRoundedIcon sx={{color: theme.darkGrey, fontSize: "1.5rem"}} />
        </Tooltip>
      )}
    </Card>
  );
}

export default DocumentCard;
