import { useRef, useState, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { keyframes, css } from "styled-components";
import { useTransition, animated } from "@react-spring/web";
import { Tooltip } from "@mui/material";

import MicIcon from '@mui/icons-material/MicRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';

import Footer from "./Footer";
import Chat from "./Chat";
import Article from "./Article";
import Resources from "./Resources";
import Knowledge from "./Knowledge";
import Notification from "./Notification";
import { WebSocketContext } from "../../../App";
import useChat from "../../../hooks/chatHook";
import useLocalization from "../../../hooks/localization";
import {
  abortAudioRecording,
  audioRecordingEnd
} from "../../../redux/actions/mediaRecorderActions";
import {
  CHAT_SECTION,
  CONTENT_IS_ARTICLE,
  CONTENT_IS_CHAT,
  RESOURCES_SECTION,
  WIDGET_SIZE_EXPANDED
} from "../../../constants/widget";
import { STANDALONE_WEB } from "../../../constants/platform";

const MainContentContainer = styled.main`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.navbarHeight});
  ${({ theme, platform }) => platform?.startsWith("mobile") || platform?.startsWith("tablet")
    ? ""
    : `border-radius: 0 0 ${theme.widgetBorderRadius} ${theme.widgetBorderRadius};`}
  background-color: #fff;
  font-size: 1rem;
  overflow: hidden;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  width: 100%;
`;

const ContentColumn = styled.div`
  box-sizing: border-box;
  width: ${({ platform, isWidgetExpanded }) => {
    if (!isWidgetExpanded) {
      return "100%";
    }
    if (platform === STANDALONE_WEB) {
      return "clamp(calc(100% - 932px), 60%, calc(100% - 292px))";
    }
    return "clamp(calc(100% - 432px), 60%, calc(100% - 292px))";
  }};
  ${({ isWidgetExpanded, theme }) => {
    if (!isWidgetExpanded) {
      return;
    }
    return `border-right: 1px solid ${theme.veryLightGrey};`;
  }}
  ${({ isWidgetExpanded }) =>
    isWidgetExpanded && "padding-bottom: 1rem;"}
  height: 100%;
  overflow: hidden;
  transition: width 200ms ease-in-out;
`;

const ResoursesColumn = styled.div`
  box-sizing: border-box;
  width: ${({ platform, isWidgetExpanded }) => {
    if (!isWidgetExpanded) {
      return "100%";
    }
    if (platform === STANDALONE_WEB) {
      return "clamp(292px, 40%, 932px)";
    }
    return "clamp(292px, 40%, 432px)";
  }};
  height: 100%;
  overflow: hidden;
  transition: width 200ms ease-in-out;
`;

const RecorderOverlay = styled(animated.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.5rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  background-color: #fff;
  font-size: 4rem;
  color: ${({ theme }) => theme.primaryColor};
  user-select: none;
  transform-origin: bottom right;
`;

const HelpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  whitespace: nowrap;
`;

const RecordingHelpText = styled.p`
  font-size: ${({ theme }) => theme.lg};
  font-weight: 500;
  color: ${({ theme }) => theme.fontColor};
`;

const RecordingSubText = styled.p`
  font-size: ${({ theme }) => theme.md};
  color: ${({ theme }) => theme.darkGrey};
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 #808080ff;
  }
  70% {
    box-shadow: 0 0 0 25px #80808000;
  }
  100% {
    box-shadow: 0 0 0 50px #80808000;
  }
`;

const PulsatingContainer = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 100%;
  box-shadow: 0 0 0 0 #80808066;
  background-color: ${({ theme }) => theme.primaryColor};
  ${({ animate }) => animate && (css`
    -moz-animation: ${pulse} 1750ms infinite;
    -webkit-animation: ${pulse} 1750ms infinite;
    animation: ${pulse} 1750ms infinite;
  `)}
`;

const IconButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  align-self: flex-end;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.25rem;
  color: ${({ theme }) => theme.darkGrey};
  background-color: transparent;
  font-size: ${({ theme }) => theme.xl};
  box-shadow: none;
  text-transform: none;
  border: none;
  cursor: pointer;
`;

function MainContent({ isWidgetOpen }) {
  const [notification, setNotification] = useState(null);
  const notificationRef = useRef(null);

  const platform = useSelector((state) => state?.org?.platform);
  const orgDetails = useSelector((state) => state?.org?.details);
  const widgetSize = useSelector((state) => state?.content?.widgetSize);

  const currentSection = useSelector((state) => state?.content?.currentSection);
  const currentContent = useSelector((state) => state?.content?.currentContent);
  const customizations = useSelector((state) => state?.org?.customizations);

  const activeReplies = useSelector((state) => state?.chat?.activeReplies);

  const recording = useSelector((state) => state?.recorder?.recordingAudio);
  const triedRecording = useSelector((state) => state?.recorder?.triedRecordingAudio);

  const isWidgetExpanded = widgetSize === WIDGET_SIZE_EXPANDED;

  const webSocketContext = useContext(WebSocketContext);
  const { localizeText, localizeTooltips } = useLocalization();
  const {
    handleUserQuery,
    handleUserAttachment
  } = useChat(webSocketContext, orgDetails, activeReplies, localizeText);

  const dispatch = useDispatch();

  const recorderTransition = useTransition(recording || triedRecording, {
    from: { scale: 0.33, opacity: 0.25, borderRadius: "100% 0 0 0" },
    enter: [
      { scale: 1, opacity: 0.67, borderRadius: "100% 0 0 0" },
      { scale: 1, opacity: 1, borderRadius: "0 0 0 0" }
    ],
    leave: { scale: 0, opacity: 0, borderRadius: "100% 0 0 0" }
  });
  const notificationTransition = useTransition(!!notification?.message, {
    from: {opacity: 0, scaleX: 0},
    enter: {opacity: 0.9, scaleX: 1},
    leave: {opacity: 0, scaleX: 0}
  });

  const kbSearchEnabled = customizations?.enableKbSearch;
  const tooltips = localizeTooltips(customizations?.tooltips?.voiceChat);

  const renderCurrentContent = () => {
    if (currentSection === CHAT_SECTION) {
      if (currentContent === CONTENT_IS_CHAT) {
        return (
          <Chat
            isWidgetOpen={isWidgetOpen}
            handleUserQuery={handleUserQuery}
            showNotification={showNotification}
            handleUserAttachment={handleUserAttachment} />
        );
      }
      if (currentContent === CONTENT_IS_ARTICLE) {
        return (
          <Article showBackButton />
        );
      }
    }
    if (currentSection === RESOURCES_SECTION) {
      if (currentContent === CONTENT_IS_ARTICLE || isWidgetExpanded) {
        return (
          <Article showBackButton />
        );
      }
      return (
        <Knowledge />
      );
    }
  };

  const renderCurrentResources = () => {
    if (currentSection === CHAT_SECTION) {
      return (
        <Resources />
      );
    }
    if (currentSection === RESOURCES_SECTION) {
      return (
        <Knowledge />
      );
    }
  };

  const stopRecording = (evt) => {
    evt.stopPropagation();
    dispatch(audioRecordingEnd(handleUserQuery));
  };

  const abortRecording = (evt) => {
    evt.stopPropagation();
    dispatch(abortAudioRecording());
  };

  const showNotification = useCallback((message, level) => {
    const notify = () => {
      setNotification({ message, level });
      notificationRef.current = setTimeout(() => {
        notificationRef.current = null;
        setNotification(null);
      }, 3_000);
    };

    if (!notification) {
      notify();
      return;
    }

    clearTimeout(notificationRef.current);
    setNotification(null);

    setTimeout(() => {
      notify();
    }, 750);
  }, [notification]);

  return (
    <MainContentContainer platform={platform}>
      <Columns>
        <ContentColumn platform={platform} isWidgetExpanded={isWidgetExpanded}>
          {renderCurrentContent()}
        </ContentColumn>
        {isWidgetExpanded && (
          <ResoursesColumn platform={platform} isWidgetExpanded={isWidgetExpanded}>
            {renderCurrentResources()}
          </ResoursesColumn>
        )}
      </Columns>
      <Footer
        showSectionSelector={kbSearchEnabled}
        isExpanded={isWidgetExpanded} />
      {notificationTransition((styles, displayNotification) => (
        displayNotification && (
          <Notification
            styles={styles}
            message={notification?.message}
            level={notification?.level} />
        )
      ))}
      {recorderTransition(({ opacity, ...rest }, displayOverlay) => (
        displayOverlay && (
          <RecorderOverlay style={{ opacity, ...rest }} onClick={stopRecording}>
            {opacity.get() === 1 && (
              <Tooltip title={tooltips?.cancel} placement="bottom-end">
                <IconButton onClick={abortRecording}>
                  <CloseIcon sx={{ fontSize: "inherit", color: "inherit" }} />
                </IconButton>
              </Tooltip>
            )}
            <PulsatingContainer animate={recording && opacity.get() === 1}>
              <MicIcon sx={{ fontSize: "inherit", color: "#fff" }}/>
            </PulsatingContainer>
            {opacity.get() > 0.75 && triedRecording && (
              <HelpTextContainer>
                <RecordingHelpText>
                  {tooltips?.allowMicAccess}
                </RecordingHelpText>
              </HelpTextContainer>
            )}
            {opacity.get() > 0.75 && recording && (
              <HelpTextContainer>
                <RecordingHelpText>
                  {tooltips?.listening}
                </RecordingHelpText>
                <RecordingSubText>
                  {tooltips?.clickToStop}
                </RecordingSubText>
              </HelpTextContainer>
            )}
          </RecorderOverlay>
        )
      ))}
    </MainContentContainer>
  );
}

export default MainContent;
