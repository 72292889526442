import { EMAIL_REGEX_STRING } from "../../constants/regex";
import { FULL_DAY_NAMES } from "../../constants/time";

const EMPTY_FIELD_WARNING = "Please fill out this field!";
const EMPTY_DATE_TIME_WARNING = "Please select a preferred date and time";

function helpMessage(validation, defaultMessage) {
  const customMessage = validation?.helpinfo;
  return typeof(customMessage) === "string" && customMessage?.length > 0
    ? customMessage
    : defaultMessage;
}

export function stringFieldValidator(value, validation) {
  if (!validation?.isOptional && !value?.length) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }
  if (!Number.isNaN(parseInt(validation?.minLength))
      && value?.length < validation?.minLength) {
    return helpMessage(
      validation,
      `Minimum ${validation?.minLength} characters required!`
    );
  }
  if (!Number.isNaN(parseInt(validation?.maxLength))
      && value?.length > validation?.maxLength) {
    return helpMessage(
      validation,
      `Should not exceed ${validation?.maxLength} characters!`
    );
  }
  if (typeof(validation?.regex) === "string"
      && validation?.regex?.length > 0) {
    try {
      const regex = new RegExp(validation?.regex);
      if (!regex.test(value)) {
        return helpMessage(
          validation,
          `Should match regular expression: ${validation?.regex}`
        );
      }
    } catch (error) {
      console.warn("FastTrack Assist: Malformed regex string found!");
    }
  }

  return null;
}

export function numberFieldValidator(value, validation) {
  if (!validation?.isOptional && !value?.length) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }
  if (!Number.isNaN(parseInt(validation?.minValue))
      && parseInt(value) < parseInt(validation?.minValue)) {
    return helpMessage(
      validation,
      `Should be greater than or equal to ${validation?.minValue}!`
    );
  }
  if (!Number.isNaN(parseInt(validation?.maxValue))
      && parseInt(value) > parseInt(validation?.maxValue)) {
    return helpMessage(
      validation,
      `Should not exceed ${validation?.maxValue}!`
    );
  }

  return null;
}

export function emailFieldValidator(value, validation) {
  if (!validation?.isOptional && !value?.length) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }

  const regex = new RegExp(EMAIL_REGEX_STRING);
  if (!regex.test(value)) {
    return helpMessage(validation, "Please enter a valid email address!");
  }

  return null;
}

export function phoneFieldValidator(value, validation) {
  if (!validation?.isOptional && !value?.length) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }

  return null;
}

export function multipleFieldValidator(value, validation) {
  if (!validation?.isOptional && !value?.length) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }

  return null;
}

export function dateFieldValidator(value, validation) {
  if (!validation?.isOptional && value?.length !== 10) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }

  // Adding time to prevent time zone conversion to GMT/UTC
  const today = new Date(`${value} 00:00:00`);
  if (today?.toString() === "Invalid Date") {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }

  const allDays = [0,1,2,3,4,5,6];
  const convertToInt = (n) => parseInt(n);
  const isDayValid = (d) => 0 <= d && d <= 6;

  const validDays = Array.isArray(validation?.validDays)
    ? validation.validDays.map(convertToInt).filter(isDayValid)
    : allDays;

  if (!(validDays.length === 0 || validDays.length === 7)
    && !validDays.some((d) => parseInt(d) === today.getDay())
  ) {
    const days = validDays.map((d) => FULL_DAY_NAMES[d]);
    return days.length > 1
      ? `Please select a date that falls on either ${
          days.slice(0, days.length - 1).join(", ")
        } or ${days[days.length - 1]}`
      : `Please select a date that falls on ${days[0]}`;
  }

  return null;
}

export function timeFieldValidator(value, validation) {
  if (!validation?.isOptional && value?.length !== 5) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }

  return null;
}

export function textareaFieldValidator(value, validation) {
  if (!validation?.isOptional && !value?.length) {
    return helpMessage(validation, EMPTY_FIELD_WARNING);
  }

  return null;
}

export function dateTimeGridValidator(value, validation) {
  if (!validation?.isOptional && !value?.length) {
    return helpMessage(validation, EMPTY_DATE_TIME_WARNING);
  }

  return null;
}
