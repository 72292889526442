import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTheme } from "styled-components";
import Skeleton from '@mui/material/Skeleton';

import ResourceCard from "./ResourceCard";
import { ResourceCardContainer } from "./ResourceCard";
import StyledScrollbarDiv from "../../../common/scrollbar";
import { pSBC } from "../../../../lib/pSBC";
import useFeedback from "../../../../hooks/feedbackHook";
import { INTERACT_SUB_EVENT } from "../../../../constants/events";
import useLocalization from "../../../../hooks/localization";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.5rem 0 0;
`;

const ResourcesContainer = styled(StyledScrollbarDiv)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0 0.75rem;
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: 1.5;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.md};
  font-weight: 700;
  color: ${({ theme }) => theme.darkGrey};
`;

function Resources() {
  const similarity = useSelector((state) => state?.content?.similarity);
  const loadingSimilarity = useSelector((state) => state?.content?.loadingSimilarity);
  const lastQuery = useSelector((state) => state?.content?.query);
  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.resources
  );

  const theme = useTheme();
  const { localizeText } = useLocalization();
  const loaderColor = pSBC(-0.5, theme.secondaryColor);

  const articles = Array.isArray(similarity)
    ? similarity
    : [];

  const filterCallback = (a) =>
    a?.url || !Number.isNaN(parseFloat(a?.version));
  const validArticles = articles.filter(filterCallback);

  const { feedback, setFeedback, resetFeedback } = useFeedback(INTERACT_SUB_EVENT);

  useEffect(() => {
    if (!loadingSimilarity) {
      return;
    }
    resetFeedback();
  }, [loadingSimilarity]);

  return (
    <Wrapper>
      <ResourcesContainer>
        <Title>{localizeText(tooltips?.resources)}</Title>
        {loadingSimilarity
          ? (
            <ResourceCardContainer>
              <Skeleton
                  animation="wave"
                  width="60%"
                  height={theme.lg}
                  sx={{ bgColor: loaderColor }} />
              <div style={{ width: "100%" }}>
                <Skeleton
                    animation="wave"
                    width="90%"
                    height={theme.reg}
                    sx={{ bgColor: loaderColor }} />
                <Skeleton
                    animation="wave"
                    width="75%"
                    height={theme.reg}
                    sx={{ bgColor: loaderColor }} />
              </div>
            </ResourceCardContainer>
          ) : (
            validArticles.map((article, index) => (
              <ResourceCard
                key={`${article.articleId || article.aid}-${article.chunk_id}`}
                article={article}
                feedback={feedback?.[index]}
                query={lastQuery}
                setFeedback={(value, eventType) =>
                  setFeedback(lastQuery, index, eventType, value, `Article: ${article?.title || ""}`)} />
            ))
          )}
      </ResourcesContainer>
    </Wrapper>
  );
}

export default Resources;

