export function extractTextbyLang(mapping, targetLang, defaultLang) {
  if (mapping === null || mapping === undefined) {
    return "";
  }
  if (typeof(mapping) === "string") {
    return mapping;
  }

  if (typeof(targetLang) === "string"
    && mapping.hasOwnProperty(targetLang)
  ) {
    return mapping[targetLang];
  }
  if (typeof(defaultLang) === "string"
    && mapping.hasOwnProperty(defaultLang)
  ) {
    return mapping[defaultLang];
  }

  console.warn(`Readyly ChatAssist: missing default language translations!`);
  return "";
}