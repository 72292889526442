import {
  CONVERSATION_TYPE_AI,
  CONVERSATION_TYPE_AGENT,
  CONVERSATION_TYPE_TICKET,
  TRIED_SWITCH_TO_AGENT,
  TRIED_CREATING_TICKET
} from "../../constants/chat";

export function getConversationType(chatStage) {
  if (chatStage >= TRIED_CREATING_TICKET) {
    return CONVERSATION_TYPE_TICKET;
  }
  if (chatStage >= TRIED_SWITCH_TO_AGENT) {
    return CONVERSATION_TYPE_AGENT;
  }
  return CONVERSATION_TYPE_AI;
}
