import { BUSINESS_HOURS_FROM, BUSINESS_HOURS_TO } from "../../constants/chat";

export function isDayOff(offDays) {
  if (!Array.isArray(offDays)) {
    console.warn("Readyly ChatAssist: invalid off days and holidays!");
    return false;
  }

  for (const day of offDays) {
    if (!day?.timeZone) {
      console.warn("Readyly ChatAssist: missing time zone in off days!");
      continue;
    }

    try {
      const today = new Date(
        new Date().toLocaleString("en-US", { timeZone: day.timeZone })
      );

      // check for specific weekdays (weekends or any other day)
      if (today.getDay() === parseInt(day?.day)) {
        return true;
      }
      // check for specific dates (annual holidays)
      if (today.getDate() === day?.date
        && today.getMonth() === parseInt(day?.month)
      ) {
        return true;
      }
    } catch(e) {
      console.warn(`Readyly ChatAssist: invalid time zone found in off days: ${day?.timeZone}!`);
    }
  }

  return false;
}

export function businessHoursToday(hours) {
  if (!hours?.from || !hours?.to) {
    console.warn("Readyly ChatAssist: invalid business hours, using defaults!");
    hours = {
      from: BUSINESS_HOURS_FROM,
      to: BUSINESS_HOURS_TO
    };
  }

  const today = new Date().toUTCString().slice(0, 17);
  const from = new Date(today + hours.from);
  const to = new Date(today + hours.to);

  if (from.toString() === "Invalid Date"
    || to.toString() === "Invalid Date"
    || from >= to
  ) {
    console.warn("Readyly ChatAssist: invalid business hours, using defaults!");
    return {
      from: new Date(today + BUSINESS_HOURS_FROM),
      to: new Date(today + BUSINESS_HOURS_TO)
    }
  }
  return { from, to };
}

export function withinBusinessHours(businessHours) {
  const now = new Date();
  const hours = businessHoursToday(businessHours);
  const isWithinHours =
    (now >= hours.from) && (now <= hours.to);

  return !isDayOff(businessHours?.daysOff || []) && isWithinHours;
}
