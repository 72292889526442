import styled from "styled-components";

const StyledLink = styled.a`
  width: fit-content;
  display: inline-block;
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    transform scale(0.99);
    transition: all .25s ease;
  }
`;

export default StyledLink;