import { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import parse from "html-react-parser";
import { domToReact, attributesToProps } from "html-react-parser";
import debounce from "lodash.debounce";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import Message from "./Message";
import Sources from "./Sources";
import DynamicForm from "./DynamicForm";
import ConversationHeader from "./ConversationHeader";
import Attachment from "./Attachment";
import IncomingAttachment from "./Attachment/Incoming";
import StyledScrollbarDiv from "../../../../common/scrollbar";
import StyledLink from "../../../../common/styledLink";
import LoadingDot from "../../../../common/LoadingDot";
import FeedbackBar from "../../../../common/FeedbackBar";
import TextToSpeech from "../../../../common/TextToSpeech";
import useFeedback from "../../../../../hooks/feedbackHook";

import { WebSocketContext } from "../../../../../App";
import { addConversation, getChatActions } from "../../../../../redux/actions/chatActions";
import { getConfigValueByPersona } from "../../../../../lib/chat/getConfigValue";
import { AGENT, AI_BOT, END_USER, SYSTEM } from "../../../../../constants/user";
import {
  CHAT_WITH_AGENT,
  MESSAGE_IS_FORM,
  MESSAGE_RECEIVED,
  MESSAGE_SENT
} from "../../../../../constants/chat";
import { INTERACT_SUB_EVENT } from "../../../../../constants/events";
import useLocalization from "../../../../../hooks/localization";

const ConversationContainer = styled(StyledScrollbarDiv)`
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  font-size: 1rem;
  overflow-y: auto;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const Messages = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0.25rem;
  row-gap: 0.5rem;
  color: black;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
`;

const NewMessagesAlert = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0.5rem;
  margin-left: auto;
  padding: 0.25rem;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 2rem;
  font-size: ${({ theme }) => theme.xxl};
  background-color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`;

const UserMessage = styled.pre`
  word-break: break-word;
  white-space: pre-wrap;
`

const XtraStyledLink = styled(StyledLink)`
  display: inline;
  word-wrap: break-word;
`;

const AGENT_INACTIVITY_DURATION = 60_000;

function Conversation({ isWidgetOpen }) {
  const [showNewMessageAlert, setShowNewMessageAlert] = useState(true);
  const chatWith = useSelector((state) => state?.chat?.chatWith);
  const conversation = useSelector((state) => state?.chat?.conversation);
  const unreadMessages = useSelector((state) => state?.chat?.unreadMessages);
  const isBotTyping = useSelector((state) => state?.chat?.isBotTyping);
  const customizations = useSelector((state) => state?.org?.customizations);
  const assetUrls = useSelector((state) => state?.org?.assetUrls);

  const endOfMessagesRef = useRef(null);
  const containerRef = useRef(null);
  const wasAtBottonRef = useRef(false);

  const dispatch = useDispatch();
  const chatActionsFn = getChatActions(dispatch);

  const { feedback, setFeedback } = useFeedback(INTERACT_SUB_EVENT);
  const { localizeText, localizeTooltips } = useLocalization();

  const { sendJsonMessage } = useContext(WebSocketContext);

  const tooltips = localizeTooltips(customizations?.tooltips?.timestamps);

  const [duration, setDuration] = useState(tooltips?.justNow);

  const calcLastMessageDuration = () => {
    const calculateDuration = () => {
      if (conversation?.length === 0) {
        setDuration(tooltips?.justNow);
        return;
      }
      if (unreadMessages?.length > 0) {
        setShowNewMessageAlert(true);
      }

      const now = Date.now();
      const latestMessage = conversation?.[conversation.length - 1];
      const timestamp = latestMessage?.timestamp || now;
      const minutesElapsed = Math.floor((now - timestamp) / (1000 * 60));

      if (minutesElapsed < 1) {
        setDuration(tooltips?.justNow);
      } else if (minutesElapsed <= 59) {
        setDuration(
          `${minutesElapsed} ${minutesElapsed === 1
            ? tooltips?.minute
            : tooltips?.minutes} ${tooltips?.ago}`
        );
      } else {
        setDuration(tooltips?.anHourAgo);
      }
    };
    calculateDuration();
    const intervalRef = setInterval(calculateDuration, 60_000);
    return () => clearInterval(intervalRef);
  };

  const scrollToElement = (element, block = "start") => {
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth", block });
    }, 50);
  };

  const scrollToLastUnreadMessage = () => {
    const oldestUnreadMsg = unreadMessages[0];

    for (let i = conversation.length - 1; i >= 0; i--) {
      if (conversation[i].id !== oldestUnreadMsg.id) {
        continue;
      }

      const element = document.getElementById(oldestUnreadMsg.id);
      scrollToElement(
        element.parentElement ?? element,
        chatWith === AGENT
          ? "end"
          : "nearest"
      );
      break;
    }
  };

  const scrollToUnreadMessages = () => {
    if (!isWidgetOpen || !conversation?.length) {
      return;
    }
    if (!unreadMessages?.length) {
      const element = endOfMessagesRef?.current;
      scrollToElement(element);
      return;
    }
    setShowNewMessageAlert(false);

    scrollToLastUnreadMessage();
  };

  const autoScroll = () => {
    if (!isWidgetOpen || !conversation?.length) {
      return;
    }
    if (conversation[conversation.length - 1].type === END_USER) {
      const element = document.getElementById(
        conversation[conversation.length - 1].id
      );
      scrollToElement(element?.parentElement || element);
      return;
    }
    if (!unreadMessages?.length || !wasAtBottonRef.current) {
      return;
    }

    const timeoutRef = setTimeout(scrollToLastUnreadMessage, 500);
    return () => clearTimeout(timeoutRef);
  };

  const sayHello = () => {
    chatActionsFn.greetEndUser(sendJsonMessage);
  };

  const handleInactiveAgent = () => {
    if (chatWith !== CHAT_WITH_AGENT
        || !conversation?.length
        || !customizations?.chatConfig?.onAgentInactivity
      ) {
      return;
    }

    const promptUser = () => {
      const inactivityMessage = getConfigValueByPersona(
        customizations.chatConfig,
        "onAgentInactivity",
        []
      );

      for (let i = conversation.length - 1; i >= 0; i--) {
        if (conversation[i].type === END_USER) {
          continue;
        }
        if (conversation[i].type === AGENT) {
          return;
        }
        if (conversation[i].agentInactivityAlert) {
          return;
        }
      }

      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        agentInactivityAlert: true,
        message: inactivityMessage.phrase,
        repliesHelpText: inactivityMessage.repliesHelpText,
        replies: inactivityMessage.replies
      }));
    };

    const timeoutRef = setTimeout(promptUser, AGENT_INACTIVITY_DURATION);
    return () => clearTimeout(timeoutRef);
  };

  const handleScrollEnd = () => {
    if (!containerRef.current) {
      return;
    }

    const container = containerRef.current;
    const handler = debounce(() => {
      const distanceFromTop = container.scrollTop + container.clientHeight;
      if (Math.abs(container.scrollHeight - distanceFromTop) < 8) {
        wasAtBottonRef.current = true;
      } else {
        wasAtBottonRef.current = false;
      }
    }, 250);

    container.addEventListener("scroll", handler);

    return () => container.removeEventListener("scroll", handler);
  };

  useEffect(sayHello, [
    customizations?.chatConfig?.opener,
    conversation?.length
  ]);
  useEffect(calcLastMessageDuration, [
    conversation?.length,
    tooltips?.ago,
    tooltips?.justNow,
    tooltips?.minute,
    tooltips?.minutes,
    tooltips?.hour,
    tooltips?.hours,
    tooltips?.anHourAgo
  ]);
  useEffect(autoScroll, [conversation?.length]);
  useEffect(scrollToUnreadMessages, [isWidgetOpen]);
  useEffect(handleInactiveAgent, [chatWith, conversation?.length]);
  useEffect(handleScrollEnd, []);

  const parseOptions = {
    replace: (node) => {
      if (node?.name === 'a') {
        const anchorProps = attributesToProps({
          ...node.attribs,
          target: '_blank',
          rel: 'noreferrer'
        });
        return (
          <XtraStyledLink {...anchorProps}>
            {domToReact(node.children, parseOptions)}
          </XtraStyledLink>
        );
      }
    }
  };

  const filterCallback = (c) => {
    if (!c?.id) {
      return false;
    }
    if (c?.type !== AI_BOT && c?.type !== END_USER
        && c?.type !== AGENT && c?.type !== SYSTEM) {
      return false;
    }
    if (c?.subtype !== MESSAGE_SENT && c?.subtype !== MESSAGE_RECEIVED
        && c?.subtype !== MESSAGE_IS_FORM) {
      return false;
    }
    if (typeof(c?.message) !== "string" || c?.message?.length === 0) {
      return false;
    }
    return true;
  };

  const botAvatar = `${assetUrls?.images}/${customizations?.chatConfig?.botAvatar}`;

  const handleFeedback = (replyId) => {
    let lastQuery = "";

    for (const c of conversation) {
      if (c?.type === END_USER) {
        lastQuery = c?.message  || "";
      }
      if (c?.id === replyId) {
        break;
      }
    }

    return (value, eventType) =>
      setFeedback(lastQuery, replyId, eventType, value, "Response");
  };

  const ttsEnabled = customizations?.textToSpeechConfig?.chat;
  const conversationWithReplies = conversation?.map((c) => ({ ...c }));

  for (let i = conversationWithReplies?.length - 1; i >= 0; i--) {
    if (conversationWithReplies[i]?.type === END_USER) {
      break;
    }
    if (!Array.isArray(conversationWithReplies[i]?.replies)) {
      continue;
    }
    conversationWithReplies[i].showReplies = true;
    if (conversationWithReplies[i]?.type === SYSTEM) {
      break; // remove all reply options before workflows
    }
  };

  let previousQuery = "";

  return (
    <ConversationContainer ref={containerRef}>
      <ConversationHeader
        duration={duration}
        timestampBanner={customizations?.timestampBanner} />
      <Messages>
        {conversationWithReplies?.filter(filterCallback)?.map((c) => {
          if (c.subtype === MESSAGE_IS_FORM) {
            return (
              <Message
                key={c.id}
                rtl={false}
                messageId={c.id}
                containerRef={containerRef}
                alignment={"center"}
                timestamp={c.timestamp}
                replies={[]}
                localizeText={localizeText}
              >
                <div id={c.id}>
                  <DynamicForm
                    options={{
                      id: c.id,
                      name: c.name,
                      fields: c.fields,
                      connectionId: c.connectionId,
                      executionArn: c.executionArn
                    }} />
                </div>
              </Message>
            );
          }
          if (c.type !== END_USER) {
            return (
              <Message
                key={c.id}
                rtl={false}
                messageId={c.id}
                containerRef={containerRef}
                alignment={"flex-start"}
                avatar={c.type === AGENT
                  ? c.avatarUrl || botAvatar
                  : botAvatar}
                timestamp={c.timestamp}
                repliesHelpText={c.repliesHelpText}
                replies={c?.showReplies ? c?.replies : []}
                localizeText={localizeText}
              >
                <div id={c.id}>
                  {(c.type === AGENT && c.attachment?.url && c.attachment?.name)
                    ? (
                      <IncomingAttachment
                        url={c.attachment.url}
                        name={c.attachment.name}
                        system={c.attachment?.system}
                        mimeType={c.attachment?.mimeType} />
                    ) : (
                      parse(
                        c?.message?.replace?.(/(\r?\n)/g, "<br/>"),
                        parseOptions
                    ))}
                </div>
                {c.sources && (
                  <Sources articles={c.sources} query={previousQuery} />
                )}
                {((ttsEnabled || c.collectFeedback) && !(c.attachment?.url && c.attachment?.name)) && (
                  <SectionContainer>
                    {ttsEnabled && (
                      <TextToSpeech textId={c?.id} text={c?.message} />
                    )}
                    {c.collectFeedback && (
                      <FeedbackBar
                        value={feedback?.[c?.id]}
                        setFeedback={handleFeedback(c?.id)} />
                    )}
                  </SectionContainer>
                )}
              </Message>
            );
          }
          previousQuery = c?.message;
          return (
            <Message
              key={c.id}
              rtl={false}
              messageId={c.id}
              containerRef={containerRef}
              alignment={"flex-end"}
              timestamp={c.timestamp}
              localizeText={localizeText}
            >
              <div id={c.id}>
                {c.attachment?.url && c.attachment?.name
                  ? (
                    <Attachment {...{ id: c.id, ...c.attachment }} />
                  ) : (
                  <UserMessage>
                    {c.message}
                  </UserMessage>
                  )}
              </div>
            </Message>
          );
        })}
        {isBotTyping && (
          <Message
            key="bot-loader"
            rtl={false}
            messageId={"bot-loader"}
            containerRef={containerRef}
            alignment={"flex-start"}
            avatar={botAvatar}
            localizeText={localizeText}
          >
            <LoadingDot />
            <LoadingDot />
            <LoadingDot />
          </Message>
        )}
        <div ref={endOfMessagesRef}></div>
        {(unreadMessages?.length > 0
          && showNewMessageAlert
          && conversation?.length > 2
        ) && (
          <NewMessagesAlert onClick={scrollToUnreadMessages}>
            <KeyboardDoubleArrowDownIcon sx={{ fontSize: "inherit", color: "inherit" }} />
          </NewMessagesAlert>
        )}
      </Messages>
    </ConversationContainer>
  );
}

export default Conversation;
