import { EXECUTE_WORKFLOW } from "../constants/replies";
import { END_USER, PUBLIC_ACCESS } from "../constants/user";

export function processWorkflows(workflows, executingWorkflow, workflowConfig) {
  const {
    enableWorkflows,
    minConfidenceThreshold,
    highConfidenceThreshold
  } = workflowConfig || {};

  const results = [];
  const poorResults = [];
  const betterResults = [];

  if (!enableWorkflows
      || executingWorkflow
      || !Array.isArray(workflows)) {
    return {
      decentWorkflows: results,
      lowConfidenceWorkflows: poorResults,
      highConfidenceWorkflows: betterResults
    };
  }

  const processMetadata = (w) => ({
    type: EXECUTE_WORKFLOW,
    data: {
      query: w?.meta?.title,
      title: w?.meta?.title,
      version: w?.meta?.version,
      workflowId: w?.meta?.id,
      workflowType: w?.meta?.workflow_type,
      confidence: w?.confidence,
      confidencePercent: `${(w?.confidence * 100).toFixed(2)}%`
    }
  });

  for (const w of workflows) {
    if (!w?.meta?.id
        || !w?.meta?.title
        || w?.meta?.target !== END_USER
        || w?.meta?.access !== PUBLIC_ACCESS
        || Number.isNaN(parseFloat(w?.confidence))) {
      continue;
    }

    if (w?.confidence < minConfidenceThreshold) {
      poorResults.push(processMetadata(w));
      continue;
    }
    if (w?.confidence >= highConfidenceThreshold) {
      betterResults.push(processMetadata(w));
      continue;
    }
    results.push(processMetadata(w));
  }

  return {
    decentWorkflows: results,
    lowConfidenceWorkflows: poorResults,
    highConfidenceWorkflows: betterResults
  };
}
