import { DYNAMIC_VARIABLE_REGEX } from "../constants/regex";

function isNullOrUndefined(obj) {
  return obj === null || obj === undefined;
}

export function getNestedProperty(source, path) {
  if (typeof(path) !== "string") {
    throw Error("Expected path to a nested property to be a string!");
  }
  if (path.length === 0) {
    return source;
  }

  const index = path.indexOf(".");
  const prop = index === -1
    ? path
    : path.slice(0, index);
  const val = source?.[prop];

  if (isNullOrUndefined(val)) {
    throw Error(`Expected to find "${path}" in source!`);
  }

  return getNestedProperty(
    val,
    index === -1
      ? ""
      : path.slice(index + 1)
  );
}

export function populateDynamicValuesInString(str, source) {
  const matches = str.matchAll(DYNAMIC_VARIABLE_REGEX);

  for (const match of matches) {
    try {
      const val = getNestedProperty(source, match[1]);
      str = str.replace(match[0], val);
    } catch(e) {
      console.warn(`Readyly ChatAssist: ${e.message}`);
      str = str.replace(match[0], "");
    }
  }

  return str;
}

export function buildDynamicPhrase(phrase, source, deps = []) {
  if (typeof(phrase) !== "string" || !phrase) {
    return "";
  }
  if (typeof(source) !== "object" && !isNullOrUndefined(source)) {
    return "";
  }

  try {
    for (const dep of deps) {
      getNestedProperty(source, `${dep}`)
    }
  } catch(e) {
    return "";
  }

  return populateDynamicValuesInString(phrase, source);
}

export function populateDynamicProperties(destination, source) {
  if (isNullOrUndefined(source)) {
    throw Error("Cannot populate dynamic props using null/undefined source!");
  }
  if (isNullOrUndefined(destination)) {
    throw Error("Cannot populate dynamic props on null/undefined destination!");
  }

  // Create a deep copy destination
  //* can only copy serializable objects
  const copy = JSON.parse(JSON.stringify(destination));

  for (const prop in copy) {
    if (isNullOrUndefined(copy[prop])) {
      continue;
    }

    if (typeof(copy[prop]) === "object") {
      copy[prop] = populateDynamicProperties(copy[prop], source);
    } else if (typeof(copy[prop]) === "string") {
      copy[prop] = populateDynamicValuesInString(copy[prop], source);
    }
  }

  return copy;
}