import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  StyledLabel,
  StyledTextField,
  FieldContainer
} from "../../../../../../common/formFields";
import FieldError from "../../../../../../common/FieldError";
import { setFormState } from "../../../../../../../redux/actions/formActions";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.75rem;
`;

function NameField({ id, data, formState, errorState, disabled }) {
  const dispatch = useDispatch();

  const handleChange = (event, field) => {
    dispatch(setFormState(id, { [field]: event.target.value }));
  };

  const handleFirstNameChange = (event) => {
    handleChange(event, data[0]?.field);
  };
  const handleLastNameChange = (event) => {
    handleChange(event, data[1]?.field);
  };

  return (
    <Wrapper>
      <Container>
        <StyledLabel htmlFor={data[0]?.field}>
          {prepareLabel(data[0]?.field)}
        </StyledLabel>
        <FieldContainer>
          <StyledTextField
            id={data[0]?.field}
            type={"text"}
            value={formState[data[0]?.field]}
            placeholder={data[0]?.message || ""}
            onChange={handleFirstNameChange}
            maxLength={64}
            disabled={disabled} />
          {errorState?.[data[0]?.field]?.length > 0 && (
            <FieldError message={errorState?.[data[0]?.field]} />
          )}
        </FieldContainer>
      </Container>
      <Container>
        <StyledLabel htmlFor={data[1]?.field}>
          {prepareLabel(data[1]?.field)}
        </StyledLabel>
        <FieldContainer>
          <StyledTextField
            id={data[1]?.field}
            type={"text"}
            value={formState[data[1]?.field]}
            placeholder={data[1]?.message || ""}
            onChange={handleLastNameChange}
            maxLength={64}
            disabled={disabled} />
          {errorState?.[data[1]?.field]?.length > 0 && (
            <FieldError message={errorState?.[data[1]?.field]} />
          )}
        </FieldContainer>
      </Container>
    </Wrapper>
  );
}

export default NameField;
