import {
  WEB,
  MOBILE_IOS,
  TABLET_IOS,
  MOBILE_ANDROID,
  TABLET_ANDROID,
  STANDALONE_WEB
} from "../constants/platform";

function webkitMessageHandler(payload) {
  if (
    typeof(window?.webkit?.messageHandlers?.ftMessageHandler?.postMessage) !== "function"
  ) {
    console.warn('Readyly ChatAssist: "ftMessageHandler" is not defined!');
    return;
  }

  window.webkit.messageHandlers.ftMessageHandler.postMessage(payload);
}

function messageParent(payload, platform, parentOrigin = "") {
  switch(platform) {
    case WEB:
      window.parent.postMessage(payload, parentOrigin);
      break;
    case STANDALONE_WEB:
      break; // cannot send messages to non-existant SDK
    case MOBILE_IOS:
    case TABLET_IOS:
      webkitMessageHandler(payload);
      break;
    case MOBILE_ANDROID:
    case TABLET_ANDROID:
    default:
      console.warn(`Readyly ChatAssist: "${platform}" is not supported!`);
      break;
  }
}

export default messageParent;
