import { supportsKeepAlive } from "../constants/browser";

export function sendBeacon(url, options = {}) {
  // Reasons to not use navigator.sendBeacon
  // - No header support
  // - Only allows POST requests
  // - Browsers limit the size of payload
  // Check out this thread for more details: https://github.com/JakeChampion/fetch/issues/700
  if (!supportsKeepAlive) {
    // We can use XMLHttpRequest here, but that might impact
    // load times for the next page
    return false;
  }

  fetch(url, { ...options, keepalive: true })
    .then(() => {})
    .catch(() => {});

  return true;
}
