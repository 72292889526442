import {
  REPLY_IS_AUTO,
  REPLY_IS_QNA,
  SWITCH_TO_AGENT,
  CLOSE_CHAT,
  CREATE_TICKET,
  CANCEL_QNA
} from "./replies";
import {
  ASK_FOR_EMAIL_PHRASE,
  LEAVE_MESSAGE,
  REENTER_EMAIL_PHRASE,
  MISHIT_PHRASE,
  AGENT_INACTIVITY_PHRASE,
  RECONNECT_FAILED_WITH_AI,
  CONNECTING_TO_AGENT_PHRASE,
  CONNECTED_TO_AGENT_PHRASE,
  FAILED_TO_CONNECT,
  TICKET_CREATED_PHRASE,
  AGENT_LEFT_PHRASE,
  WORKFLOW_CANCELLED_PHRASE,
  EMPTY_AUDIO_TRANSCRIPT_PHRASE,
  WAIT_FOR_AGENT,
  PLEASE_ELABORATE,
  ASK_LANGUAGE_HELP_PHRASE,
  ASK_LANGUAGE_PHRASE,
  QNA_FAILED_PHRASE,
  QNA_DEFAULT_HELP_PHRASE
} from "./phrases";
import { EMAIL_REGEX_STRING, VALID_LANGUAGE_REGEX_STRING, VALID_URL_REGEX } from "./regex";
import { LANGUAGE_PROP } from "./qna";

export const READYLY_LOGO = "readyly_logo.png";

export const READYLY_ICON = "readyly_icon.png";

export const NAVBAR_TITLE = "Customer Service";

export const AI_CHAT_OPENER = "Hello, I'm an AI agent. This is a recorded chat. How can I help you?";

export const PROMPT_AFTER_ANSWER = "Did this answer your question?";

export const HAPPY_TO_HELP = "I'm happy to assist you. You can always come back to me with more questions.";

export const PROMPT_FOR_QUERY = "What can I help you with?";

export const AI_CHAT_FAREWELL = "Have a nice day!";

export const DEFAULT_FONT = "Poppins";

export const DEFAULT_WIDGET_OFFSET = {
  right: 20,
  left: 20,
  bottom: 20
};

export const DEFAULT_THEME = {
  primaryColor: "#1F90BD",
  secondaryColor: "#f5f5f5",

  veryLightGrey: "#e1e1e1",
  lightGrey: "#c9c9c9",
  mediumGrey: "#9d9d9d",
  darkGrey: "#808080",

  green: "#008b50",
  red: "#EB5757",

  fontColor: "#000000",
  fontFamily: "'Poppins', sans-serif",

  navbarHeight: "56px",
  sectionSelectorHeight: "4.5rem",
  widgetBorderRadius: "1.25rem",
  launchButton: {
    minSize: "3.5rem",
    shortTextIconSize: "3rem",
    chevronDownIconSize: "3rem",
    autoAwesomeIconSize: "1.75rem",
    customIconSize: "2rem"
  },

  xxxs: "0.125rem",
  xxs: "0.25rem",
  xs: "0.5rem",
  sm: "0.75rem",
  reg: "0.875rem",
  md: "0.9375rem",
  lg: "1.125rem",
  xl: "1.25rem",
  xxl: "1.5rem",
  xxxl: "1.75rem",
  xxxxl: "2rem"
};

export const DEFAULT_LAUNCH_BTN_CONFIG = {
  logo: null,
  label: null
};

export const DEFAULT_NAVBAR_CONFIG = {
  custom: null,
  title: NAVBAR_TITLE,
  logo: READYLY_LOGO
};

export const DEFAULT_CHAT_CONFIG = {
  botAvatar: READYLY_ICON,
  emptyAudioTranscriptPhrase: EMPTY_AUDIO_TRANSCRIPT_PHRASE,
  pleaseWaitPhrase: WAIT_FOR_AGENT,
  pleaseElaboratePhrase: PLEASE_ELABORATE,
  qnaFailedPhrase: QNA_FAILED_PHRASE,
  qnaDefaultHelpPhrase: QNA_DEFAULT_HELP_PHRASE,
  opener: {
    phrase: AI_CHAT_OPENER,
    replies: []
  },
  promptAfterAnswer: {
    default: {
      phrase: PROMPT_AFTER_ANSWER,
      replies: [
        {
          type: REPLY_IS_AUTO,
          data: {
            query: "Yes",
            response: HAPPY_TO_HELP
          }
        },
        {
          type: SWITCH_TO_AGENT,
          data: {
            query: "No, I'd like to talk to an agent"
          }
        },
        {
          type: REPLY_IS_AUTO,
          data: {
            query: "I want to ask something else",
            response: PROMPT_FOR_QUERY
          }
        }
      ]
    }
  },
  onMishit: {
    phrase: MISHIT_PHRASE,
    replies: [
      {
        type: REPLY_IS_AUTO,
        data: {
          query: "Yes",
          response: PROMPT_FOR_QUERY
        }
      },
      {
        type: SWITCH_TO_AGENT,
        data: {
          query: "No, I'd like to talk to an agent"
        }
      }
    ]
  },
  onWorkflowCancelled: {
    default: {
      phrase: WORKFLOW_CANCELLED_PHRASE,
      showInitialPrompts: false,
      replies: []
    }
  },
  connectingToAgent: {
    default: {
      phrase: CONNECTING_TO_AGENT_PHRASE,
      replies: []
    }
  },
  connectedToAgent: {
    phrase: CONNECTED_TO_AGENT_PHRASE,
    replies: []
  },
  switchToAgentFailed: {
    default: {
      phrase: FAILED_TO_CONNECT,
      replies: []
    }
  },
  onAgentInactivity: {
    phrase: AGENT_INACTIVITY_PHRASE,
    replies: [
      {
        type: CLOSE_CHAT,
        data: {
          query: "Leave a message"
        }
      }
    ]
  },
  agentLeft: {
    default: {
      phrase: AGENT_LEFT_PHRASE,
      replies: [
        {
          type: CLOSE_CHAT,
          data: {
            query: "Leave a message"
          }
        }
      ]
    }
  },
  ticketCreated: {
    default: {
      phrase: TICKET_CREATED_PHRASE,
      replies: []
    }
  },
  onBotUnavailability: {
    phrase: RECONNECT_FAILED_WITH_AI,
    replies: [
      {
        type: SWITCH_TO_AGENT,
        data: {
          query: "I'd like to talk to an agent"
        }
      }
    ]
  },
  switchToAgentQna: [{
    q: ASK_FOR_EMAIL_PHRASE,
    a: null,
    updates: "endUser_email",
    help: REENTER_EMAIL_PHRASE,
    replies: [
      {
        type: CANCEL_QNA,
        data: {
          query: "I want to ask something else",
          response: PROMPT_FOR_QUERY
        }
      }
    ],
    validation: {regex: EMAIL_REGEX_STRING},
    retries: 10
  }],
  createTicketQna: [{
    q: LEAVE_MESSAGE,
    a: null,
    help: LEAVE_MESSAGE,
    replies: [
      {
        type: REPLY_IS_QNA,
        data: {
          query: "Add more details",
          qna: [{
            q: "Please leave a message for our agents.",
            a: null,
            help: null,
            replies: [],
            validation: {
              regex: null
            },
            retries: 1
          }]
        }
      },
      {
        type: CREATE_TICKET,
        data: {
          query: "Submit the ticket"
        }
      }
    ],
    validation: {regex: null},
    retries: 1
  }],
  switchLanguageQna: [{
    q: ASK_LANGUAGE_PHRASE,
    a: null,
    help: ASK_LANGUAGE_HELP_PHRASE,
    overwrites: LANGUAGE_PROP,
    replies: [],
    validation: {regex: VALID_LANGUAGE_REGEX_STRING},
    retries: 10
  }],
};

export const DEFAULT_TOOLTIPS = {
  navbar: {
    resetChat: "Reset Chat",
    expandWidget: "Expand",
    narrowWidget: "Narrow",
    hideWidget: "Hide"
  },
  replyBar: {
    record: "Record Message",
    submit: "Send Message",
    langMenu: "Switch Language",
    attachFile: "Upload Attachment",
    removeFile: "Remove Attachment",
    replyPlaceholder: "Write an message..."
  },
  feedback: {
    cta: "Was this helpful?",
    like: "Like",
    dislike: "Dislike"
  },
  voiceChat: {
    cancel: "Cancel",
    play: "Read Aloud",
    pause: "Pause/Stop",
    allowMicAccess: "Allow microphone access",
    listening: "Listening...",
    clickToStop: "Click to stop"
  },
  footer: {
    chatSection: "Chat",
    resourcesSection: "Resources",
    banner: "Powered by Readyly"
  },
  resources: {
    back: "Back",
    topic: "Topic",
    topics: "Topics",
    article: "Article",
    articles: "Articles",
    resources: "Resources",
    explore: "Explore Resources",
    loadingTopics: "Loading Topics",
    searchResults: "Search Results",
    searchPlaceholder: "Search for help",
    relatedLinks: "Related Links:"
  },
  timestamps: {
    ago: "ago",
    justNow: "just now",
    minute: "minute",
    minutes: "minutes",
    oneHourAgo: "an hour ago"
  },
  attachments: {
    download: "Download",
    downloadFailed: "Download Failed",
    uploadFailed: "Upload Failed"
  }
};
