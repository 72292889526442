import styled from "styled-components";

import {
  StyledTextarea,
  HiddenLabel,
  FieldContainer
} from "../../../../../../common/formFields";
import FieldError from "../../../../../../common/FieldError";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.75rem;
`;

function TextareaField({ data, value, error, disabled, updateFieldState }) {
  const handleChange = (event) => {
    updateFieldState(data?.field, event.target.value);
  };

  return (
    <Container>
      <HiddenLabel htmlFor={data?.field}>
        {prepareLabel(data?.field)}
      </HiddenLabel>
      <FieldContainer>
        <StyledTextarea
          id={data?.field}
          value={value}
          placeholder={data?.message || prepareLabel(data?.field)}
          disabled={disabled}
          maxLength={!Number.isNaN(parseInt(data?.validation?.maxLength))
            ? parseInt(data?.validation?.maxLength)
            : 1200}
          onChange={handleChange} />
        {error?.length > 0 && (
          <FieldError message={error} />
        )}
      </FieldContainer>
    </Container>
  );
}

export default TextareaField;
