import { EMAIL_REGEX_STRING } from "../../constants/regex";

const RESERVED_FIELDS = [
  "firstname",
  "lastname",
  "fullname",
  "email",
  "personas"
];

export function parseUserDetails(details, allowPersonas = true) {
  if (typeof(details) !== "object" || details === null) {
    return {};
  }

  try {
    const emailRegex = new RegExp(EMAIL_REGEX_STRING);
    const email = details?.email || "";
    const personas = typeof(details?.personas) === "string"
      ? details.personas.split(" ")
      : details?.personas;

    const result = resolveEndUserName(details);

    if (details.hasOwnProperty("email")) {
      result.email = emailRegex.test(`${email}`)
        ? email.toLocaleLowerCase()
        : null;
    }
    if (allowPersonas && details.hasOwnProperty("personas")) {
      result.personas = Array.isArray(personas)
        ? personas.filter((p) => p?.length > 0)
        : [];
    }

    return handleCustomFields(details, result);
  } catch (error) {
    console.warn("FastTrack Assist: failed to parse user details!");
    return {};
  }
}

function capitalizeString(str) {
  if (typeof(str) !== "string" || str.length === 0) {
    return;
  }
  return `${str[0].toLocaleUpperCase()}${str.slice(1).toLocaleLowerCase()}`;
}

function handleFullName(fullName) {
  if (typeof(fullName) !== "string" || !fullName.length) {
    return {};
  }
  const parts = fullName.split(/\s+/)
    .map((part) => capitalizeString(part));
  return {
    firstName: parts[0],
    lastName: parts?.[1],
    fullName: parts.join(" ")
  };
}

function resolveEndUserName(details) {
  if (details.hasOwnProperty("fullName")) {
    return handleFullName(details.fullName);
  }

  const result = {};

  if (typeof(details?.firstName) === "string" && details.firstName.length > 0) {
    result.firstName = capitalizeString(details.firstName);
    result.fullName = result.firstName;
  }
  if (typeof(details?.lastName) === "string" && details.lastName.length > 0) {
    result.lastName = capitalizeString(details.lastName);
    result.fullName = result.fullName
      ? `${result.fullName} ${result.lastName}`
      : result.lastName;
  }

  return result;
}

function handleCustomFields(source, result) {
  const isValueInvalid = (val) => 
    typeof(val) !== "string"
    || val.length === 0
    || val.length > 128;

  for (const field in source) {
    const value = source[field];
    if (isValueInvalid(field)
      || isValueInvalid(value)
      || RESERVED_FIELDS.some((f) => f === field.toLowerCase())
    ) {
      continue;
    }
    result[field] = value;
  }

  return result;
}
