import { ReactComponent as BookOutlinedIcon } from "./book_outlined.svg";
import { ReactComponent as BookFilledIcon } from "./book_filled.svg";
import { ReactComponent as ChatOutlinedIcon } from "./chat_outlined.svg";
import { ReactComponent as ChatFilledIcon } from "./chat_filled.svg";

import { ReactComponent as InnagoBookOutlinedIcon } from "./innago/book_outlined.svg";
import { ReactComponent as InnagoBookFilledIcon } from "./innago/book_filled.svg";
import { ReactComponent as InnagoChatOutlinedIcon } from "./innago/chat_outlined.svg";
import { ReactComponent as InnagoChatFilledIcon } from "./innago/chat_filled.svg";

export const footerIcons = {
  defaults: {
    chatIcons: {
      filled: ChatFilledIcon,
      outlined: ChatOutlinedIcon,
      scale: 1.0
    },
    resourceIcons: {
      filled: BookFilledIcon,
      outlined: BookOutlinedIcon,
      scale: 1.0
    }
  },
  hrcovered: {
    chatIcons: {
      filled: ChatFilledIcon,
      outlined: ChatOutlinedIcon,
      scale: 0.7
    },
    resourceIcons: {
      filled: BookFilledIcon,
      outlined: BookOutlinedIcon,
      scale: 0.7
    }
  },
  innago: {
    chatIcons: {
      filled: InnagoChatFilledIcon,
      outlined: InnagoChatOutlinedIcon,
      scale: 1.25
    },
    resourceIcons: {
      filled: InnagoBookFilledIcon,
      outlined: InnagoBookOutlinedIcon,
      scale: 1.25
    }
  }
};
