import styled from 'styled-components';
import { useTheme } from 'styled-components';
import Skeleton from '@mui/material/Skeleton';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0.75rem 0.5rem;
  row-gap: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.veryLightGrey};
`;

function Loader() {
  const theme = useTheme();

  return (
    <Container>
      <Skeleton
        variant="text"
        animation="wave"
        sx={{ fontSize: theme.md, width: "60%" }} />
      <Skeleton
        variant="text"
        animation="wave"
        sx={{ fontSize: theme.md, width: "75%" }} />
      <Skeleton
        variant="text"
        animation="wave"
        sx={{ fontSize: theme.sm, width: "30%" }} />
    </Container>
  );
}

export default Loader;