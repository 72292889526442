import produce from "immer";

import { contentActions } from "../actions/contentActions";
import {
  CHAT_SECTION,
  RESOURCES_SECTION,
  CONTENT_IS_CHAT,
  CONTENT_IS_ARTICLE,
  WIDGET_SIZE_MINIMAL,
  MIN_WIDTH_TO_EXPAND,
  DEFAULT_ORIENTATION,
  SUPPORTED_ORIENTATIONS
} from "../../constants/widget";

const MAX_ARTICLES = 10;
const MAX_TOPICS = 3;

const initialState = {
  currentSection: CHAT_SECTION,
  currentContent: CONTENT_IS_CHAT,
  widgetSize: WIDGET_SIZE_MINIMAL,
  windowDimensions: {},
  widgetOrientation: DEFAULT_ORIENTATION,
  query: "",
  intent: [],
  similarity: [],
  openArticle: {},
  openedArticles: [],
  loadingSimilarity: false,
  loadingArticle: false,
  topics: {},
  loadingTopics: false,
  openTopic: null,
  articlesByTopic: {},
  searchQuery: "",
  isLastSearchPage: false,
  loadingSearchResults: false,
  searchResults: {}
};

const contentReducer = produce((draft, action) => {
  switch(action.type) {
    case contentActions.SET_WINDOW_DIMENSIONS:
      draft.windowDimensions = action.payload;
      return;
    case contentActions.RESIZE_WIDGET:
      draft.widgetSize = draft.windowDimensions?.width < MIN_WIDTH_TO_EXPAND
        ? WIDGET_SIZE_MINIMAL
        : action.payload;
      return;
    case contentActions.SET_WIDGET_ORIENTATION:
      draft.widgetOrientation = SUPPORTED_ORIENTATIONS.some((o) => o === action.payload)
        ? action.payload
        : DEFAULT_ORIENTATION;
      return;
    case contentActions.OPEN_CHAT_SECTION:
      draft.currentSection = CHAT_SECTION;
      return;
    case contentActions.OPEN_RESOURCES_SECTION:
      draft.currentSection = RESOURCES_SECTION;
      return;
    case contentActions.OPEN_CHAT:
      draft.currentContent = CONTENT_IS_CHAT;
      draft.openArticle = {};
      return;
    case contentActions.OPEN_ARTICLE_BEGIN:
      draft.currentContent = CONTENT_IS_ARTICLE;
      draft.openArticle = action.payload;
      draft.loadingArticle = true;
      return;
    case contentActions.OPEN_ARTICLE_SUCCESS:
      draft.loadingArticle = false;
      draft.openArticle = action.payload.article;
      if (!action.payload?.isNew) {
        return;
      }
      draft.openedArticles.push(action.payload.article);
      if (draft.openedArticles.length > MAX_ARTICLES) {
        draft.openedArticles.shift();
      }
      return;
    case contentActions.OPEN_ARTICLE_ERROR:
      draft.currentContent = "article";
      draft.openArticle = action.payload;
      draft.loadingArticle = false;
      return;
    case contentActions.SET_INTENT:
      draft.intent = action.payload;
      return;
    case contentActions.LOAD_SIMILARITY_BEGIN:
      draft.query = "";
      draft.similarity = [];
      draft.loadingSimilarity = true;
      return;
    case contentActions.LOAD_SIMILARITY_SUCCESS:
      draft.query = action.payload.query;
      draft.similarity = action.payload.processedArticles;
      draft.loadingSimilarity = false;
      return;
    case contentActions.LOAD_SIMILARITY_ERROR:
      draft.similarity = [];
      draft.loadingSimilarity = false;
      return;
    case contentActions.CLEAR_SIMILARITY:
      draft.query = "";
      draft.loadingSimilarity = false;
      draft.similarity = [];
      return;
    case contentActions.LOAD_TOPICS_BEGIN:
      draft.loadingTopics = true;
      return;
    case contentActions.LOAD_TOPICS_SUCCESS:
      for (const t of action.payload.topics) {
        draft.topics[`${t.id}`] = {
          name: t.name,
          desc: t.desc,
          count: t.count,
          parent: `${t.parent}`
        };
      }
      draft.loadingTopics = false;
      return;
    case contentActions.LOAD_TOPICS_ERROR:
      draft.loadingTopics = false;
      return;
    case contentActions.OPEN_TOPIC:
      const opened = Object.keys(draft.articlesByTopic);
      draft.openTopic = action.payload;
      if (opened.length <= MAX_TOPICS) {
        return;
      }
      for (const tid of opened) {
        if (tid === action.payload) {
          continue;
        }
        delete draft.articlesByTopic[tid]
      }
      return;
    case contentActions.CLOSE_TOPIC:
      draft.openTopic = null;
      return;
    case contentActions.LOAD_ARTICLES_BY_TOPIC_BEGIN:
      draft.articlesByTopic[action.payload.topicId] = {
        page: 0,
        isLast: false,
        ...draft.articlesByTopic?.[action.payload.topicId],
        isLoading: true
      };
      return;
    case contentActions.LOAD_ARTICLES_BY_TOPIC_SUCCESS:
      const articles = {};
      for (const a of action.payload.articles) {
        articles[a.aid] = a;
      }
      draft.articlesByTopic[action.payload.topicId].articles = {
        ...draft.articlesByTopic?.[action.payload.topicId]?.articles,
        ...articles
      };
      draft.articlesByTopic[action.payload.topicId].page =
        (draft.articlesByTopic[action.payload.topicId].page ?? 0) + 1 ;
      draft.articlesByTopic[action.payload.topicId].isLoading = false;
      draft.articlesByTopic[action.payload.topicId].isLast =
        action.payload.isLast;
      return;
    case contentActions.LOAD_ARTICLES_BY_TOPIC_ERROR:
      draft.articlesByTopic[action.payload.topicId] = {
        ...draft.articlesByTopic[action.payload.topicId],
        isLoading: false,
        isLast: true
      };
      return;
    case contentActions.UPDATE_SEARCH_QUERY:
      draft.searchQuery = action.payload.query;
      draft.loadingSearchResults = false;
      draft.isLastSearchPage = false;
      draft.searchResults = {};
      return;
    case contentActions.SEARCH_ARTICLES_BEGIN:
      draft.loadingSearchResults = true;
      draft.isLastSearchPage = false;
      draft.searchResults = {};
      return;
    case contentActions.SEARCH_ARTICLES_SUCCESS:
      const results = {};
      for (const a of action.payload.articles) {
        results[a.aid] = a;
      }
      draft.searchResults = {
        ...results
      };
      draft.loadingSearchResults = false;
      draft.isLastSearchPage = true;
      return;
    case contentActions.SEARCH_ARTICLES_ERROR:
      draft.loadingSearchResults = false;
      draft.isLastSearchPage = true;
      draft.searchResults = {};
      return;
    case contentActions.CLEAR_SEARCH_RESULTS:
      draft.searchQuery = "";
      draft.loadingSearchResults = false;
      draft.isLastSearchPage = false;
      draft.searchResults = {};
      return;
    default:
      return;
  }
}, initialState);

export default contentReducer;
