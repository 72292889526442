import { useCallback } from "react";
import useWebSocket from "react-use-websocket";
import { CHAT_ON_AMAZON_CONNECT, CHAT_WITH_AGENT } from "../constants/chat";

function useWebSocketWrapper(
  orgId,
  profile,
  conversationId,
  socketUrl,
  chatOn,
  chatWith,
  chatMedium,
  chatActionsFn,
  contentActionsFn
) {
  const chattingWithAgent = chatWith === CHAT_WITH_AGENT;
  const shouldOpenConnection = chattingWithAgent
    ? chatOn && (chatMedium !== CHAT_ON_AMAZON_CONNECT)
    : chatOn;

  const handleSocketOpen = (event) => {
    console.log("Readyly ChatAssist: connection opened");
    console.log(event);
    chatActionsFn.handleSocketOpen(event, chatWith, chatMedium, sendMessageWrapper);
  };

  const handleSocketClose = (event) => {
    console.log("Readyly ChatAssist: connection closed");
  };

  const handleReconnectStop = (event) => {
    console.log(
      "Readyly ChatAssist: retries exhausted, failed to open a connection"
    );
    chatActionsFn.handleSocketReconnectStop(event, chatMedium);
  };

  const messageFromAI = async (event) => {
    try {
      const response = JSON.parse(event.data);

      if (Object.keys(response).includes("intent")) {
        chatActionsFn.interact(response, sendMessageWrapper);
      } else if (Object.keys(response).includes("similarity")) {
        contentActionsFn.similarity(response);
      } else {
        chatActionsFn.systemMessage(response, sendMessageWrapper);
      }
    } catch(error) {
      console.warn(
        `Readyly ChatAssist: failed to process message from bot: ${error?.message}`
      );
    }
  };

  const messageFromAgent = async (event) => {
    try {
      const response = JSON.parse(event.data);
      chatActionsFn.handleMessageFromAgent(response, chatMedium, sendMessageWrapper);
    } catch(error) {
      console.warn(
        `Readyly ChatAssist: failed to process message during live chat: ${error?.message}`
      );
    }
  };

  const { sendJsonMessage } = useWebSocket(
    socketUrl,
    {
      share: true,
      onOpen: handleSocketOpen,
      onClose: handleSocketClose,
      shouldReconnect: (closeEvent) => closeEvent.wasClean,
      reconnectAttempts: 3,
      reconnectInterval: 5_000,
      onReconnectStop: handleReconnectStop,
      onMessage: (event) => {
        if (chattingWithAgent) {
          messageFromAgent(event);
          return;
        }
        messageFromAI(event);
      }
    },
    shouldOpenConnection
  );

  const sendMessageWrapper = useCallback((jsonMessage) => {
    if (!jsonMessage) {
      return;
    }
    sendJsonMessage({
      orgId,
      profile,
      conversationId,
      readylyService: "chat_assist",
      ...jsonMessage
    });
  }, [orgId, conversationId, profile, sendJsonMessage]);

  return {sendJsonMessage: sendMessageWrapper};
}

export default useWebSocketWrapper;
