import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import Loader from "./Loader";
import Card, {
  CardContainer,
  CardTitle,
  CardContent,
  CardFooter
} from "../../../../common/Card";
import StyledScrollbarDiv from "../../../../common/scrollbar";
import BackButton from "../../../../common/backButton";
import {
  getContentActions,
  openTopic,
  closeTopic
} from "../../../../../redux/actions/contentActions";
import {
  logEventRecord
} from "../../../../../redux/actions/chatActions";
import {
  RESOURCE_SECTION_SUB_EVENT,
  VIEW_TOPIC_EVENT
} from "../../../../../constants/events";
import useLocalization from "../../../../../hooks/localization";

const Container = styled(StyledScrollbarDiv)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 0.125rem 0 0;
  overflow-y: auto;
`;

const Header = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0.75rem;
    color: ${({ theme }) => theme.darkGrey};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.md};
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.veryLightGrey};
`;

const SubTopicHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.veryLightGrey};
`;

const StyledBackButton = styled(BackButton)`
  padding: 0.125rem 0;
`;

const StyledCardContainer = styled(CardContainer)`
  padding-left: 0.25rem;
  cursor: default;
`;

function Topics({ topics, openedTopic }) {
  const loadingTopics = useSelector((state) => state?.content?.loadingTopics);
  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.resources
  );

  const dispatch = useDispatch();
  const { localizeTooltips } = useLocalization();
  const contentActions = getContentActions(dispatch);

  const localizedTooltips = localizeTooltips(tooltips);

  useEffect(() => {
    if (topics?.length) {
      return;
    }
    contentActions.loadTopics();
  }, []);

  const displayTopic = (t) => {
    dispatch(logEventRecord(
      null,
      null,
      uuid(),
      VIEW_TOPIC_EVENT,
      RESOURCE_SECTION_SUB_EVENT,
      true,
      `ID: ${t?.id}\nName: ${t?.name}`
    ));
    dispatch(openTopic(t.id));
  };

  const renderTopic = (t) => {
    if (!t?.id || !t?.name) {
      return;
    }

    return (
      <Card
        key={t.id}
        title={t.name}
        content={t.desc}
        footer={t.count === 1
          ? `${t.count} ${localizedTooltips?.article}`
          : `${t.count} ${localizedTooltips?.articles}`}
        showIcon={true}
        clickHandler={() => displayTopic(t)} />
    );
  };

  const goBack = () => {
    if (!openedTopic?.parent || openedTopic.parent === "0") {
      dispatch(closeTopic());
      return;
    }
    dispatch(openTopic(openedTopic.parent));
  };

  const headerTextLoading = localizedTooltips?.loadingTopics;
  const headerText = topics?.length > 0
    ? `${topics.length} ${topics.length === 1 ? localizedTooltips?.topic : localizedTooltips?.topics}`
    : localizedTooltips?.searchResults;

  return (
    <Container>
      {!!openedTopic
        ? (
          <SubTopicHeader>
            <StyledBackButton onClick={goBack}>
              <KeyboardArrowLeftIcon
                sx={{ fontSize: "inherit", color: "inherit" }} />
              &nbsp;{localizedTooltips?.topics}
            </StyledBackButton>
            {openedTopic?.name?.length > 0 && (
              <StyledCardContainer>
                <CardTitle>{openedTopic.name}</CardTitle>
                {openedTopic?.desc?.length > 0 && (
                  <CardContent>{openedTopic.desc}</CardContent>
                )}
                {!Number.isNaN(openedTopic?.count) && (
                  <CardFooter>
                    {topics?.length ?? 0}
                    &nbsp;
                    {topics?.length === 1
                      ? localizedTooltips?.topic
                      : localizedTooltips?.topics}
                  </CardFooter>
                )}
              </StyledCardContainer>
            )}
          </SubTopicHeader>
        ) : (
          <Header>
            {loadingTopics
              ? headerTextLoading
              : headerText}
          </Header>
        )}
      {topics.map(renderTopic)}
      {loadingTopics && (
        <>
          <Loader />
          {topics?.length === 0 && (
            <>
              <Loader />
              <Loader />
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default Topics;
