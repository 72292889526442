import styled from "styled-components";
import { keyframes } from "styled-components";
import { pSBC } from "../../lib/pSBC";

const wave = keyframes`
  0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-0.25rem);
	}
`;

const LoadingDot = styled.div`
  display: inline-block;
  width: ${({ theme }) => theme.xs};
  height: ${({ theme }) => theme.xs};
  margin-right: ${({ theme }) => theme.xs};
  border-radius: 50%;
  background-color: ${({ theme }) => {
    return pSBC(-0.5, theme.secondaryColor);
  }};
  animation: ${wave} 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
    margin-right: 0;
  }
`;

export default LoadingDot;
