import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  StyledTextField,
  StyledLabel,
  FieldContainer
} from "../../../../../../common/formFields";
import FieldError from "../../../../../../common/FieldError";
import { setFormState } from "../../../../../../../redux/actions/formActions";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.75rem;
`;

function AddressField({ id, data, formState, errorState, disabled }) {
  const dispatch = useDispatch();

  const handleChange = (event, field) => {
    dispatch(
      setFormState(id, { [field]: event.target.value })
    );
  };

  const handleField1 = (event) => {
    handleChange(event, data[0]?.field);
  };
  const handleField2 = (event) => {
    handleChange(event, data[1]?.field);
  };
  const handleField3 = (event) => {
    handleChange(event, data[2]?.field);
  };
  const handleField4 = (event) => {
    handleChange(event, data[3]?.field);
  };
  const handleField5 = (event) => {
    handleChange(event, data[4]?.field);
  };

  return (
    <Wrapper>
      <Container>
        <StyledLabel htmlFor={data[0]?.field}>
          {prepareLabel(data[0]?.field)}
        </StyledLabel>
        <FieldContainer>
          <StyledTextField
            id={data[0]?.field}
            type={"text"}
            value={formState[data[0]?.field]}
            placeholder={data[0]?.message || ""}
            disabled={disabled}
            maxLength={150}
            onChange={handleField1} />
          {errorState?.[data[0]?.field]?.length > 0 && (
            <FieldError message={errorState?.[data[0]?.field]} />
          )}
        </FieldContainer>
      </Container>
      <Container>
        <StyledLabel htmlFor={data[1]?.field}>
          {prepareLabel(data[1]?.field)}
        </StyledLabel>
        <FieldContainer>
          <StyledTextField
            id={data[1]?.field}
            type={"text"}
            value={formState[data[1]?.field]}
            placeholder={data[1]?.message || ""}
            disabled={disabled}
            maxLength={150}
            onChange={handleField2} />
          {errorState?.[data[1]?.field]?.length > 0 && (
            <FieldError message={errorState?.[data[1]?.field]} />
          )}
        </FieldContainer>
      </Container>
      <Container>
        <StyledLabel htmlFor={data[2]?.field}>
          {prepareLabel(data[2]?.field)}
        </StyledLabel>
        <FieldContainer>
          <StyledTextField
            id={data[2]?.field}
            type={"text"}
            value={formState[data[2]?.field]}
            placeholder={data[2]?.message || ""}
            disabled={disabled}
            maxLength={128}
            onChange={handleField3} />
          {errorState?.[data[2]?.field]?.length > 0 && (
            <FieldError message={errorState?.[data[2]?.field]} />
          )}
        </FieldContainer>
      </Container>
      <Container>
        <StyledLabel htmlFor={data[3]?.field}>
          {prepareLabel(data[3]?.field)}
        </StyledLabel>
        <FieldContainer>
          <StyledTextField
            id={data[3]?.field}
            type={"text"}
            value={formState[data[3]?.field]}
            placeholder={data[3]?.message || ""}
            disabled={disabled}
            maxLength={128}
            onChange={handleField4} />
          {errorState?.[data[3]?.field]?.length > 0 && (
            <FieldError message={errorState?.[data[3]?.field]} />
          )}
        </FieldContainer>
      </Container>
      <Container>
        <StyledLabel htmlFor={data[4]?.field}>
          {prepareLabel(data[4]?.field)}
        </StyledLabel>
        <FieldContainer>
          <StyledTextField
            id={data[4]?.field}
            type={"text"}
            value={formState[data[4]?.field]}
            placeholder={data[4]?.message || ""}
            disabled={disabled}
            maxLength={20}
            onChange={handleField5} />
          {errorState?.[data[4]?.field]?.length > 0 && (
            <FieldError message={errorState?.[data[4]?.field]} />
          )}
        </FieldContainer>
      </Container>
    </Wrapper>
  );
}

export default AddressField;
