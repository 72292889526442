export const HELP_PHRASES = [
  "i need help",
  "need help",
  "help needed",
  "talk to agent",
  "talk to human agent",
  "connect to human agent",
  "connect to an agent",
  "i want speak to human agent",
  "i want speak to an agent",
  "i need to talk to someone",
  "i need to talk to real human",
  "i want to speak with human agent"
];

export const NEED_HELP_INTENT = "Connect_Agent";

export const URGENT_QUERY = "Urgent Query";

export const ASK_AGAIN_PHRASE =
  "I'm sorry, I don't have that information. If you need help with something specific, please ask me another question.";

export const MISHIT_PHRASE = "I'm sorry, I couldn't answer your question. Do you need help on another topic?";

export const PLEASE_WAIT_PHRASE = "Please wait a few more moments while we try to answer your question.";

export const PLEASE_ELABORATE = "Can you please elaborate?";

export const AI_FAILED_PHRASE = "Bad input received";

export const ASK_FOR_EMAIL_PHRASE = "I understand you need further assistance. Could you please provide your email address to connect you with an agent?";

export const REENTER_EMAIL_PHRASE = "Please enter a valid email address.";

export const NO_AGENTS_AVAILABLE = "Thank you! Our agents are currently unavailable but this conversation will be shared with an agent who will get in touch with you soon.";

export const FAILED_TO_CONNECT = "Our agents are currently unavailable but this conversation will be shared with an agent who will get in touch with you soon.";

export const LEAVE_MESSAGE = "Would you like to provide any other details or should I submit a ticket?";

export const TICKET_CREATED_PHRASE = "Ticket created, an agent will get in touch with you soon.";

export const AUTO_SUBMISSION = "I'm sorry, I didn't receive a response from you. I've gone ahead and submitted the ticket on your behalf. An agent will be in touch with you shortly.";

export const CONNECTING_TO_AGENT_PHRASE = "Thank you! Connecting you to a live agent.";

export const CONNECTED_TO_AGENT_PHRASE = "You are now connected to a live agent.";

export const WAIT_FOR_AGENT = "Please wait, I'm processing your request.";

export const AGENT_CHAT_ENDED = "Everyone has left the chat and the conversation is marked as closed.";

export const RECONNECT_FAILED_WITH_AI = "I'm unable to answer any questions right now. Please try again later.";

export const AGENT_INACTIVITY_PHRASE = "Our agents are either offline or with another customer. You can wait for an agent to respond, or leave a message in the chat and we'll get back to you by email.";

export const AGENT_LEFT_PHRASE = "The agent has disconnected from the chat. You can either reset the chat to start another session or leave a message for us and we'll get back to you by email.";

export const WORKFLOWS_FOUND = "Need more help? Here are some potential next steps related to your question.";

export const WORKFLOWS_MATCHED = "Here are some potential next steps related to your question.";

export const WORKFLOW_SUCCESS = "Workflows has successfully executed";

export const WORKFLOW_START = "Workflows execution has started";

export const WORKFLOW_CANCELLED_PHRASE = "Can I help you with anything else?"

export const EMPTY_AUDIO_TRANSCRIPT_PHRASE = "I could not understand what you were saying. You can either try again or type out a message.";

export const MIC_ACCESS_PHRASE = "Allow microphone access";

export const RECORDING_PHRASE = "Listening...";
export const STOP_RECORDING_PHRASE = "Click to stop";

export const ASK_LANGUAGE_PHRASE = "Which language would you prefer to communicate in? Please respond with the name of the language in a single word.";
export const ASK_LANGUAGE_HELP_PHRASE = "Please respond with the name of the language in a single word."

export const QNA_FAILED_PHRASE = "Exhausted all attempts, please try again later.";
export const QNA_DEFAULT_HELP_PHRASE = "Incorrect response, please try again.";
