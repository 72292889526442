import { useMemo } from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";
import FormControl from "@mui/material/FormControl";
import NativeSelect from '@mui/material/NativeSelect';

import StyledScrollbarDiv from "../../../../../../common/scrollbar";
import {
  StyledFormLabel,
  StyledToggleButton,
  StyledInputBase
} from "../../../../../../common/formFields";
import FieldError from "../../../../../../common/FieldError";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";
import convertTime from "../../../../../../../lib/convertTime";
import {
  FULL_DAY_NAMES,
  SHORT_MONTH_NAMES
} from "../../../../../../../constants/time";

const Container = styled(StyledScrollbarDiv)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  row-gap: 0.75rem;
`;

const DateWrapper = styled.div`
  width: 100%;
`;

const TimeWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isRange }) =>
    `repeat(auto-fill, minmax(${isRange ? 188 : 94}px, 1fr))`};
  gap: 0.5rem;
`;

const TimeToggleButton = styled(StyledToggleButton)`
  flex-direction: column;
  row-gap: 0.5rem;
  color: ${({ theme, selected }) =>
    selected ? "#fff" : theme.fontColor};
  border: 1px solid ${({ theme, selected }) =>
    selected ? theme.primaryColor : theme.mediumGrey};
  overflow: hidden;
  &:hover {
    ${({ theme, selected, disabled }) => !selected && !disabled && `
      border-color: ${theme.primaryColor};
      color: ${theme.primaryColor};
    `}
  }
`;

const TimeSlot = styled.span`
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeSlotLabel = styled(TimeSlot)`
  font-size: ${({ theme }) => theme.reg};
`;

function formatDate(date) {
  return `${FULL_DAY_NAMES[date.getDay()]}, ${
    date.getDate()
  } ${SHORT_MONTH_NAMES[date.getMonth()]}`;
}

function isValidDateString(dateString) {
  try {
    return new Date(dateString).toString() !== "Invalid Date";
  } catch(error) {
    return false;
  }
}

function isValidTimeSlot(slot) {
  if (!slot || typeof(slot) !== "object") {
    return false;
  }
  if (!isValidDateString(slot.start ?? "")) {
    return false;
  }
  if (slot.end && !isValidDateString(slot.end ?? "")) {
    return false;
  }
  return true;
}

function formatTimeSlot(slot) {
  if (typeof(slot) === "string") {
    return [slot, convertTime(new Date(slot).toTimeString().slice(0, 5))];
  }
  return [
    slot.start,
    {
      start: convertTime(new Date(slot.start).toTimeString().slice(0, 5)),
      end: slot.end
        ? convertTime(new Date(slot.end).toTimeString().slice(0, 5))
        : null,
      label: slot.label
    }
  ];
}

function DateTimeGrid({ data, formState, error, disabled, updateFieldState }) {
  const theme = useTheme();

  const availableSlots = data?.dateTimeGrid ?? {};
  const availableDates = useMemo(
    () => Object.keys(availableSlots).filter(isValidDateString),
    []
  );

  const selectedDate = formState[`${data?.field}-date`];
  const selectedTime = formState[data?.field];

  const handleTimeChange = (slot) => {
    updateFieldState(data?.field, slot);
  };

  const handleDateChange = (event) => {
    updateFieldState(`${data?.field}-date`, event.target.value);
  };

  const availableTimeSlots = useMemo(
    () => (availableSlots[selectedDate] ?? [])
      .filter((dt) => isValidDateString(dt ?? "") || isValidTimeSlot(dt))
      .map(formatTimeSlot),
    [selectedDate]
  );

  return (
    <Container>
      <StyledFormLabel
        customTheme={theme}
        title={data?.message}
        sx={{ display: "none" }}
      >
        {prepareLabel(data?.field)}
      </StyledFormLabel>
      <DateWrapper>
        <FormControl fullWidth size={"small"}>
          <NativeSelect
            value={selectedDate ?? ""}
            disabled={disabled}
            title={data?.message}
            onChange={handleDateChange}
            input={<StyledInputBase customTheme={theme} />}
          >
            <option aria-label="None" value="" disabled>
              {data?.message || prepareLabel(data?.field)}
            </option>
            {availableDates?.map((v) =>
              <option key={v} value={v}>
                {formatDate(new Date(`${v.replaceAll("/", "-")}T00:00:00`))}
              </option>)}
          </NativeSelect>
        </FormControl>
        {error?.length > 0 && (
          <FieldError message={error} />
        )}
      </DateWrapper>
      {selectedDate && (
        <TimeWrapper isRange={availableTimeSlots.some((s) => !!s?.[1]?.end)}>
          {availableTimeSlots.map((d) => (
            <TimeToggleButton
              disabled={disabled}
              selected={d[0] === selectedTime}
              onClick={() => handleTimeChange(d[0])}
            >
              <TimeSlot>
                {typeof(d[1]) === "object"
                  ? `${d[1].start}${d[1].end ? (" - " + d[1].end) : ""}`
                  : d[1]}
              </TimeSlot>
              {typeof(d[1]) === "object" && !!d[1].label && (
                <TimeSlotLabel>
                  {d[1].label}
                </TimeSlotLabel>
              )}
            </TimeToggleButton>
          ))}
        </TimeWrapper>
      )}
    </Container>
  );
}

export default DateTimeGrid;
