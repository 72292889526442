import { useDispatch } from "react-redux";
import styled from "styled-components";
import parse from "html-react-parser";
import uuid from "react-uuid";
import { domToReact, attributesToProps } from "html-react-parser";
import { useSpring, animated } from "@react-spring/web";

import StyledLink from "../../../../common/styledLink";
import ArticleSource from "../../../../common/ArticleSource";
import FeedbackBar from "../../../../common/FeedbackBar";
import { openArticle } from "../../../../../redux/actions/contentActions";
import { logEventRecord } from "../../../../../redux/actions/chatActions";
import {
  TITLE_SEARCH_SUB_EVENT,
  VIEW_ARTICLE_EVENT
} from "../../../../../constants/events";

export const ResourceCardContainer = styled(animated.div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.875rem;
  width: 100%;
  padding: 1rem 0.75rem;
  border-radius: 1rem;
  font-size: ${({ theme }) => theme.md};
  font-family: ${({ theme }) => theme.fontFamily};
  font-color: ${({ theme }) => theme.fontColor};
  background-color: ${({ theme }) => theme.secondaryColor};
`;

const Title = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Snippet = styled.div`
  line-height: 1.5;
`;

function ResourceCard({ article, feedback, query, setFeedback }) {
  const dispatch = useDispatch();

  const [spring] = useSpring(() => ({
    from: {
      opacity: 0,
      y: 50
    },
    to: {
      opacity: 1,
      y: 0
    }
  }), [article]);

  const viewArticle = (a) => {
    dispatch(logEventRecord(
      query,
      null,
      uuid(),
      VIEW_ARTICLE_EVENT,
      TITLE_SEARCH_SUB_EVENT,
      true,
      a?.title || ""
    ));
    dispatch(openArticle({
      articleId: a.articleId || a.aid,
      meta: {
        ...a
      }
    }));
  };

  const parseOptions = {
    replace: (node) => {
      if (node?.name === 'a') {
        const anchorProps = attributesToProps({
          ...node.attribs,
          target: '_blank',
          rel: 'noreferrer'
        });
        return (
          <StyledLink {...anchorProps}>
            {domToReact(node.children, parseOptions)}
          </StyledLink>
        );
      }
    }
  };

  return (
    <ResourceCardContainer style={spring}>
      <Title>
        <ArticleSource
          article={article}
          query={query}
          eventSubtype={TITLE_SEARCH_SUB_EVENT} />
      </Title>
        <Snippet>
          {parse(
            (article?.firstLine || "").slice(0, 125),
            parseOptions
          )}&nbsp;
          <StyledLink
            title="Show more"
            onClick={() => viewArticle(article)}
          >
            Show More
          </StyledLink>
        </Snippet>
      <FeedbackBar value={feedback} setFeedback={setFeedback} />
    </ResourceCardContainer>
  )
}

export default ResourceCard;
