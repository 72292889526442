import styled, { useTheme } from "styled-components";
import parse from "html-react-parser";
import { domToReact, attributesToProps } from "html-react-parser";

import Skeleton from "@mui/material/Skeleton";

import StyledLink from "../../../../../common/styledLink";

const StyledImg = styled.img`
  max-width: 100%;
`

const Heading2 = styled.p`
  font-size: ${({ theme }) => theme.xl};
  font-weigth: bold;
`;

const Heading3 = styled.p`
  font-size: ${({ theme }) => theme.lg};
`;

const handleAnchorTag = (node, parseOptions) => {
  const anchorProps = attributesToProps({
    ...node.attribs,
    target: "_blank",
    rel: "noreferrer"
  });
  return (
    <StyledLink {...anchorProps}>
      {domToReact(node.children, parseOptions)}
    </StyledLink>
  );
};

const handleImgTag = (node) => {
  return (
    <StyledImg
      src={node?.attribs?.src}
      alt={node?.attribs?.alt || ""}
      srcSet={node?.attribs?.srcset}
      sizes={node?.attribs?.sizes} />
  );
};

const handleFigureTag = (node) => {
  for (const child of (node?.children || [])) {
    if (child?.name === "img" && child?.attribs?.src) {
      return (
        <figure>
          {handleImgTag(child)}
        </figure>
      );
    }
  }
  return;
};

const removeLineBreaksInNode = (node, parseOptions) => {
  const removeEmpty = (n) => n?.name !== "br";
  return domToReact(
    node?.children?.filter(removeEmpty),
    parseOptions
  );
};

const parseOptions = {
  replace: (node) => {
    switch(node?.name) {
      case "h1":
      case "h2":
        return (
          <Heading2>
              {domToReact(node.children, parseOptions)}
          </Heading2>
        );
      case "h3":
        return (
          <Heading3>
            {domToReact(node.children, parseOptions)}
          </Heading3>
        );
      case "a":
        return handleAnchorTag(node, parseOptions);
      case "p":
        if (!node?.children?.length) {
          return (
            <p>
              <br/>
            </p>
          );
        }
        return;
      case "div":
        return (
          <div {...node?.attribs}>
            {removeLineBreaksInNode(node, parseOptions)}
          </div>
        );
      case "li":
        return (
          <li {...node?.attribs}>
            {removeLineBreaksInNode(node, parseOptions)}
          </li>
        );
      case "figure":
        return handleFigureTag(node);
      case "img":
        return handleImgTag(node);
      default:
        return;
    }
  }
};

function MarkdownRenderer({ article, isLoading }) {
  const theme = useTheme();

  return isLoading
    ? (
      <div>
        <Skeleton
          animation="wave"
          width="90%"
          height="2rem"
          sx={{ bgColor: theme.darkGrey }} />
        <Skeleton
          animation="wave"
          width="80%"
          height="3rem"
          sx={{ bgColor: theme.darkGrey }} />
        <Skeleton
          animation="wave"
          width="60%"
          height="1.5rem"
          sx={{ bgColor: theme.darkGrey }} />
      </div>
    ) : (
      <div>
        {parse(
          (article?.meta?.formattedText || "").replace?.(/(\r?\n)/g, "<br/>"),
          parseOptions
        )}
      </div>
    );
}

export default MarkdownRenderer;
