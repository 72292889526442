import { 
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore
} from "redux";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import orgReducer from "./reducers/orgReducer";
import chatReducer from "./reducers/chatReducer";
import contentReducer from "./reducers/contentReducer";
import formReducer from "./reducers/formReducer";
import speechSynthesisReducer from "./reducers/speechSynthesisReducer";
import mediaRecorderReducer from "./reducers/mediaRecorderReducer";
import attachmentReducer from "./reducers/attachmentReducer";

const rootReducer = combineReducers({
  org: orgReducer,
  chat: chatReducer,
  content: contentReducer,
  form: formReducer,
  speech: speechSynthesisReducer,
  recorder: mediaRecorderReducer,
  attachments: attachmentReducer
});

const middlewareEnhancer = applyMiddleware(thunk);

export const store = createStore(
  rootReducer,
  composeWithDevTools(middlewareEnhancer)
);

export default store;
