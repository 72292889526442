import styled from "styled-components";

const StyledScrollbarDiv = styled.div`
  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-height: thin;
    scrollbar-color: ${({ theme }) => theme.lightGrey} transparent;
  }

  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.lightGrey};
    border-radius: 6px;
    -webkit-border-radius: 6px;
    border: 3px solid ${({ theme }) => theme.lightGrey};
  }
`;

export default StyledScrollbarDiv;
