import styled from "styled-components";

const BackButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.xxxs} ${theme.xxs}`};
  background-color: transparent;
  box-shadow: none;
  text-transform: none;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  color: ${({ theme }) => theme.darkGrey};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition-duration: 0.25s;
  }
`;

export default BackButton;
