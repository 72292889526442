const ANCHOR_TAG_REGEX = /<\s*a[^>]*>[^<]*<\s*\/\s*a\s*>/g;

function checkProtocol(url) {
  try {
    const parsedUrl = new URL(url);
    // Remove the trailing dot in the pathname
    parsedUrl.pathname = parsedUrl.pathname.replace(/\.*$/, '');
    // Attempt to remove the trailing dot in the href
    parsedUrl.href = parsedUrl.href.replace(/\.*$/, '');

    const origin = parsedUrl.origin && parsedUrl.origin !== 'null'
      ? parsedUrl.origin
      : '';
    const linkText = `${origin}${parsedUrl.pathname}`;

    switch (parsedUrl.protocol) {
      case 'http:':
        parsedUrl.protocol = 'https:';
      case 'https:':
      case 'mailto:':
        return [true, parsedUrl.toString(), linkText];
      default:
        return [false, parsedUrl.toString(), linkText];
    }
  } catch (error) {
    console.error(`Error parsing URL: ${error.message}`);
    return [false, url, url];
  }
}

export function urlify(text) {
  const newText = text
    ?.replace(/&quot;/g, '"')
    ?.replace(/&lt;/g, '<')
    ?.replace(/&gt;/g, '>')
    ?.replace(/&#x27;/g, "'")
    ?.replace(/&amp;/g, "&");

  const anchorTagLocations = [];
  try {
    const matches = [...newText.matchAll(ANCHOR_TAG_REGEX)];
    for (const m of matches) {
      anchorTagLocations.push({
        start: m.index,
        end: m.index + m[0].length - 1
      });
    }
  } catch (error) {
    console.error(error);
  }
  
  const urlRegex =
    /[(href=(\"|\'))(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  return newText?.replace(urlRegex, (url, ...args) => {
    const offset = args[args.length - 2];

    // ignore anchor tags
    if (anchorTagLocations.some((l) => offset >= l.start && offset <= l.end)) {
      return url;
    }

    let urlReceived = url;
    let startBracket = false;
    let endBracket = false;
    if (urlReceived.charAt(0) === '(') {
      startBracket = true;
      urlReceived = urlReceived.slice(1);
    }
    if (urlReceived.charAt(url.length - 1) === ')') {
      endBracket = true;
      urlReceived = urlReceived.slice(0, -1);
    }
    if (urlReceived.includes('href')) {
      return urlReceived;
    } else {
      // handle email address with missing protocol
      let newUrl = "";
      const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;
      newUrl = urlReceived.replace(emailAddressPattern, 'mailto:$&');

      const [isValidProtocol, parsedUrl, linkText] = checkProtocol(newUrl);
      if(isValidProtocol) {
        newUrl = `<a href="${parsedUrl}" target="_blank" rel="noreferrer">${linkText}</a>`;
      }

      if (startBracket) {
        newUrl = '(' + newUrl;
      }
      if (endBracket) {
        newUrl = newUrl + ')';
      }
      return newUrl;
    }
  });
}
