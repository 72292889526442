import textToSpeechService from "../../services/textToSpeech";
import { base64ToBlob } from "../../lib/base64ToBlob";
import { textToSpeech } from "../../api";

export const speechSynthesisActions = {
  SPEAK_UTTERANCE: "SPEAK_UTTERANCE",
  RESUME_UTTERANCE: "RESUME_UTTERANCE",
  PAUSE_UTTERANCE: "PAUSE_UTTERANCE",
  CANCEL_UTTERANCE: "CANCEL_UTTERANCE",
  CLEAR_UTTERANCE: "CLEAR_UTTERANCE",

  AUDIO_LOADING: "AUDIO_LOADING",
  AUDIO_LOADED: "AUDIO_LOADED"
};

export const loadAudio = (id) => {
  return {
    type: speechSynthesisActions.AUDIO_LOADING,
    payload: id
  };
};

export const audioLoaded = (id) => {
  return {
    type: speechSynthesisActions.AUDIO_LOADED,
    payload: id
  };
};

export const speakUtterance = (id, text) => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataUrls = state?.speech?.dataUrls;
    const orgId = state?.org?.details?.id;
    const content = {};

    for (const item of dataUrls) {
      if (item?.id === id && item?.url) {
        content.id = item.id;
        content.url = item.url;
        content.mimeType = item.mimeType;
        break;
      }
    }

    if (!content?.url) {
      try {
        const response = await textToSpeech(orgId, text, {
          orgName: state?.org?.subdomain,
          conversationId: state?.chat?.chatRecordId,
          platform: state?.org?.platform,
          profile: state?.org?.profile,
          parentOrigin: state?.org?.parentOrigin
        });
        const { b64Audio, mimeType } = response.data;
        const blob = base64ToBlob(b64Audio, mimeType);
        const objectUrl = URL.createObjectURL(blob);

        content.id = id;
        content.url = objectUrl;
        content.mimeType = mimeType;
      } catch(error) {
        console.warn("Readyly ChatAssist: failed to generate audio for text!");
        return;
      }
    }

    dispatch(audioLoaded(content.id));

    if (state?.recorder?.recordingAudio) {
      return;
    }

    const handlers = {
      play: () => dispatch(resumeUtterance()),
      pause: () => dispatch(pauseUtterance()),
      ended: () => dispatch(clearUtterance(content.id)),
      abort: () => dispatch(cancelUtterance()),
      error: () => dispatch(cancelUtterance())
    };

    try {
      await textToSpeechService.speak(content.url, content.mimeType, handlers);
    } catch(error) {
      console.warn(`Readyly ChatAssist: ${error.message}`);
      return;
    }

    if (state?.recorder?.recordingAudio) {
      dispatch(pauseUtterance());
      return;
    }

    dispatch({
      type: speechSynthesisActions.SPEAK_UTTERANCE,
      payload: { ...content }
    });
  };
};

export const resumeUtterance = () => {
  return async (dispatch) => {
    try {
      await textToSpeechService.resume();
    } catch(error) {
      console.warn(`Readyly ChatAssist: ${error.message}`)
      return;
    }
    dispatch({
      type: speechSynthesisActions.RESUME_UTTERANCE
    });
  };
};

export const pauseUtterance = () => {
  return async (dispatch) => {
    await textToSpeechService.pause();

    dispatch({
      type: speechSynthesisActions.PAUSE_UTTERANCE
    });
  }
};

export const cancelUtterance = () => {
  return async (dispatch, getState) => {
    const speaking = getState()?.speech?.speaking;
    const paused = getState()?.speech?.paused;

    if (!speaking && !paused) {
      return;
    }

    await textToSpeechService.cancel();
    dispatch({ type: speechSynthesisActions.CANCEL_UTTERANCE });
  }
};

export const clearUtterance = (id) => {
  return async (dispatch) => {
    await textToSpeechService.cancel();
    dispatch({
      type: speechSynthesisActions.CLEAR_UTTERANCE,
      payload: { id }
    });
  }
}
