export function censorPII(text) {
  text = text ?? "";
  const patterns = [
    /\b((\(?\d{3}\)?|\d{2,3})[\s.-]?\d{3,4}[\s.-]?\d{4}|\d{4,5}[\s.-]?\d{6}|\d{9,10})\b/g, // Phone numbers
    // /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g, // Email addresses
    /\b(\d{4}[-\s]?){3}\d{4}\b/g, // Credit card numbers
    /\b(\d{9}|\d{3}[-\s]+\d{2}[-\s]+\d{4})\b/g // Social Security numbers
  ];
  for (const pattern of patterns) {
    text = text.replace(pattern, "<redacted>");
  }
  return text;
}
