import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import levenshtein from "fast-levenshtein";
import uuid from "react-uuid";

import {
  addConversation,
  setActiveReplies,
  updateQuery,
  beginSwitchToAgent,
  getChatActions,
  setEndUserDetails,
  clearQnaResults,
  setBotTyping,
  chatEnded,
  workflowExecutionStart,
  logEventRecord,
  cancelSwitchToAgent
} from "../redux/actions/chatActions";
import { uploadAttachment } from "../redux/actions/attachmentActions";
import { chatWithAI, handleAutoReply } from "../lib/chat/replyOptions";
import { getConfigValueByPersona } from "../lib/chat/getConfigValue";
import {
  CREATE_TICKET,
  REPLY_IS_AUTO,
  REPLY_IS_QNA,
  REPLY_IS_QUERY,
  SWITCH_TO_AGENT,
  CLOSE_CHAT,
  EXECUTE_WORKFLOW,
  UPDATE_USER_DETAILS,
  CANCEL_QNA,
  CUSTOM_ESCALATION
} from "../constants/replies";
import {
  EMPTY_AUDIO_TRANSCRIPT_PHRASE,
  PLEASE_ELABORATE,
  WAIT_FOR_AGENT
} from "../constants/phrases";
import { AI_BOT, END_USER } from "../constants/user";
import {
  AUTO_LANGUAGE_DETECTION,
  CHAT_ON_AMAZON_CONNECT,
  CHAT_WITH_AGENT,
  CHAT_WITH_BOT,
  CHAT_WITH_QNA_BOT,
  MESSAGE_RECEIVED,
  MESSAGE_SENT,
  PICK_THE_LANGUAGE
} from "../constants/chat";
import {
  ESCALATION_EVENT,
  SUBMIT_TICKET_EVENT,
  REPLY_OPTION_SUB_EVENT,
  INITIAL_PROMPT_EVENT,
  EXECUTE_WORKFLOW_EVENT,
  INTERACT_SUB_EVENT
} from "../constants/events";

function useChat(
  webSocketContext,
  orgDetails,
  activeReplies = [],
  localizeText = (v) => v
) {
  const chatWith = useSelector((state) => state?.chat?.chatWith);
  const qna = useSelector((state) => state?.chat?.qna);
  const qnaComplete = useSelector((state) => state?.chat?.qnaComplete);

  const switchingToAgent = useSelector((state) => state?.chat?.switchingToAgent);
  const createTicket = useSelector((state) => state?.chat?.createTicket);
  const chatMedium = useSelector((state) => state?.chat?.chatMedium);

  const customizations = useSelector((state) => state?.org?.customizations);

  const endUser = useSelector((state) => state?.chat?.endUser);
  const lang = useSelector((state) => state?.org?.lang);

  const dispatch = useDispatch();
  const chatActionsFn = getChatActions(dispatch);
  const { sendJsonMessage, amzSendMessage } = webSocketContext;

  const handleEmailQna = () => {
    if (!qnaComplete || !switchingToAgent) {
      return;
    }

    dispatch(clearQnaResults());

    const connectingToAgent = getConfigValueByPersona(
      customizations?.chatConfig,
      "connectingToAgent",
      endUser?.personas
    );
    askToWait(localizeText(connectingToAgent?.phrase));
    dispatch(setBotTyping(true));
    chatActionsFn.switchToAgent();
  };

  const redoLeaveMessageQnaFlow = () => {
    if (!qnaComplete || !createTicket) {
      return;
    }

    const ticketQna = getConfigValueByPersona(
      customizations?.chatConfig,
      "createTicketQna",
      endUser?.personas
    );
    dispatch(clearQnaResults());
    chatActionsFn.beginQnaFlow(ticketQna);
  };

  useEffect(redoLeaveMessageQnaFlow, [customizations, qnaComplete, createTicket]);
  useEffect(handleEmailQna, [customizations, qnaComplete, switchingToAgent]);

  const clearActiveReplies = () => {
    dispatch(setActiveReplies([]));
  };

  const askToElaborate = (autoTextToSpeech = false) => {
    const phrase = getConfigValueByPersona(
      customizations?.chatConfig,
      "pleaseElaboratePhrase",
      endUser?.personas
    );
    dispatch(addConversation({
      autoTextToSpeech,
      type: AI_BOT,
      subtype: MESSAGE_RECEIVED,
      message: localizeText(phrase)
    }));
  };

  const askToWait = (phrase = "", autoTextToSpeech = false) => {
    if (phrase?.length > 0) {
      dispatch(addConversation({
        autoTextToSpeech,
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: phrase
      }));
    }
  };

  const renderUserQuery = (query) => {
    dispatch(addConversation({
      type: END_USER,
      subtype: MESSAGE_SENT,
      message: query
    }));
    dispatch(updateQuery(query));
  };

  const logEvent = (
    eventType,
    query = "",
    title = "",
    subtype = REPLY_OPTION_SUB_EVENT,
    meta = null
  ) => {
    dispatch(logEventRecord(
      query,
      null,
      uuid(),
      eventType,
      subtype,
      true,
      title,
      null,
      meta
    ));
  };

  const handleSwitchToAgent = (option) => {
    dispatch(beginSwitchToAgent());
    const emailQna = getConfigValueByPersona(
      customizations?.chatConfig,
      "switchToAgentQna",
      endUser?.personas
    );
    chatActionsFn.beginQnaFlow(emailQna);
    chatActionsFn.creatingChatRecord();
    logEvent(
      ESCALATION_EVENT,
      option?.data?.previousQuery || option?.data?.query,
      option?.data?.query,
      INTERACT_SUB_EVENT
    );
  };

  const beginWorkflowExecution = (query, option) => {
    sendJsonMessage({
      action: "stateMachine",
      orgid: orgDetails?.id,
      workflowid: option?.data?.workflowId,
      endUser
    });
    const workflowTitle = option?.data?.title || option?.data?.query;
    dispatch(workflowExecutionStart(
      option?.data?.workflowId,
      option?.data?.version,
      workflowTitle,
      option?.data?.workflowType
    ));
    dispatch(setBotTyping(true));
    logEvent(
      EXECUTE_WORKFLOW_EVENT,
      query || option?.data?.query,
      `Workflow Name: ${workflowTitle || "<Missing Title>"}`,
      REPLY_OPTION_SUB_EVENT,
      {
        id: option?.data?.workflowId,
        type: option?.data?.workflowType,
        title: option?.data?.title || workflowTitle,
        version: option?.data?.version
      }
    );
  };

  const handleCustomEscalation = (option) => {
    let action = getConfigValueByPersona(
      customizations?.chatConfig,
      "customEscalations",
      endUser?.personas
    );
    if (
        !action?.type
        || action?.type === SWITCH_TO_AGENT
        || action?.type === CREATE_TICKET
    ) {
      handleSwitchToAgent(action);
      return;
    }
    executeReplyOption(action);
    chatActionsFn.creatingChatRecord();
    logEvent(
      ESCALATION_EVENT,
      option?.data?.previousQuery || option?.data?.query,
      option?.data?.query,
      INTERACT_SUB_EVENT
    );
  };

  const updateConversationCache = (option) => {
    sendJsonMessage({
      action: "updateConversation",
      orgid: orgDetails?.id,
      userQuery: option?.data?.query,
      botAnswer: option?.data?.response,
      role: END_USER,
      personas: Array.isArray(endUser?.personas)
        ? endUser?.personas
        : []
    });
  };

  const getLanguageName = () => {
    if (typeof(lang) !== "string") {
      return;
    }
    if (lang === PICK_THE_LANGUAGE || lang === AUTO_LANGUAGE_DETECTION) {
      return null;
    }
    const language = customizations?.localizationConfig?.supported?.[lang]
      || lang;
    return typeof(language) === "string"
      ? language.toLowerCase()
      : null;
  };

  const executeReplyOption = (option, query = "", autoTextToSpeech = false) => {
    clearActiveReplies();

    option = {
      ...option,
      data: {
        ...option?.data
      }
    };

    if (option?.data?.query) {
      option.data.query = localizeText(option.data.query);
    }
    if (option?.data?.response) {
      option.data.response = localizeText(option.data.response);
    }

    switch(option?.type) {
      case REPLY_IS_AUTO:
        updateConversationCache(option);
        handleAutoReply(option?.data, dispatch, autoTextToSpeech);
        break;
      case REPLY_IS_QUERY:
        chatWithAI(
          query || option?.data?.query,
          orgDetails,
          endUser,
          getLanguageName(),
          dispatch,
          sendJsonMessage,
          autoTextToSpeech
        );
        break;
      case REPLY_IS_QNA:
        chatActionsFn.beginQnaFlow(option?.data?.qna);
        break;
      case CUSTOM_ESCALATION:
        handleCustomEscalation(option);
        break;
      case SWITCH_TO_AGENT:
        handleSwitchToAgent(option);
        break;
      case CREATE_TICKET:
        dispatch(clearQnaResults());
        dispatch(setBotTyping(true));
        chatActionsFn.creatingTicket();
        chatActionsFn.creatingChatRecord();
        logEvent(SUBMIT_TICKET_EVENT, option?.data?.query);
        break;
      case CLOSE_CHAT:
        dispatch(chatEnded());
        if (chatWith === CHAT_WITH_AGENT) {
          if (chatMedium !== CHAT_ON_AMAZON_CONNECT) {
            chatActionsFn.handleLiveChatEnd(sendJsonMessage);
          }
          chatActionsFn.alertAgentDisconnected();
          chatActionsFn.switchBackToBot();
        }
        break;
      case EXECUTE_WORKFLOW:
        beginWorkflowExecution(query, option);
        break;
      case UPDATE_USER_DETAILS:
        updateConversationCache(option);
        dispatch(setEndUserDetails({ ...endUser, ...option?.data?.userDetails }));
        handleAutoReply(option?.data, dispatch, autoTextToSpeech);
        break;
      case CANCEL_QNA:
        dispatch(clearQnaResults());
        dispatch(cancelSwitchToAgent());
        if (option?.data?.response?.length > 0) {
          dispatch(addConversation({
            autoTextToSpeech,
            type: AI_BOT,
            subtype: MESSAGE_RECEIVED,
            message: option?.data?.response
          }));
        }
        break;
      default:
        break;
    }

    if (option?.opener) {
      logEvent(INITIAL_PROMPT_EVENT, option?.data?.query);
    }
  };

  const handleActiveReplies = (query, autoTextToSpeech = false) => {
    const lowerCasedQuery = query?.toLowerCase();
    const replyOptions = Array.isArray(activeReplies)
      ? activeReplies
      : [];

    for (const option of replyOptions) {
      try {
        const reply = localizeText(option?.data?.query)?.toLowerCase();
        let matched = false;

        if (reply?.length <= 5) {
          matched = lowerCasedQuery === reply;
        } else if (reply?.length <= 20) {
          matched = levenshtein.get(reply, lowerCasedQuery) <= 2;
        } else {
          matched = levenshtein.get(reply, lowerCasedQuery) <= 3;
        }

        if (matched) {
          executeReplyOption(option, query, autoTextToSpeech);
          return true;
        }
      } catch (error) {
        continue;
      }
    }

    return false;
  };

  const handleUserQuery = (input, autoTextToSpeech = false) => {
    const query = typeof(input) === "string"
      ? input.trim()
      : input?.data?.query;

    if (!query?.length) {
      if (autoTextToSpeech) {
        const phrase = getConfigValueByPersona(
          customizations?.chatConfig,
          "emptyAudioTranscriptPhrase",
          endUser?.personas
        );
        dispatch(addConversation({
          autoTextToSpeech,
          type: AI_BOT,
          subtype: MESSAGE_RECEIVED,
          message: localizeText(phrase)
        }));
      }
      return;
    }

    renderUserQuery(query);

    const handled = handleActiveReplies(query, autoTextToSpeech);
    if (handled) {
      return;
    } else {
      clearActiveReplies();
    }

    if (chatWith === CHAT_WITH_AGENT) {
      switch(chatMedium) {
        case CHAT_ON_AMAZON_CONNECT:
          amzSendMessage(query);
          break;
        default:
          chatActionsFn.sendLiveChatMessage(query, sendJsonMessage);
          break;
      }
      return;
    }
    if (chatWith === CHAT_WITH_QNA_BOT && !qnaComplete) {
      chatActionsFn.handleQnaResponse(query);
      return;
    }
    // completed email qna, but still connecting to slack
    // so asking user to wait
    if (switchingToAgent) {
      const pleaseWait = getConfigValueByPersona(
        customizations?.chatConfig,
        "pleaseWaitPhrase",
        endUser?.personas
      );
      askToWait(localizeText(pleaseWait), autoTextToSpeech);
      return;
    }
    if (chatWith === CHAT_WITH_BOT) {
      if (query?.length <= 1) {
        askToElaborate(autoTextToSpeech);
        return;
      }
      chatWithAI(
        query,
        orgDetails,
        endUser,
        getLanguageName(),
        dispatch,
        sendJsonMessage,
        autoTextToSpeech
      );
    }
  };

  const handleReplyOption = (option, query = "") => {
    renderUserQuery(localizeText(query || option?.data?.query));

    executeReplyOption(option, query);
  };

  const handleUserAttachment = (file) => {
    if (!file?.name) {
      return;
    }

    dispatch(uploadAttachment(file));
  }

  return {
    handleUserQuery,
    handleReplyOption,
    handleUserAttachment
  };
}

export default useChat;
