import { forwardRef } from "react";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { pSBC } from "../../../lib/pSBC";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: ${({ theme }) => theme.xxs};
  width: 100%;
  padding: ${({ theme }) => `${theme.sm} ${theme.xs}`};
  background-color: transparent;
  color: ${({ theme }) => theme.primaryColor};
  border-bottom: 1px solid ${({ theme }) => theme.veryLightGrey};
  &:hover {
    background-color: ${({ theme }) => theme.secondaryColor};
  }
  &:active {
    background-color: ${({ theme }) => pSBC(0.8, theme.primaryColor)};
  }
  &:focus {
    background-color: ${({ theme }) => pSBC(0.8, theme.primaryColor)};
  }
`;

export const CardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  row-gap: ${({ theme }) => theme.sm};
  font-family: ${({ theme }) => theme.fontFamily};
  cursor: pointer;
`;

export const CardTitle = styled.span`
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme, isActive }) => isActive
    ? theme.primaryColor
    : theme.fontColor};
  font-size: ${({ theme }) => theme.md};
  font-weight: bold;
  line-height: 1.5;
`;

export const CardContent = styled.p`
  color: ${({ theme }) => theme.fontColor};
  font-size: ${({ theme }) => theme.md};
  line-height: 1.25;
`;

export const CardFooter = styled.span`
  color: ${({ theme }) => theme.mediumGrey};
  font-size: ${({ theme }) => theme.sm};
`;

const iconStyle = {
  alignSelf: "center",
  fontSize: "inherit",
  color: "inherit",
  verticalAlign: "middle"
};

function Card({ title, content, footer, showIcon, clickHandler }, ref) {
  const isActive = typeof(clickHandler) === "function";
  return (
    <Container onClick={clickHandler} ref={ref} tabIndex={-1}>
      <CardContainer>
        <CardTitle isActive={isActive}>
          {title}
        </CardTitle>
        {content?.length > 0 && (
          <CardContent>{content}</CardContent>
        )}
        {footer?.length > 0 && (
          <CardFooter>{footer}</CardFooter>
        )}
      </CardContainer>
      {showIcon && (
        <KeyboardArrowRightIcon sx={iconStyle} />
      )}
    </Container>
  );
}

export default forwardRef(Card);
