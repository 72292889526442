import produce from "immer";
import uuid from "react-uuid";

import { urlify } from "../../lib/urlify";
import { markdownToHtml } from "../../lib/markdownToHtml";
import { prepareChatHeader, stringifyMessage } from "../../lib/chat/stringify";
import { getEndUserSlackIcon } from "../../lib/slack/userIcon";
import { chatActions } from "../actions/chatActions";
import { END_USER } from "../../constants/user";
import {
  CHAT_WITH_BOT,
  CHAT_ON_SLACK,
  CHAT_WITH_QNA_BOT,
  NO_INTERACTION,
  INTERACTED_WITH_AI,
  TRIED_SWITCH_TO_AGENT,
  INTERACTED_WITH_AGENT,
  NO_TICKET_CREATED,
  TRIED_CREATING_TICKET,
  TICKET_CREATED,
  CHAT_WITH_AGENT,
  ALTERNATE_INTERACTION,
} from "../../constants/chat";

const initialState = {
  chatStage: NO_INTERACTION,
  chatWith: CHAT_WITH_BOT,
  chatMedium: CHAT_ON_SLACK,
  slackUserIcon: getEndUserSlackIcon(),
  amzContactId: null,
  amzParticipantId: null,
  amzParticipantToken: null,
  endUser: {
    firstName: null,
    lastName: null,
    email: null
  },
  switchingToAgent: false,
  createTicket: false,
  qna: [{
    q: null,
    a: null,
    help: null,
    replies: [],
    validation: {
      regex: null
    },
    retries: null
  }],
  currentQna: -1,
  qnaComplete: false,
  chatRecordId: uuid(),
  aiChatRecord: "",
  agentChatRecord: "",
  leftDetailsRecord: "",
  lastSavedChatRecord: {
    messages: 0,
    recordLength: 0,
    timestamp: 0,
  },
  additionalDetails: "",
  chatEventsRecord: [],
  lastActivity: null,
  conversation: [],
  unreadMessages: [],
  currentWorkflow: {},
  pendingTriggers: [],
  activeReplies: [],
  query: "",
  chatOn: false,
  userDetails: {},
  isBotTyping: false,
  chatEvents: {} // stores the likes and dislikes
};

const chatReducer = produce((draft, action) => {
  switch(action.type) {
    case chatActions.UPDATE_ACTIVITY_TIMESTAMP:
      draft.lastActivity = {
        type: action.payload,
        timestamp: Date.now()
      };
      return;
    case chatActions.UPDATE_CHAT_WITH:
      draft.chatWith = action.payload;
      return;
    case chatActions.UPDATE_CHAT_MEDIUM:
      draft.chatMedium = action.payload;
      return;
    case chatActions.UPDATE_QUERY:
      draft.query = action.payload;
      return;
    case chatActions.ADD_CONVERSATION:
      const timestamp = Date.now();
      const c = {
        ...action.payload,
        timestamp,
        id: action.payload?.id || uuid()
      };

      const prepareRecord = (user, record) => record?.length
        ? `${record}\n${stringifyMessage(c)}`
        : `${prepareChatHeader(user)}\n${stringifyMessage(c)}`;

      if (draft.chatStage <= TRIED_SWITCH_TO_AGENT) {
        draft.aiChatRecord = prepareRecord(
          draft.endUser?.email || "anonymous",
          draft.aiChatRecord
        );
      } else if (draft.chatStage < NO_TICKET_CREATED) {
        draft.agentChatRecord =
          prepareRecord(draft.endUser?.email, draft.agentChatRecord);
      } else {
        draft.leftDetailsRecord =
          prepareRecord(draft.endUser?.email, draft.leftDetailsRecord);
      }

      let message = action?.payload?.message || "";
      if (action?.payload?.type !== END_USER) {
        draft.activeReplies = [
          ...draft.activeReplies,
          ...(c?.replies ?? [])
        ];
        message = markdownToHtml(message);
        message = urlify(
          message
            ?.replace(/&quot;/g, '"')
            ?.replace(/&lt;/g, '<')
            ?.replace(/&gt;/g, '>')
        );
      }

      if (action?.payload?.type === END_USER) {
        draft.lastActivity = {
          type: "chatmessage",
          timestamp
        };

        if (draft.chatStage < INTERACTED_WITH_AI) {
          draft.chatStage = INTERACTED_WITH_AI;
        }
      } else {
        draft.unreadMessages.push({ id: c.id, timestamp: c.timestamp });
      }

      draft.conversation.push({
        ...c,
        message
      });
      return;
    case chatActions.SET_ADDITIONAL_DETAILS:
      if (action.payload?.options?.append && draft.additionalDetails?.length) {
        draft.additionalDetails += `\n${action.payload.details}`;
        return;
      }
      draft.additionalDetails = action.payload.details;
      return;
    case chatActions.MARK_MESSAGE_AS_READ:
      for (let i = 0; i < draft.unreadMessages.length; i++) {
        if (draft.unreadMessages[i].id !== action.payload?.messageId) {
          continue;
        }
        draft.unreadMessages = [
          ...draft.unreadMessages.slice(i + 1)
        ];
        break;
      }
      return;
    case chatActions.EXECUTE_WORKFLOW_BEGIN:
      draft.currentWorkflow = {
        id: action.payload?.id,
        title: action.payload?.title,
        version: action.payload?.version,
        type: action.payload?.type,
        runInBackground: action.payload?.runInBackground
      };
      if (!action.payload?.runInBackground) {
        draft.activeReplies = [];
        if (draft.chatStage <= NO_INTERACTION) {
          draft.chatStage = ALTERNATE_INTERACTION;
        }
      }
      return;
    case chatActions.SET_WORKFLOW_DETAILS:
      draft.currentWorkflow = {
        ...draft.currentWorkflow,
        ...(action.payload ?? {})
      };
      return;
    case chatActions.EXECUTE_WORKFLOW_ERROR:
      draft.currentWorkflow = {};
      return;
    case chatActions.EXECUTE_WORKFLOW_END:
      draft.currentWorkflow = {};
      return;
    case chatActions.BOT_TYPING:
      draft.isBotTyping = action.payload;
      return;
    case chatActions.CHAT_STARTED_TRACK:
      draft.chatOn = true;
      return;
    case chatActions.CHAT_ENDED_TRACK:
      draft.chatOn = false;
      return;
    case chatActions.CLEAR_CHAT_TRACK:
      if (action.payload?.closeChat) {
        draft.chatOn = false;
      }
      draft.chatWith = CHAT_WITH_BOT;

      draft.chatRecordId = uuid();
      draft.chatStage = NO_INTERACTION;

      draft.switchingToAgent = false;
      draft.createTicket = false;

      draft.conversation = [];
      draft.activeReplies = [];
      draft.chatEvents = {};
      draft.lastActivity = null;
      draft.aiChatRecord = "";
      draft.agentChatRecord = "";
      draft.leftDetailsRecord = "";
      draft.additionalDetails = "";
      draft.lastSavedChatRecord = {
        messages: 0,
        recordLength: 0,
        timestamp: 0,
      };
      draft.chatEventsRecord = [];

      draft.qna = [];
      draft.qnaComplete = false;
      draft.currentQna = -1;

      draft.currentWorkflow = {};
      draft.pendingTriggers = [];

      draft.liveChatSocketUrl = null;
      draft.slackUserIcon = getEndUserSlackIcon();

      draft.amzContactId = null;
      draft.amzParticipantId = null;
      draft.amzParticipantToken = null;
      return;
    case chatActions.SET_ACTIVE_REPLIES:
      draft.activeReplies = action.payload;
      break;
    case chatActions.QNA_BEGIN:
      if (draft.chatStage === NO_INTERACTION) {
        draft.chatStage = ALTERNATE_INTERACTION;
      }
      draft.chatWith = CHAT_WITH_QNA_BOT;
      draft.qnaComplete = false;
      draft.currentQna = -1;
      draft.qna = action.payload;
      break;
    case chatActions.QNA_NEXT:
      if (draft?.qna?.[draft.currentQna]) {
        draft.qna[draft.currentQna].a = action.payload;
      }
      break;
    case chatActions.QNA_CURRENT_QUESTION:
      draft.currentQna = action.payload;
      break;
    case chatActions.QNA_SUCCESS:
      draft.chatWith = CHAT_WITH_BOT;
      if (draft?.qna?.[draft.currentQna]) {
        draft.qna[draft.currentQna].a = action.payload;
      }
      draft.qnaComplete = true;
      draft.currentQna = -1;
      break;
    case chatActions.QNA_FAILURE:
      if (draft?.qna?.[draft.currentQna]) {
        draft.qna[draft.currentQna].retries -= 1;
      }
      break;
    case chatActions.QNA_ERROR:
      draft.chatWith = CHAT_WITH_BOT;
      if (draft.switchingToAgent) {
        draft.switchingToAgent = false;
      }
      draft.currentQna = -1;
      break;
    case chatActions.QNA_CLEAR:
      draft.chatWith = CHAT_WITH_BOT;
      draft.qna = [];
      draft.qnaComplete = false;
      draft.currentQna = -1;
      break;
    case chatActions.SWITCH_TO_AGENT_BEGIN:
      if (draft.chatStage <= INTERACTED_WITH_AI) {
        draft.chatStage = TRIED_SWITCH_TO_AGENT;
      }
      draft.switchingToAgent = true;
      break;
    case chatActions.SWITCH_TO_AGENT_CANCEL:
      draft.switchingToAgent = false;
      break;
    case chatActions.SWITCH_TO_AGENT_SUCCESS:
      if (draft.chatStage <= TRIED_SWITCH_TO_AGENT) {
        draft.chatStage = INTERACTED_WITH_AGENT;
      }

      draft.switchingToAgent = false;
      draft.chatWith = CHAT_WITH_AGENT;
      draft.chatMedium = action.payload?.chatMedium;
      draft.liveChatSocketUrl = action.payload?.liveChatSocketUrl;

      draft.amzContactId = action.payload?.amzContactId;
      draft.amzParticipantId = action.payload?.amzParticipantId;
      draft.amzParticipantToken = action.payload?.amzParticipantToken;
      break;
    case chatActions.SWITCH_TO_AGENT_ERROR:
      draft.chatWith = CHAT_WITH_BOT;
      draft.liveChatSocketUrl = null;
      draft.switchingToAgent = false;

      draft.amzContactId = null;
      draft.amzParticipantId = null;
      draft.amzParticipantToken = null;
      break;
    case chatActions.ARCHIVE_AMAZON_CONNECT_CHAT:
      draft.amzContactId = null;
      draft.amzParticipantId = null;
      draft.amzParticipantToken = null;
      break;
    case chatActions.SET_CREATE_TICKET:
      if (draft.chatStage <= INTERACTED_WITH_AGENT) {
        draft.chatStage = NO_TICKET_CREATED;
      }
      draft.createTicket = action.payload;
      break;
    case chatActions.SET_END_USER_DETAILS:
      draft.endUser = {
        ...draft.endUser,
        ...action.payload
      };
      break;
    case chatActions.CREATE_TICKET_BEGIN:
      if (draft.chatStage <= NO_TICKET_CREATED) {
        draft.chatStage = TRIED_CREATING_TICKET;
      }
      break;
    case chatActions.CREATE_TICKET_ERROR:
      break;
    case chatActions.CREATE_TICKET_SUCCESS:
      if (draft.chatStage <= TRIED_CREATING_TICKET) {
        draft.chatStage = TICKET_CREATED;
      }
      break;
    case chatActions.CREATE_CHAT_RECORD_BEGIN:
      draft.lastSavedChatRecord = {
        prev: {
          ...draft.lastSavedChatRecord
        },
        messages: action.payload?.messages,
        recordLength: action.payload?.recordLength,
        timestamp: Date.now()
      };
      break;
    case chatActions.CREATE_CHAT_RECORD_ERROR:
      draft.lastSavedChatRecord = {
        ...draft.lastSavedChatRecord?.prev
      };
      break;
    case chatActions.CREATE_CHAT_RECORD_SUCCESS:
      draft.lastSavedChatRecord.prev = null;
      break;
    case chatActions.RECORD_CHAT_EVENT:
      if (action.payload.value) {
        draft.chatEventsRecord.push(action.payload.eventId);
      }
      if (action.payload.id !== null && action.payload.id !== undefined) {
        draft.chatEvents = {
          ...draft.chatEvents,
          [action.payload.subtype]: {
            ...draft.chatEvents[action.payload.subtype],
            [action.payload.id]: action.payload.value
          }
        };
      }
      break;
    case chatActions.RESET_CHAT_EVENTS:
      if (Object.keys(draft.chatEvents?.[action.payload] || {}).length) {
        draft.chatEvents = {
          ...draft.chatEvents,
          [action.payload]: {}
        };
      }
      break;
    case chatActions.ADD_PENDING_TRIGGER:
      for (let i = 0; i < draft.pendingTriggers.length; i++) {
        if (draft.pendingTriggers[i]?.trigger !== action.payload?.trigger) {
          continue;
        }
        draft.pendingTriggers[i] = action.payload;
        break;
      }
      draft.pendingTriggers.push(action.payload);
      break;
    case chatActions.CLEAR_PENDING_TRIGGERS:
      draft.pendingTriggers = [];
      break;
    default:
      return;
  }
}, initialState);

export default chatReducer;
