import {
  AUTO_EXECUTE_WORKFLOW,
  MIN_SOURCE_CONFIDENCE,
  MIN_WORKFLOW_CONFIDENCE,
  PICK_THE_LANGUAGE,
  AUTO_LANGUAGE_DETECTION,
  WORKFLOWS_ENABLED,
  WORKFLOW_MATCH_CONFIDENCE
} from "../../constants/chat";

function parseNumber(num, defaultNum, parser = parseFloat) {
  num = parser(num);
  return Number.isNaN(num)
    ? defaultNum
    : num
};

export function parseCustomizations(data) {
  const customizations = data?.ftAssistCustomizations;
  const workflowConfig = customizations?.workflowConfig ?? {};
  const responseConfig = customizations?.responseConfig ?? {};
  const kbSystem = customizations?.kbSystem ?? {};

  // refer to ../../constants/defaults for details on each property
  return {
    font: customizations?.font,
    theme: customizations?.theme,

    navbarConfig: customizations?.navbarConfig,
    chatConfig: customizations?.chatConfig,
    launchButtonConfig: customizations?.launchButtonConfig,

    conversationBanner: {
      config: customizations?.conversationBanner?.config,
      content: customizations?.conversationBanner?.content
    },
    timestampBanner: {
      config: customizations?.timestampBanner?.config,
      content: customizations?.timestampBanner?.content
    },

    validOrigins: customizations?.validOrigins ?? [],

    autoExpand: customizations?.autoExpand ?? true,
    noAutoTicketAfterHandoff: customizations?.noAutoTicketAfterHandoff ?? false,
    noTicketAfterHandoffFailure: customizations?.noTicketAfterHandoffFailure ?? false,

    enableKbSearch: customizations?.enableKbSearch ?? false,
    kbSystem: {
      name: kbSystem?.name,
      baseUrl: kbSystem?.baseUrl
    },

    workflowTriggers: customizations?.workflowTriggers ?? {},

    textToSpeechConfig: {
      chat: customizations?.textToSpeechConfig?.chat
    },
    voiceChatConfig: {
      enabled: customizations?.voiceChatConfig?.enabled ?? false
    },

    responseConfig: {
      sourceConfidenceThreshold: parseNumber(
        responseConfig?.sourceConfidenceThreshold,
        MIN_SOURCE_CONFIDENCE
      )
    },
    workflowConfig: {
      enableWorkflows: workflowConfig?.enableWorkflows ?? WORKFLOWS_ENABLED,
      autoExecution: workflowConfig?.autoExecution ?? AUTO_EXECUTE_WORKFLOW,
      minConfidenceThreshold: parseNumber(
        workflowConfig?.minConfidenceThreshold,
        MIN_WORKFLOW_CONFIDENCE
      ),
      highConfidenceThreshold: parseNumber(
        workflowConfig?.highConfidenceThreshold,
        WORKFLOW_MATCH_CONFIDENCE
      ),
      forms: {
        showHeader: workflowConfig?.forms?.showHeader ?? true,
        showTitle: workflowConfig?.forms?.showTitle ?? true,
        showIcon: workflowConfig?.forms?.showIcon ?? true,
        autoPopulate: workflowConfig?.forms?.autoPopulate ?? false,
        fields: workflowConfig?.forms?.fields ?? {}
      }
    },

    localizationConfig: {
      default: customizations?.localizationConfig?.default ?? "",
      initial: customizations?.localizationConfig?.initial ?? AUTO_LANGUAGE_DETECTION,
      supported: {
        ...customizations?.localizationConfig?.supported,
        [PICK_THE_LANGUAGE]: capitalizeString(PICK_THE_LANGUAGE),
        [AUTO_LANGUAGE_DETECTION]: capitalizeString(AUTO_LANGUAGE_DETECTION)
      }
    },

    tooltips: customizations?.tooltips ?? {}
  };
}

function capitalizeString(s) {
  return `${s[0].toUpperCase()}${s.slice(1)}`;
}
