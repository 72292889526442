import produce from "immer";

import { orgActions } from "../actions/orgActions";

const initialState = {
  platform: null,
  subdomain: null,
  parentOrigin: null,
  criticalFailure: false,
  details: {},
  profile: "default",
  lang: "",
  customizations: {},
  assetUrls: {
    images: null,
    fonts: null
  }
}

const orgReducer = produce((draft, action) => {
  switch(action.type) {
    case orgActions.SET_PLATFORM:
      draft.platform = action.payload;
      return;
    case orgActions.INVALID_PLATFORM:
      draft.criticalFailure = true;
      return;
    case orgActions.SET_ORG_SUBDOMAIN:
      draft.subdomain = action.payload;
      return;
    case orgActions.SET_CUSTOMIZATION_PROFILE:
      draft.profile = action.payload;
      return;
    case orgActions.SET_PARENT_ORIGIN:
      draft.parentOrigin = action.payload;
      return;
    case orgActions.FETCH_ORG_DETAILS_BEGIN:
      return;
    case orgActions.FETCH_ORG_DETAILS_SUCCESS:
      draft.details = action.payload;
      return;
    case orgActions.FETCH_ORG_DETAILS_ERROR:
      draft.criticalFailure = true;
      return;
    case orgActions.FETCH_ORG_CUSTOMIZATION_BEGIN:
      return;
    case orgActions.FETCH_ORG_CUSTOMIZATION_SUCCESS:
      const localization = action.payload?.customizations?.localizationConfig;
      const defaultLang = localization?.default;
      const initialLang = localization?.initial;
      draft.lang = defaultLang
        ? draft.lang || initialLang || defaultLang
        : "";
      draft.customizations = action.payload?.customizations;
      draft.assetUrls = action.payload?.assetUrls;
      return;
    case orgActions.FETCH_ORG_CUSTOMIZATION_ERROR:
      draft.criticalFailure = true;
      return;
    case orgActions.UPDATE_LANGUAGE:
      draft.lang = action.payload;
      return;
    default:
      return;
  }
}, initialState);

export default orgReducer;
