import MarkdownRenderer from "./MarkdownRenderer";
import PdfRenderer from "./PdfRenderer";
import DocxRenderer from "./DocxRenderer";

import { ARTICLE_IS_DOCX, ARTICLE_IS_PDF } from "../../../../../constants/article";

function ContentRenderer({ article, isLoading }) {
  switch (article?.meta?.fileType) {
    case ARTICLE_IS_DOCX:
      return <DocxRenderer article={article} />;
    case ARTICLE_IS_PDF:
      return <PdfRenderer article={article} />;
    default:
      return <MarkdownRenderer article={article} isLoading={isLoading} />
  }
}

export default ContentRenderer;
