import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "amazon-connect-chatjs";

import {
  archiveAmazonConnectChat,
  getChatActions,
  setBotTyping
} from "../redux/actions/chatActions";
import {
  CHAT_WITH_AGENT,
  AMZ_CONNECT_CUSTOMER_ROLE,
  AMZ_CONNECT_TEXT_PLAIN,
  AMZ_CONNECT_AGENT_ROLE
} from "../constants/chat";

function useAmazonConnect(orgDetails, customizations, chatOn, chatWith) {
  const contactId = useSelector((state) => state?.chat?.amzContactId);
  const participantId = useSelector((state) => state?.chat?.amzParticipantId);
  const participantToken = useSelector((state) => state?.chat?.amzParticipantToken);

  const sessionRef = useRef(null);
  const agentTypingRef = useRef(null);

  const dispatch = useDispatch();
  const chatActionsFn = getChatActions(dispatch);

  const canChat = chatOn
    && chatWith === CHAT_WITH_AGENT
    && contactId
    && participantId
    && participantToken;

  const amzSendMessage = (message, contentType = AMZ_CONNECT_TEXT_PLAIN) => {
    const chatSession = sessionRef.current;

    if (!chatSession || !canChat || !message.length || !contentType) {
      return;
    }

    chatSession.sendMessage({
      contentType,
      message
    });
  };

  const handleConnection = () => {
    if (!canChat || sessionRef.current) {
      return;
    }

    // eslint-disable-next-line no-undef
    connect.ChatSession.setGlobalConfig({
      region: orgDetails?.amazon_connect?.region
    });
    // eslint-disable-next-line no-undef
    const session = connect.ChatSession.create({
      chatDetails: {
        ContactId: contactId,
        ParticipantId: participantId,
        ParticipantToken: participantToken
      },
      type: AMZ_CONNECT_CUSTOMER_ROLE
    });

    const startConnect = async () => {
      try {
        await session.connect();
        sessionRef.current = session;
        chatActionsFn.alertAgentConnected();
      } catch(error) {
        console.warn("FastTrack Assist: " + JSON.stringify(error));
        chatActionsFn.switchToAgentFailed();
      }
    }
    startConnect();

    session.onMessage((message) => {
      const data = message?.data ?? {};

      chatActionsFn.handleAmazonConnectMessage(data, amzSendMessage);
    });

    session.onTyping((message) => {
      if (agentTypingRef.current) {
        return;
      }

      const data = message?.data ?? {};

      if (data?.ParticipantRole === AMZ_CONNECT_AGENT_ROLE) {
        dispatch(setBotTyping(true));
        agentTypingRef.current = setTimeout(() => {
          agentTypingRef.current = null;
          dispatch(setBotTyping(false));
        }, 5_000);
      }
    })

    session.onConnectionBroken(() => {
      console.log("FastTrack Assist: connect chat connection broken.");
      sessionRef.current = null;
      chatActionsFn.alertAgentDisconnected();
      chatActionsFn.switchBackToBot();
    });
  };

  const disconnect = () => {
    if (canChat || !sessionRef.current) {
      return;
    }

    const handler = async () => {
      try {
        console.log("FastTrack Assist: disconnecting participant...");
        await sessionRef.current.disconnectParticipant();
        sessionRef.current = null;
        dispatch(archiveAmazonConnectChat());
      } catch (error) {
        console.warn("FastTrack Assist: failed to disconnect connect chat!");
      }
    }
    handler();
  };

  useEffect(handleConnection, [
    canChat,
    orgDetails?.amazon_connect?.region,
    customizations?.chatConfig?.connectedToAgent
  ]);
  useEffect(disconnect, [canChat]);

  return {
    // eslint-disable-next-line no-undef
    amzConnect: connect,
    amzSession: sessionRef.current,
    amzSendMessage
  };
}

export default useAmazonConnect;
