import { useCallback } from "react";
import { useSelector } from "react-redux";

import { extractTextbyLang } from "../lib/intl";

function useLocalization() {
  const lang = useSelector((state) => state?.org?.lang);
  const localization = useSelector((state) => state?.org?.customizations?.localizationConfig);

  const localizeText = useCallback((mapping) => {
    return extractTextbyLang(mapping, lang, localization?.default);
  }, [lang, localization?.default]);

  const localizeTooltips = useCallback((tooltips) => {
    if (tooltips === null || tooltips === undefined) {
      return tooltips;
    }
    const localizedTooltips = {};
    for (const key of Object.keys(tooltips)) {
      localizedTooltips[key] = localizeText(tooltips[key]);
    }
    return localizedTooltips;
  }, [localizeText]);

  return { localizeText, localizeTooltips };
}

export default useLocalization;
