import produce from "immer";

import { speechSynthesisActions } from "../actions/speechSynthesisActions";

const MAX_DATA_URLS = 10;

const initialState = {
  speaking: false,
  paused: false,
  loadingAudio: [],
  content: {
    id: null,
    url: null,
    mimeType: null
  },
  dataUrls: []
};

const speechSynthesisReducer = produce((draft, action) => {
  const storeDataUrl = (content) => {
    for (const item of draft.dataUrls) {
      if (item?.id === content.id) {
        return;
      }
    }
    if (draft.dataUrls?.length > MAX_DATA_URLS) {
      try {
        const { url } = draft.dataUrls.shift();
        URL.revokeObjectURL(url);
      } catch(error) {
        console.warn("FastTrack Assist: failed to revoke object URL!")
      }
    }
    draft.dataUrls.push(content);
  };

  switch(action.type) {
    case speechSynthesisActions.SPEAK_UTTERANCE:
      const { id, url, mimeType } = action.payload || {};
      draft.content = { id, url, mimeType };
      storeDataUrl({ id, url, mimeType });
      draft.speaking = true;
      draft.paused = false;
      return;
    case speechSynthesisActions.RESUME_UTTERANCE:
      if (draft.paused) {
        draft.speaking = true;
        draft.paused = false;
      }
      return;
    case speechSynthesisActions.PAUSE_UTTERANCE:
      if (draft.speaking) {
        draft.speaking = false;
        draft.paused = true;
      }
      return;
    case speechSynthesisActions.CANCEL_UTTERANCE:
      storeDataUrl({ ...draft.content });
      draft.speaking = false;
      draft.paused = false;
      draft.content = {};
      return;
    case speechSynthesisActions.CLEAR_UTTERANCE:
      if (draft.content?.id !== action.payload?.id) {
        return;
      }
      storeDataUrl({ ...draft.content });
      draft.speaking = false;
      draft.paused = false;
      draft.content = {};
      return;
    case speechSynthesisActions.AUDIO_LOADING:
      for (const id of draft.loadingAudio) {
        if (id === action.payload) {
          return;
        }
      }
      draft.loadingAudio.push(action.payload);
      return;
    case speechSynthesisActions.AUDIO_LOADED:
      for (let i = 0; i < draft.loadingAudio.length; i++) {
        if (draft.loadingAudio[i] !== action.payload) {
          continue;
        }
        draft.loadingAudio = [
          ...draft.loadingAudio.slice(0, i),
          ...draft.loadingAudio.slice(i + 1),
        ]
        break;
      }
      return;
    default:
      return;
  }
}, initialState);

export default speechSynthesisReducer;
