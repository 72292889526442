import styled from "styled-components";
import CheckIcon from '@mui/icons-material/Check';

import {
  StyledToggleButton,
  StyledLabel
} from "../../../../../../common/formFields";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 1.25rem;
`;

function BooleanField({ data, value, disabled, updateFieldState }) {
  const handleChange = () => {
    updateFieldState(data?.field, !value);
  };

  return (
    <Container>
      <StyledLabel htmlFor={data?.field} title={data?.message}>
        {data?.message || prepareLabel(data?.field)}
      </StyledLabel>
      <StyledToggleButton
        id={data?.field}
        title={data?.message}
        selected={value}
        disabled={disabled}
        onClick={handleChange}
      >
        <CheckIcon sx={{ fontSize: "inherit", color: "inherit" }} />
      </StyledToggleButton>
    </Container>
  );
}

export default BooleanField;
