import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";

import { footerIcons } from "../../../../../assets/footer/index";

import {
  openChatSection,
  openResourcesSection
} from "../../../../../redux/actions/contentActions";
import {
  logEventRecord
} from "../../../../../redux/actions/chatActions";
import {
  CHAT_SECTION,
  RESOURCES_SECTION,
  SMALL_PHONE
} from "../../../../../constants/widget";
import { OPEN_CHAT_TAB_EVENT, OPEN_RESOURCE_TAB_EVENT, TAB_CHANGE_SUB_EVENT } from "../../../../../constants/events";
import useLocalization from "../../../../../hooks/localization";

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: ${({ theme }) => theme.sectionSelectorHeight};
  with: 100%;
`;

const SectionSwitch = styled.button`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  column-gap: 0.5rem;
  box-shadow: none;
  text-transform: none;
  backface-visibility: hidden;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  ${({ theme, selected }) => selected
    ? `
      color: ${theme.primaryColor};
      border-bottom: 4px solid ${theme.primaryColor};
    ` : `color: ${theme.lightGrey};`}
  &:hover {
    ${({ theme }) => `color: ${theme.primaryColor};`}
  }
`;

const BorderedSectionSwitch = styled(SectionSwitch)`
  border-right: 1px solid ${({ theme }) => theme.veryLightGrey};
  ${({ theme, isExpanded }) => isExpanded && `
    border-left: 1px solid ${theme.veryLightGrey};
  `}
`;

const Label = styled.span`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.lg};
  font-weight: bold;
`;

function SectionSelector({ isExpanded, windowDimensions }) {
  const subdomain = useSelector((state) => state?.org?.subdomain);
  const currentSection = useSelector((state) => state?.content?.currentSection);
  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.footer
  );

  const dispatch = useDispatch();

  const { localizeText } = useLocalization();

  const chatSectionActive = currentSection === CHAT_SECTION;
  const resourcesSectionActive = !chatSectionActive;

  const renderLabel = windowDimensions?.width > SMALL_PHONE;

  const logEvent = (eventType) => {
    dispatch(logEventRecord(
      null,
      null,
      uuid(),
      eventType,
      TAB_CHANGE_SUB_EVENT,
      true,
      ""
    ));
  };

  const handleClick = (sectionToOpen) => {
    if (sectionToOpen === currentSection) {
      return;
    }

    switch(sectionToOpen) {
      case CHAT_SECTION:
        dispatch(openChatSection());
        logEvent(OPEN_CHAT_TAB_EVENT);
        return;
      case RESOURCES_SECTION:
        dispatch(openResourcesSection());
        logEvent(OPEN_RESOURCE_TAB_EVENT);
        return;
      default:
        return;
    }
  };

  const renderChatIcon = () => {
    const icons = footerIcons.hasOwnProperty(subdomain)
      ? footerIcons[subdomain]
      : footerIcons.defaults;

    const Icon = chatSectionActive
      ? icons.chatIcons.filled
      : icons.chatIcons.outlined;
    const scale = icons.chatIcons.scale;

    return <Icon style={{transform: `scale(${scale})`}} />;
  };

  const renderResourceIcon = () => {
    const icons = footerIcons.hasOwnProperty(subdomain)
      ? footerIcons[subdomain]
      : footerIcons.defaults;

    const Icon = resourcesSectionActive
      ? icons.resourceIcons.filled
      : icons.resourceIcons.outlined;
    const scale = icons.resourceIcons.scale;

    return <Icon style={{transform: `scale(${scale})`}} />;
  };

  return (
    <Container>
      <BorderedSectionSwitch
        selected={chatSectionActive}
        isExpanded={isExpanded}
        onClick={() => handleClick(CHAT_SECTION)}
      >
        {renderChatIcon()}
        {renderLabel && (
          <Label>{localizeText(tooltips?.chatSection)}</Label>
        )}
      </BorderedSectionSwitch>
      <SectionSwitch
        selected={resourcesSectionActive}
        isExpanded={isExpanded}
        onClick={() => handleClick(RESOURCES_SECTION)}
      >
        {renderResourceIcon()}
        {renderLabel && (
          <Label>{localizeText(tooltips?.resourcesSection)}</Label>
        )}
      </SectionSwitch>
    </Container>
  );
}

export default SectionSelector;
