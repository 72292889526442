import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";

import { getChatActions, resetChatEvents } from "../redux/actions/chatActions";

function useFeedback(subtype) {
  const chatEvents = useSelector((state) => state?.chat?.chatEvents);

  const [feedbackToSave, setFeedbackToSave] = useState(null);

  const dispatch = useDispatch();
  const chatActionsFn = getChatActions(dispatch);

  const setFeedback = (query, id, eventType, value, title = "") => {
    setFeedbackToSave({
      query,
      id,
      eventType,
      value,
      title
    });
  };

  const saveFeedback = () => {
    if (!feedbackToSave) {
      return;
    }

    const timeoutRef = setTimeout(() => {
      chatActionsFn.logEventRecord(
        feedbackToSave.query,
        feedbackToSave.id,
        uuid(),
        feedbackToSave.eventType,
        subtype,
        feedbackToSave.value,
        feedbackToSave.title
      );
      setFeedbackToSave(null);
    }, 150); // small timeout to prevent spam

    return () => clearTimeout(timeoutRef);
  };

  useEffect(saveFeedback, [feedbackToSave, subtype]);

  const resetFeedback = () => {
    dispatch(resetChatEvents(subtype));
  };

  return {
    feedback: chatEvents?.[subtype] ?? {},
    setFeedback: setFeedback,
    resetFeedback
  };
}

export default useFeedback;
