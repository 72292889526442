import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import StyledScrollbarDiv from "../../../common/scrollbar";
import StyledLink from "../../../common/styledLink";
import FeedbackBar from "../../../common/FeedbackBar";
import BackButton from "../../../common/backButton";
import { openChat } from "../../../../redux/actions/contentActions";
import useFeedback from "../../../../hooks/feedbackHook";
import ContentRenderer from "./ContentRenderer";

import {
  ReactComponent as BookOutlinedIcon
} from "../../../../assets/footer/book_outlined.svg";

import { INTERACT_SUB_EVENT } from "../../../../constants/events";
import useLocalization from "../../../../hooks/localization";

const ArticleContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1.25rem;
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem 0;
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: 1.4;
  overflow-x: hidden;
`;

const BadgeContainer = styled(ArticleContainer)`
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.veryLightGrey};
`;

const Badge = styled.div`
  font-size: ${({ theme }) => theme.xl};
  font-weight: bold;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.xxl};
  margin-bottom: 1rem;
`;

const ContentContainer = styled(StyledScrollbarDiv)`
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0 1rem;
  font-size: ${({ theme }) => theme.md};
  overflow-y: auto;
`;

const LinksHeader = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.darkGrey};
`;

const FeedbackContainer = styled.div`
  margin-top: 1rem;
`;

const XtraStyledLink = styled(StyledLink)`
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
`;

const StyledLinkText = styled.span`
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

function Article({ showBackButton }) {
  const lastQuery = useSelector((state) => state?.chat?.query);
  const article = useSelector((state) => state?.content?.openArticle);
  const loadingArticle = useSelector((state) => state?.content?.loadingArticle);
  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.resources
  );

  const dispatch = useDispatch();

  const { feedback, setFeedback } = useFeedback(INTERACT_SUB_EVENT);
  const { localizeTooltips } = useLocalization();

  const localizedTooltips = localizeTooltips(tooltips);

  const backToChat = () => {
    dispatch(openChat());
  };

  if (!article?.articleId) {
    return (
      <BadgeContainer>
        <BookOutlinedIcon style={{ transform: "scale(2)" }} />
        <Badge>
          {localizedTooltips?.explore}
        </Badge>
      </BadgeContainer>
    );
  }

  return (
    <ArticleContainer>
      {(showBackButton && article?.articleId) && (
        <BackButton onClick={backToChat}>
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "inherit", color: "inherit" }} />
          &nbsp;{localizedTooltips?.back}
        </BackButton>
      )}
      <ContentContainer>
        <Title>
          {article?.meta?.title}
        </Title>
        <ContentRenderer article={article} isLoading={loadingArticle} />
        {article?.meta?.source && (
          <>
            <LinksHeader>
              {localizedTooltips?.relatedLinks}
            </LinksHeader>
            <XtraStyledLink
              href={article.meta.source}
              target="_blank"
              referrer="noreferrer"
              title={article.title}
            >
              <StyledLinkText>{article.meta.title}&nbsp;</StyledLinkText>
              <OpenInNewIcon
                sx={{
                  fontSize: "inherit",
                  color: "inherit",
                  verticleAlign: "middle"
                }} />
            </XtraStyledLink>
          </>
        )}
        <FeedbackContainer>
          <FeedbackBar
            value={feedback?.[article?.articleId]}
            setFeedback={
              (value, eventType) =>
                setFeedback(lastQuery, article?.articleId, eventType,
                  value, `Article: ${article?.meta?.title}`)
            } />
        </FeedbackContainer>
      </ContentContainer>
    </ArticleContainer>
  );
}

export default Article;
