import produce from "immer";

import { formActions } from "../actions/formActions";
import { BOOL_FIELD, DATE_TIME_GRID, EMAIL_FIELD } from "../../constants/form";

const initialState = {
  activeForm: null,
  forms: {}
};

const formReducer = produce((draft, action) => {
  switch(action.type) {
    case formActions.SET_FORM_FIELDS:
      draft.activeForm = action.payload?.id;
      draft.forms[action.payload?.id] = {
        fields: Array.isArray(action.payload?.fields)
          ? action.payload.fields
          : [],
        state: {}
      };

      const populateState = (f) => {
        switch (f?.type?.toLowerCase()) {
          case EMAIL_FIELD:
            draft.forms[action.payload?.id].state[f?.field] =
              action.payload?.initialValues?.email ?? "";
            break;
          case BOOL_FIELD:
            draft.forms[action.payload?.id].state[f?.field] = false;
            break;
          case DATE_TIME_GRID:
            draft.forms[action.payload?.id].state[f?.field] = "";
            draft.forms[action.payload?.id].state[`${f?.field}-date`] = "";
            break;
          default:
            draft.forms[action.payload?.id].state[f?.field] = "";
            break;
        }
      };

      for (const f of draft.forms[action.payload?.id].fields) {
        if (f?.field) {
          populateState(f);
        }
        if (Array.isArray(f?.fields)) {
          for (const fs of f?.fields) {
            populateState(fs);
          }
        }
      }
      break;
    case formActions.SET_FORM_STATE:
      draft.forms[action.payload?.id].state = {
        ...draft.forms[action.payload?.id].state,
        ...action.payload?.state
      };
      break;
    case formActions.CLEAR_ACTIVE_FORM:
      draft.activeForm = null;
      break;
    default:
      break;
  }
}, initialState);

export default formReducer;
