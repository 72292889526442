import { useTheme } from "styled-components";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";

import FieldError from "../../../../../../common/FieldError";
import { HiddenLabel, FieldContainer } from "../../../../../../common/formFields";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

import "react-phone-input-2/lib/style.css";
import "./phone.css";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.75rem;
`;

function PhoneField({ data, value, error, disabled, updateFieldState }) {
  const theme = useTheme();

  const handleChange = (val) => {
    updateFieldState(data?.field, val);
  };

  return (
    <Container>
      <HiddenLabel htmlFor={data?.field}>
        {prepareLabel(data?.field)}
      </HiddenLabel>
      <FieldContainer>
        <PhoneInput
          id={data?.field}
          country={""}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          placeholder={data?.message || prepareLabel(data?.field)}
          inputStyle={{
            fontSize: theme.md,
            fontFamily: theme.fontFamily,
            borderRadius: "1.25rem",
            border: `1px solid ${theme.mediumGrey}`,
            height: "2.375rem",
            width: "100%"
          }}
          buttonStyle={{
            borderRadius: "1.25rem 0 0 1.25rem",
            border: `1px solid ${theme.mediumGrey}`
          }} />
        {error?.length > 0 && (
          <FieldError message={error} />
        )}
      </FieldContainer>
    </Container>
  );
}

export default PhoneField;
