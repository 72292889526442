import styled from "styled-components";
import CircleIcon from '@mui/icons-material/Circle';
import { VALID_URL_REGEX } from "../../../../../../constants/regex";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
  padding: 0 0.5rem;
  line-height: 1.5;
  font-size: ${({ theme }) => theme.sm};
  color: ${({ theme }) => theme.lightGrey};
  margin-bottom: 0.5rem;
  overflow-x: hidden;
  white-space: nowrap;
  user-select: none;
`;

const NoStyleAnchor = styled.a`
  text-decoration: inherit;
  color: inherit;
  cursor: ${({ isLinkValid }) => isLinkValid
    ? "pointer"
    : "default"};
  user-select: none;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: ${({ isLinkValid }) => isLinkValid
    ? "pointer"
    : "default"};
  }
  &:hover {
    color: ${({ theme, isLinkValid }) => isLinkValid
      ? theme.primaryColor
      : "inherit"};
  }
`;

function ConversationHeader({ duration, timestampBanner }) {
  const displayTimestampBanner = !!timestampBanner?.content?.length;
  const bannerLink = typeof(timestampBanner?.config?.linkTo) === "string"
    ? timestampBanner?.config?.linkTo
    : "";
  const isLinkValid = VALID_URL_REGEX.test(bannerLink);

  const handleClick = (evt) => {
    if (isLinkValid) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
  };

  return (
    <Container>
        {duration}
        {displayTimestampBanner && (
          <>
            <CircleIcon sx={{ fontColor: "inherit", fontSize: "0.5rem" }} />
            <NoStyleAnchor
              target="_blank"
              rel="noreferrer noopener"
              href={bannerLink || "#"}
              onClick={handleClick}
              isLinkValid={isLinkValid}
            >
              {timestampBanner?.content}
            </NoStyleAnchor>
          </>
        )}
    </Container>
  )
}

export default ConversationHeader;