const MIME_TYPES = [
  "audio/webm",
  "audio/mp3",
  "audio/mp4"
];
const RECORDING_EVENTS = [
  "dataavailable",
  "error",
  "stop",
  "pause"
];

class SpeechRecorderService {
  slices = [];
  stream = null;
  mediaRecorder = null;

  $timeslice = 1_000; // ms
  $maxSlices = 30; // ~30 seconds

  $eventHandlers = {};

  constructor() {
    if (!SpeechRecorderService.instance) {
      SpeechRecorderService.instance = this;
    }
    return SpeechRecorderService.instance;
  }

  get recording() {
    return this.mediaRecorder?.state === "recording";
  }

  get paused() {
    return this.mediaRecorder?.state === "paused";
  }

  async start(handlers = {}) {
    if (typeof(navigator?.mediaDevices?.getUserMedia) !== "function"
      || typeof(MediaRecorder?.isTypeSupported) !== "function"
    ) {
      throw new Error("This browser does not support audio recording!")
    }

    const mimeType = this.$getSupportedMimeFormat();
    if (!mimeType) {
      throw new Error("This browser does not support accepted audio formats!");
    }

    this.slices = [];

    try {
      this.stream = await navigator.mediaDevices.getUserMedia(
        { audio: true, video: false }
      );
    } catch(error) {
        switch (error?.name) {
          case "AbortError":
              throw new Error("An unknown problem is preventing the microphone from being used.");
          case "NotAllowedError":
              throw new Error("Permission denied! Cannot use the microphone at this time.");
          case "NotReadableError":
              throw new Error("The browser or operating system is preventing the microphone from being used.");
          case "SecurityError":
              throw new Error("A security error has occured. User media support is disabled.");
          default:
            throw new Error(`An error occured: "${error?.name}"`);
      }
    }

    try {
      this.mediaRecorder = new MediaRecorder(this.stream, { mimeType });
      this.$addEventHandlers(handlers);
      this.mediaRecorder.start(this.$timeslice);
    } catch (error) {
      switch(error?.name) {
        case "InvalidStateError":
          throw new Error("Audio is already being recorded!");
        case "NotSupportedError":
          throw new Error("The media stream is inactive.");
        case "SecurityError":
          throw new Error("The media stream is configured to disallow recording.");
        default:
          throw new Error(`An error occured: "${error?.name}"`);
      }
    }
  }

  async stop() {
    return new Promise((resolve) => {
      this.$removeEventHandlers();

      if (this.mediaRecorder?.state === "inactive") {
        this.$stopStream();
        this.$resetState();
        resolve(null);
      }

      const mimeType = this.mediaRecorder.mimeType;
      this.mediaRecorder.stop();
      this.mediaRecorder.addEventListener("stop", () => {
        const blob = new Blob(this.slices, { mimeType });
        resolve({ slices: [...this.slices], blob, mimeType });
      });
  
      this.$stopStream();
      this.$resetState();
    });
  }

  abort() {
    this.$removeEventHandlers();
    if (this.mediaRecorder?.state !== "inactive") {
      this.mediaRecorder.stop();
    }
    this.$stopStream();
    this.$resetState();
  }

  $getSupportedMimeFormat() {
    for (const type of MIME_TYPES) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
  }

  $stopStream() {
    if (typeof(this.stream?.getTracks) !== "function") {
      return;
    }
    this.stream.getTracks().forEach((track) => track?.stop());
  }

  $resetState() {
    this.stream = null;
    this.$eventHandlers = {};
    this.mediaRecorder = null;
    this.$soundDetected = false;
  }

  $addEventHandlers(handlers = {}) {
    for (const event of RECORDING_EVENTS) {
      if (event === "dataavailable") {
        this.$eventHandlers[event] = (evt) => {
          if (this.slices.length >= this.$maxSlices) {
            return;
          }
          if (evt.data) {
            this.slices.push(evt.data);
          }
          if (typeof(handlers?.[event]) === "function") {
            handlers[event](
              evt, this.slices.length >= this.$maxSlices
            );
          }
        };
        this.mediaRecorder.addEventListener(event, this.$eventHandlers[event]);
        continue;
      }
      if (typeof(handlers?.[event]) === "function") {
        continue;
      }

      this.$eventHandlers[event] = handlers[event];
      this.mediaRecorder.addEventListener(event, this.$eventHandlers[event]);
    }
  }

  $removeEventHandlers() {
    if (!this.mediaRecorder) {
      return;
    }
    for (const event of Object.keys(this.$eventHandlers)) {
      this.mediaRecorder.removeEventListener(event, this.$eventHandlers[event]);
    }
  }
}

const speechRecorderService = new SpeechRecorderService();

export default speechRecorderService;
