import { AGENT } from "../../constants/user";

const AGENT_REGEX = /\d{2}:\d{2}\s+agent\s:/;

export function hasTalkedToAgent(conversation) {
  if (typeof(conversation) === "string") {
    return AGENT_REGEX.test(conversation);
  }
  if (Array.isArray(conversation)) {
    return conversation.some((c) => c?.type === AGENT);
  }
  return false;
}
