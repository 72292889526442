import styled from "styled-components";

const Iframe = styled.iframe`
  width: 100%;
  height: 80%;
`;

function PdfRenderer({ article }) {
  return (
    <Iframe title={article?.meta?.title} src={article?.meta?.source}></Iframe>
  );
}

export default PdfRenderer;
