import { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";
import SearchIcon from "@mui/icons-material/Search";

import Topics from "./Topics";
import Articles from "./Articles";
import {
  getContentActions,
  updateSearchQuery
} from "../../../../redux/actions/contentActions";
import { logEventRecord } from "../../../../redux/actions/chatActions";
import {
  RESOURCE_SECTION_SUB_EVENT,
  SEARCH_EVENT
} from "../../../../constants/events";
import useLocalization from "../../../../hooks/localization";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled(Wrapper)`
  height: calc(100% - 102px);
  padding: 0 0.5rem 0 0;
`;

const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 0.125rem;
  box-shadow: 0 4px 0.25rem rgba(0,0,0,0.05);
`;

const SearchBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.25rem;
  padding: 0.25em 0.5em;
  width: 94%;
  border: 1px solid ${({ theme }) => theme.veryLightGrey};
  border-radius: 1.5rem;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const InputField = styled.input`
  box-sizing: border-box;
  flex-grow: 1;
  max-width: calc(100% - 1.875rem);
  padding: 0.25rem 0.1rem;
  font-size: inherit;
  line-height: 1.5;
  font-size: ${({ theme }) => theme.md};
  font-color: ${({ theme }) => theme.fontColor};
  border: none;
  outline: none;
  box-shadow: none;
  font-family: ${({ theme }) => theme.fontFamily};
  &::placeholder {
    color: ${({ theme }) => theme.mediumGrey};
  }
`;

const SearchButton = styled.button`
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  box-shadow: none;
  text-transform: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: ${({ theme }) => theme.xl};
  color: ${({ theme }) => theme.darkGrey};
  &:hover {
    transform: scale(1.1);
    transition-duration: 0.25s;
  }
`;

const topicComparator = (t1, t2) => {
  if (t1?.name < t2?.name) return -1;
  if (t1?.name > t2?.name) return 1;
  return 0;
};

function Knowledge() {
  const [query, setQuery] = useState("");

  const openTopic = useSelector((state) => state?.content?.openTopic);
  const topics = useSelector((state) => state?.content?.topics);
  const articlesByTopic = useSelector((state) => state?.content?.articlesByTopic);

  const searchQuery = useSelector((state) => state?.content?.searchQuery);
  const searchResults = useSelector((state) => state?.content?.searchResults);
  const loadingSearchResults = useSelector((state) => state?.content?.loadingSearchResults);
  const isLastSearchPage = useSelector((state) => state?.content?.isLastSearchPage);

  const tooltips = useSelector((state) => state?.org?.customizations?.tooltips?.resources);

  const dispatch = useDispatch();
  const { localizeText } = useLocalization();
  const contentActions = getContentActions(dispatch);

  const topicsArray = useMemo(
    () => Object.keys(topics ?? {})
      .filter((id) => topics[id]?.parent === (openTopic ?? "0"))
      .map((id) => ({ id, ...topics?.[id]}))
      .sort(topicComparator),
    [topics, openTopic]
  );

  const handleSearch = () => {
    if (!query) return;
    dispatch(updateSearchQuery(query));
    dispatch(logEventRecord(
      null,
      null,
      uuid(),
      SEARCH_EVENT,
      RESOURCE_SECTION_SUB_EVENT,
      true,
      query
    ));
  };

  const renderContent = () => {
    if (searchQuery) {
      return (
        <Articles
          topic={{}}
          articles={searchResults || {}}
          hasMore={!isLastSearchPage ?? false}
          isLoading={loadingSearchResults ?? false}
          loadMore={contentActions.searchKnowledge} />
      );
    }
    if (openTopic && !topicsArray.some((t) => t.parent === openTopic)) {
      const content = articlesByTopic?.[openTopic];
      return (
        <Articles
          topic={{ id: openTopic, ...topics?.[openTopic] }}
          articles={content?.articles || {}}
          hasMore={!content?.isLast ?? false}
          isLoading={content?.isLoading ?? false}
          loadMore={() => contentActions.loadArticlesByTopic(openTopic)} />
      );
    }
    return (
      <Topics topics={topicsArray} openedTopic={topics?.[openTopic]} />
    );
  };

  return (
    <Wrapper>
      <Header>
        <SearchBox>
          <InputField
            type="text"
            placeholder={localizeText(tooltips?.searchPlaceholder)}
            onKeyDown={(evt) => evt.key === "Enter" && handleSearch()}
            value={query}
            maxLength={64}
            onChange={(evt) => setQuery(evt.target.value)} />
          <SearchButton onClick={handleSearch}>
            <SearchIcon sx={{ fontSize: "inherit", color: "inherit" }} />
          </SearchButton>
        </SearchBox>
      </Header>
      <ContentWrapper>
        {renderContent()}
      </ContentWrapper>
    </Wrapper>
  );
}

export default Knowledge;
