import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import parse from "html-react-parser";
import mammoth from "mammoth";

import CircularProgress from "@mui/material/CircularProgress";

import { getIframeWebpage } from "../../../../../../api";
import { base64ToBlob } from "../../../../../../lib/base64ToBlob";

const DocumentContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const DOCX_MIME_TYPE = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

function DocxRenderer({ article }) {
  const [docx, setDocx] = useState(null);
  const [error, setError] = useState(null);

  const subdomain = useSelector((state) => state?.org?.subdomain);
  const chatRecordId = useSelector((state) => state?.chat?.chatRecordId);

  const theme = useTheme();

  const fetchDocx = async (url) => {
    try {
      const maxRequests = 10;
      let requests = 0;
      let pointer = 0;
      let base64 = "";

      do {
        const response = await getIframeWebpage(
          url,
          DOCX_MIME_TYPE,
          "arraybuffer",
          pointer,
          {
            orgName: subdomain,
            conversationId: chatRecordId
          }
        );
        base64 += response.data;
        pointer = response.next;
        requests += 1;
      } while (pointer && requests < maxRequests)

      const buffer = await base64ToBlob(base64, DOCX_MIME_TYPE).arrayBuffer();

      const result = await mammoth.convertToHtml(
        { arrayBuffer: buffer },
        { includeDefaultStyleMap: true, ignoreEmptyParagraphs: false }
      );

      setDocx(result);
    } catch (error) {
      console.warn("FastTrack Assist: failed to load document!");
      setDocx(null);
      setError("Failed to load document!")
    }
  };

  useEffect(() => {
    setDocx(null);
    setError(null);
    fetchDocx(article?.meta?.source);
  }, [article?.meta?.source]);

  const render = () => {
    if (error) {
      return (
        <Container>
          <span>{error}</span>
        </Container>
      );
    }
    if (docx) {
      try {
        return parse(docx.value);
      } catch(error) {
        console.warn("FastTrack Assist: Failed to render document!");
        setError("Failed to render document!");
        setDocx(null);
      }
    }
    return (
      <Container>
        <CircularProgress sx={{ color: theme.primaryColor }} />
      </Container>
    );
  }

  return (
    <DocumentContainer>
      {render()}
    </DocumentContainer>
  )
}

export default DocxRenderer;
