import { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";
import { Tooltip } from "@mui/material";
import { useTheme } from "styled-components";
import { useTransition, animated } from "@react-spring/web";

import SendIcon from "@mui/icons-material/SendRounded";
import MicIcon from "@mui/icons-material/MicRounded";
import AttachmentIcon from "@mui/icons-material/AttachFileRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import TranslateIcon from "@mui/icons-material/TranslateRounded";
import CheckIcon from "@mui/icons-material/CheckRounded";

import { WebSocketContext } from "../../../../../App";
import { beginQnaFlow, clearChat } from "../../../../../redux/actions/chatActions";
import {
  startAudioRecording,
  triedAudioRecording
} from "../../../../../redux/actions/mediaRecorderActions";
import { updateLanguage } from "../../../../../redux/actions/orgActions";
import {
  ACCEPTED_ATTACHMENT_TYPES,
  CHAT_ON_SLACK,
  CHAT_ON_SUNSHINE,
  CHAT_WITH_AGENT,
  CHAT_WITH_BOT,
  PICK_THE_LANGUAGE,
  TICKET_CREATED
} from "../../../../../constants/chat";
import useLocalization from "../../../../../hooks/localization";

const MAX_REPLY_BAR_HEIGHT = 120; // px

const Wrapper = styled.div`
  width: 100%;
`;

const ReplyContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.25rem;
  padding: 0.25em 0.5em;
  width: 98%;
  border: 1px solid ${({ theme }) => theme.veryLightGrey};
  border-radius: 1.5rem;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const TextAreaField = styled.textarea`
  box-sizing: border-box;
  flex-grow: 1;
  max-height: ${MAX_REPLY_BAR_HEIGHT}px;
  padding: 0.25rem 0.1rem;
  resize: none;
  font-size: inherit;
  line-height: 1.4;
  background-color: transparent;
  font-size: ${({ theme }) => theme.md};
  font-color: ${({ theme }) => theme.fontColor};
  border: none;
  outline: none;
  box-shadow: none;
  overflow: auto;
  font-family: ${({ theme }) => theme.fontFamily};
  -ms-overflow-style: none;
  transition: width 200ms ease-in;
  &::placeholder {
    color: ${({ theme }) => theme.mediumGrey};
  }
  &::-webkit-scrollbar {
    background: transparent;
    width: 0;
  }
  @-moz-document url-prefix() {
    scrollbar-width: none;
  }
`;

const IconContainer = styled(animated.div)`
  align-self: flex-end;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.25rem;
`;

const IconButton = styled.button`
  align-self: flex-end;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 6px 5px;
  background-color: transparent;
  box-shadow: none;
  text-transform: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  align-self: flex-end;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 6px 5px;
  background-color: transparent;
  box-shadow: none;
  text-transform: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const FileToUpload = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.125em;
  font-size: ${({ theme }) => theme.reg};
  font-color: ${({ theme }) => theme.fontColor};
  overflow: hidden;
`;

const Filename = styled.div`
  overflow: hidden;
  line-height: 1.25;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LangMenu = styled.div`
  position: absolute;
  bottom: 30px;
  left: -18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 150px;
  background-color: #fff;
  box-shadow: 0 0 0.25rem rgba(0,0,0,0.25);
  border-radius: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
`;

const LangMenuItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: ${({ theme }) => theme.reg};
  line-height: 1.25;
  color: ${({ selected, theme }) => selected
    ? theme.primaryColor
    : theme.darkGrey};
  cursor: pointer;
  overflow: hidden
  white-space: nowrap;
  :hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

function Reply({ handleUserQuery, showNotification, handleUserAttachment }) {
  const [value, setValue] = useState("");
  const [file, setFile] = useState(null);
  const [fileKey, setFileKey] = useState("default-key");
  const [showLangMenu, setShowLangMenu] = useState(false);
  const orgDetails = useSelector((state) => state?.org?.details);
  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.replyBar
  );
  const lang = useSelector((state) => state?.org?.lang);
  const activeForm = useSelector((state) => state?.form?.activeForm);

  const chatStage = useSelector((state) => state?.chat?.chatStage);
  const chatWith = useSelector((state) => state?.chat?.chatWith);
  const chatMedium = useSelector((state) => state?.chat?.chatMedium);
  const lastSavedChatRecord = useSelector(
    (state) => state?.chat?.lastSavedChatRecord?.messages
  );

  const customizations = useSelector((state) => state?.org?.customizations);
  const canAccessDevices = useSelector((state) => state?.recorder?.canAccessDevices);

  const dispatch = useDispatch();

  const textareaRef = useRef(null);

  const theme = useTheme();
  const { localizeTooltips } = useLocalization();

  const localizationConfig = customizations?.localizationConfig;
  const vcConfig = customizations?.voiceChatConfig;
  const chatConfig = customizations?.chatConfig;
  const localizedTooltips = localizeTooltips(tooltips);
  const supportedLangs = localizationConfig?.supported
    ? { ...localizationConfig?.supported }
    : null;

  const showMic = vcConfig?.enabled && canAccessDevices;
  const showUpload = window.FileReader
    && (chatMedium === CHAT_ON_SLACK || chatMedium === CHAT_ON_SUNSHINE)
    && chatWith === CHAT_WITH_AGENT;
  const showTranslate = typeof(lang) === "string"
    && lang.length > 0
    && localizationConfig?.default
    && supportedLangs
    && chatWith === CHAT_WITH_BOT;

  if (showTranslate && !supportedLangs?.[lang]) {
    supportedLangs[lang] = lang[0].toUpperCase() + lang.slice(1);
  }

  const replyBarBlurred = !value?.length;
  const iconTransition = useTransition(replyBarBlurred, {
    initial: { translateX: "0px", opacity: 1 },
    from: { translateX: "20px", opacity: 0 },
    enter: { translateX: "0px", opacity: 1 },
    leave: { translateX: "20px", opacity: 0 }
  });

  const webSocketContext = useContext(WebSocketContext);

  const clearChatCache = () => {
    webSocketContext?.sendJsonMessage({
      action: "resetChat",
      orgid: orgDetails?.id
    });
  };

  const handleQuery = () => {
    const query = value.trim();
    if (query?.length > 0) {
      handleUserQuery(query.slice(0, 999));
    }
    if (file?.name) {
      handleUserAttachment(file);
    }
    setTimeout(() => { // delay this to remove newline
      setValue("");
      setFile(null);
      setFileKey(uuid());
    }, 1);
  };

  const handleReplyBarHeight = () => {
    if (!textareaRef.current) {
      return;
    }
    const textarea = textareaRef.current;
    textarea.style.height = "0";
    textarea.style.height = `${Math.min(
      textarea.scrollHeight, MAX_REPLY_BAR_HEIGHT
    )}px`;
  };

  const handleChange = (evt) => {
    if (chatStage === TICKET_CREATED && lastSavedChatRecord) {
      clearChatCache();
      dispatch(clearChat(false));
    }
    setValue(evt.target.value);
    setTimeout(() => {
      handleReplyBarHeight();
    }, 1);
  };

  const handleKeyDown = (evt) => {
    if (evt.shiftKey || evt.key !== "Enter") {
      return;
    }
    handleQuery();
    setTimeout(() => {
      handleReplyBarHeight();
    }, 1);
  };

  const startRecording = () => {
    dispatch(triedAudioRecording());
    dispatch(startAudioRecording(handleUserQuery));
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (!showUpload || files?.length !== 1) {
      setFileKey(uuid());
      setFile(null);
      return;
    }
    if (!ACCEPTED_ATTACHMENT_TYPES.some((t) => t === files[0].type)) {
      setFileKey(uuid());
      setFile(null);
      showNotification(
        `Attachment "${files[0]?.name}" is not of a supported type!`,
        "info"
      );
      return;
    }
    if (files[0].size > 3_000_000) {
      setFileKey(uuid());
      setFile(null);
      showNotification(
        `Attachment "${files[0]?.name}" exceeds the max size limit of 3 MB!`,
        "info"
      );
      return;
    }
    setFile(files[0]);
  };

  const removeFile = () => {
    setFile(null);
    setFileKey(uuid());
  };

  const setLang = (val) => {
    if (val === PICK_THE_LANGUAGE) {
      dispatch(beginQnaFlow(chatConfig?.switchLanguageQna));
      return;
    }
    hideLangMenu();
    dispatch(updateLanguage(val));
  };

  const hideLangMenu = () => {
    setTimeout(() => {
      setShowLangMenu(false);
    }, 750);
  };

  useEffect(() => {
    if (!lang || localizationConfig?.supported?.[lang]) {
      return;
    }
    showNotification(`The conversation language is now set to "${lang}"`, "info");
  }, [lang, localizationConfig?.supported]);

  const iconStyle = { fontSize: theme.xl, color: theme.darkGrey, cursor: "pointer" };

  return !activeForm && (
    <Wrapper>
      {file && (
        <FileToUpload>
          <Filename>
            {file?.name} · {(file?.size / 1_000_000).toFixed(2)} MB
          </Filename>
          <Tooltip title={localizedTooltips?.removeFile} placement="top-end">
            <ClearRoundedIcon
              onClick={removeFile}
              sx={{ fontSize: "inherit", color: "inherit", cursor: "pointer" }} />
          </Tooltip>
        </FileToUpload>
      )}
      <ReplyContainer>
        <TextAreaField
          ref={textareaRef}
          rows={1}
          placeholder={localizedTooltips?.replyPlaceholder}
          value={value}
          onKeyDown={handleKeyDown}
          maxLength={1000}
          onInput={handleChange}
        />
        {iconTransition((style, showIcons) => (
          showIcons && (
            <IconContainer style={style}>
              {showUpload && (
                <Tooltip title={localizedTooltips?.attachFile} placement="top-end">
                  <FileInputLabel htmlFor="file-upload">
                    <AttachmentIcon sx={{ ...iconStyle, transform: "rotate(30deg)" }} />
                  </FileInputLabel>
                  <FileInput
                    key={fileKey}
                    type="file"
                    id="file-upload"
                    name="file-upload"
                    onChange={handleFileUpload}
                    accept={ACCEPTED_ATTACHMENT_TYPES.join(",")} />
                </Tooltip>
              )}
              {showMic && (
                <Tooltip title={localizedTooltips?.record} placement="top-end">
                  <IconButton onClick={startRecording}>
                    <MicIcon sx={iconStyle} />
                  </IconButton>
                </Tooltip>
              )}
              {showTranslate && (
                <div style={{ position: "relative" }}>
                    <Tooltip title={localizedTooltips?.langMenu} placement="top-end">
                      <IconButton
                        onFocus={() => setShowLangMenu(true)}
                        onClick={(evt) => {evt?.target?.focus()}}
                        onBlur={hideLangMenu}
                      >
                        <TranslateIcon sx={iconStyle} />
                      </IconButton>
                    </Tooltip>
                    {showLangMenu && (
                      <LangMenu>
                        {Object.keys(supportedLangs)?.map((k) => (
                          <LangMenuItem
                            key={k}
                            selected={lang === k}
                            onClick={() => setLang(k)}
                          >
                            {localizedTooltips?.[supportedLangs[k]] || supportedLangs[k]}
                            {(lang === k) && (
                              <CheckIcon sx={{
                                fontSize: "inherit",
                                color: "inherit",
                                cursor: "pointer"
                              }} />
                            )}
                          </LangMenuItem>
                        ))}
                      </LangMenu>
                    )}
                  </div>
              )}
            </IconContainer>
          )
        ))}
        <Tooltip title={localizedTooltips?.submit} placement="top-end">
          <IconButton onClick={() => handleQuery()}>
            <SendIcon sx={iconStyle} />
          </IconButton>
        </Tooltip>
      </ReplyContainer>
    </Wrapper>
  );
}

export default Reply;
