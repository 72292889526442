import { useSelector } from "react-redux";
import styled from "styled-components";

import Banner from "./Banner";
import SectionSelector from "./SectionSelector";
import {
  LAPTOP,
  WIDESCREEN
} from "../../../../constants/widget";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  ${({ isExpanded, windowDimensions }) => {
    if (!isExpanded) {
      return "";
    }
    let template = "";
    if (windowDimensions?.width <= LAPTOP) {
      template = "180px auto 360px";
    } else if (windowDimensions?.width <= WIDESCREEN) {
      template = "180px auto 360px";
    } else {
      template = "400px auto 400px";
    }
    return `
      display: grid;
      grid-template-columns: ${template};
    `;
  }}
  box-shadow: 0 -4px 0.25rem -4px rgba(0,0,0,0.25);
`;

const EmptyDiv = styled.div`
  use-select: none;
`;

function Footer({ showSectionSelector, isExpanded }) {
  const windowDimensions = useSelector((state) => state?.content?.windowDimensions);

  return showSectionSelector
    ? (
      <>
        {!isExpanded && (
          <Banner />
        )}
        <Container isExpanded={isExpanded} windowDimensions={windowDimensions}>
          {isExpanded && (
            <>
              <EmptyDiv />
              <Banner />
            </>
          )}
          <SectionSelector isExpanded={isExpanded} windowDimensions={windowDimensions} />
        </Container>
      </>
    ) : (
      <Banner />
    );
}

export default Footer;
