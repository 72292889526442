import { useSelector } from "react-redux";
import styled from "styled-components";

import { READYLY_ICON } from "../../../../../constants/defaults";
import useLocalization from "../../../../../hooks/localization";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-size: ${({ theme }) => theme.sm};
  color: ${({ theme }) => theme.lightGrey};
  user-select: none;
`;

const Logo = styled.img`
  width: 1rem;
  height: 1rem;
`;

function Banner() {
  const assetUrls = useSelector((state) => state?.org?.assetUrls);
  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.footer
  );

  const { localizeText } = useLocalization();

  return (
    <Container>
      <span>{localizeText(tooltips?.banner)}</span>
      <Logo src={`${assetUrls?.images}/${READYLY_ICON}`} />
    </Container>
  );
}

export default Banner;
