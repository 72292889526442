import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { FormLabel } from "@mui/material";
import { pSBC } from "../../lib/pSBC";

export const FieldContainer = styled.div`
  width: 100%;
`;

export const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.md};
  line-height: 1.25;
`;

export const HiddenLabel = styled(StyledLabel)`
  display: none;
`;

export const StyledTextField = styled.input`
  box-sizing: border-box;
  background-color: #ffffff;
  width: 100%;
  color: ${({ theme }) => theme.fontColor};
  padding: ${({ theme }) => `${theme.xs} ${theme.reg}`};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.md};
  line-height: 1.25;
  border: 1px solid ${({ theme }) => theme.mediumGrey};
  border-radius: ${({ theme }) => theme.xl};
  outline: none;
  box-shadow: none;
  transition: border 100ms ease-in;
  &:hover {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
  &:active {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
  ${({ disabled, theme }) => disabled
    ? `
      border: 1px solid ${theme.mediumGrey} !important;
      color: ${theme.darkGrey};
      cursor: not-allowed;
    ` : ""}
`;

export const StyledToggleButton = styled.button`
  display: inline-flex;
  backface-visibility: hidden;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.xxs};
  color: #fff;
  background-color: ${({ theme, selected }) =>
    selected ? theme.primaryColor : "transparent"};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.lg};
  outline: none;
  box-shadow: none;
  text-transform: none;
  border: 1px solid ${({ theme, selected }) =>
    selected ? theme.primaryColor : theme.mediumGrey};
  border-radius: ${({ theme }) => theme.xl};
  cursor: pointer;
  &:hover {
    ${({ theme, selected, disabled }) => !selected && !disabled && `
      border-color: ${theme.primaryColor};
      color: ${theme.primaryColor};
    `}
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledButton = styled.button`
  align-self: center;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.xs} ${theme.md}`};
  color: white;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.md};
  line-height: 1.2;
  border: none;
  outline: none;
  box-shadow: none;
  text-transform: none;
  border-radius: ${({ theme }) => theme.xl};
  cursor: pointer;
  backface-visibility: hidden;
  transition: background-color 150ms ease-out;
`;

export const StyledSubmitButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.primaryColor};
  &:hover {
    background-color: ${({ theme }) => pSBC(0.05, theme.primaryColor)};
  }
`;

export const StyledCancelButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.mediumGrey};
  &:hover {
    background-color: ${({ theme }) => pSBC(0.05, theme.mediumGrey)};
  }
`;

export const StyledFormLabel = muiStyled(FormLabel)`
  font-size: ${({ customTheme }) => customTheme.md};
  font-family: ${({ customTheme }) => customTheme.fontFamily};
  color: ${({ customTheme }) => customTheme.fontColor};
  & .MuiTypography-root {
    font-size: ${({ customTheme }) => customTheme.md};
    font-family: ${({ customTheme }) => customTheme.fontFamily};
  }
`;

export const StyledInputBase = muiStyled(InputBase)(({ customTheme }) => ({
  '& .MuiInputBase-input': {
    width: "100%",
    backgroundColor: "#fff",
    fontSize: customTheme.md,
    fontFamily: customTheme.fontFamily,
    border: `1px solid ${customTheme.mediumGrey}`,
    borderRadius: customTheme.xl,
    padding: `${customTheme.xs} ${customTheme.reg}`
  },
  '& :hover': {
    border: `1px solid ${customTheme.primaryColor}`,
    borderRadius: `${customTheme.xl} !important`,
  },
  '& :focus': {
    border: `1px solid ${customTheme.primaryColor}`,
    borderRadius: `${customTheme.xl} !important`
  },
  '& :active': {
    border: `1px solid ${customTheme.primaryColor}`,
    borderRadius: `${customTheme.xl} !important`
  },
  '& :disabled': {
    border: `1px solid ${customTheme.mediumGrey}`,
    borderRadius: `${customTheme.xl} !important`,
    cursor: "not-allowed !important"
  }
}));

export const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  background-color: #ffffff;
  resize: none;
  width: 100%;
  min-height: 120px;
  color: ${({ theme }) => theme.fontColor};
  padding: ${({ theme }) => `${theme.xs} ${theme.reg}`};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.md};
  line-height: 1.25;
  border: 1px solid ${({ theme }) => theme.mediumGrey};
  border-radius: ${({ theme }) => theme.xl};
  outline: none;
  box-shadow: none;
  transition: border 100ms ease-in;
  &:hover {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
  &:active {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
  ${({ disabled, theme }) => disabled
    ? `
      border: 1px solid ${theme.mediumGrey} !important;
      cursor: not-allowed;
    ` : ""}
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
