import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import uuid from "react-uuid";
import Tooltip from "@mui/material/Tooltip";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import { WebSocketContext } from "../../../App";
import { resizeWidget } from "../../../redux/actions/contentActions";
import {
  getChatActions,
  logEventRecord
} from "../../../redux/actions/chatActions";
import {
  abortAudioRecording
} from "../../../redux/actions/mediaRecorderActions";
import useFeedback from "../../../hooks/feedbackHook";
import useLocalization from "../../../hooks/localization";
import messageParent from "../../../lib/messageParent";
import {
  WIDGET_SIZE_MINIMAL,
  WIDGET_SIZE_EXPANDED,
  MIN_WIDTH_TO_EXPAND,
  MINIMIZE_MESSAGE,
  EXPAND_MESSAGE,
  CLOSE_MESSAGE
} from "../../../constants/widget";
import {
  RESET_CHAT_EVENT,
  RESIZE_SUB_EVENT,
  WIDGET_CLOSE_EVENT,
  WIDGET_EXPAND_EVENT,
  WIDGET_NARROW_EVENT,
  WIDGET_SUB_EVENT
} from "../../../constants/events";
import { LINEAR_GRADIENT_REGEX } from "../../../constants/regex";
import { STANDALONE_WEB } from "../../../constants/platform";

const NavbarContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  column-gap: 0.25rem;
  width: 100%;
  height: ${({ theme }) => theme.navbarHeight};
  ${({ theme, background }) => {
    background = typeof(background) === "string"
      ? background
      : "";
    if (LINEAR_GRADIENT_REGEX.test(background)) {
      return `background: ${background};`;
    }
    return `background-color: ${theme.primaryColor};`;
  }}
  color: #fff;
  font-size: 1rem;
  padding: 0.25rem 1rem;
  border-radius: ${
    ({ theme }) => `${theme.widgetBorderRadius} ${theme.widgetBorderRadius} 0 0`
  };
  overflow: hidden;
`;

const Logo = styled.img`
  max-height: ${({ theme, windowDimensions }) => {
    if (windowDimensions?.width >= MIN_WIDTH_TO_EXPAND) {
      return theme.xxl;
    } else {
      return theme.xl;
    }
  }};
  justify-self: flex-start;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.lg};
  line-height: 1.25;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  height: ${({ theme }) => theme.xxxxl};
  width: ${({ theme }) => theme.xxxxl};
  color: #fff;
  background-color: transparent;
  box-shadow: none;
  text-transform: none;
  border: none;
  cursor: pointer;
`;

const RightEndButtons = styled.div`
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LeftEndButton = styled(StyledButton)`
  justify-self: flex-start;
`;

function Navbar({ toggleWidgetOpen }) {
  const platform = useSelector((state) => state?.org?.platform);
  const widgetSize = useSelector((state) => state?.content?.widgetSize);
  const windowDimensions = useSelector((state) => state?.content?.windowDimensions);
  const orgDetails = useSelector((state) => state?.org?.details);
  const parentOrigin = useSelector((state) => state?.org?.parentOrigin);
  const assetUrls = useSelector((state) => state?.org?.assetUrls);
  const customizations = useSelector((state) => state?.org?.customizations);
  const currentWorkflow = useSelector((state) => state?.chat?.currentWorkflow);

  const theme = useTheme();
  const dispatch = useDispatch();
  const chatActionFn = getChatActions(dispatch);
  const webSocketContext = useContext(WebSocketContext);

  const { setFeedback } = useFeedback(RESIZE_SUB_EVENT);
  const { localizeText, localizeTooltips } = useLocalization();

  const isExpanded = widgetSize === WIDGET_SIZE_EXPANDED;
  const tooltips = localizeTooltips(customizations?.tooltips?.navbar);

  const sendMessage = (type) => {
    messageParent(JSON.stringify({ type }), platform, parentOrigin);
  };

  const onMobilePlatform = platform?.startsWith("mobile");
  const onStandaloneWeb = platform === STANDALONE_WEB;
  const hasSpaceToExpand = windowDimensions?.width
    && windowDimensions.width >= MIN_WIDTH_TO_EXPAND;
  const canResizeWidget = !onMobilePlatform && hasSpaceToExpand;

  const handleResize = () => {
    const newSize = isExpanded
      ? WIDGET_SIZE_MINIMAL
      : WIDGET_SIZE_EXPANDED;
    sendMessage(isExpanded
      ? MINIMIZE_MESSAGE
      : EXPAND_MESSAGE);
    setFeedback(
      null,
      null,
      isExpanded ? WIDGET_NARROW_EVENT : WIDGET_EXPAND_EVENT,
      true,
      ""
    );
    dispatch(resizeWidget(newSize));
  };

  const collapseWidget = () => {
    if (!isExpanded || canResizeWidget) {
      return;
    }
    handleResize();
  }

  const logEvent = (eventType) => {
    dispatch(logEventRecord(
      null,
      null,
      uuid(),
      eventType,
      WIDGET_SUB_EVENT,
      true,
      ""
    ));
  };

  const closeWidget = () => {
    logEvent(WIDGET_CLOSE_EVENT);
    toggleWidgetOpen(false);
    setTimeout(() => {
      sendMessage(CLOSE_MESSAGE);
    }, 150);
  };

  const clearChatCache = () => {
    webSocketContext?.sendJsonMessage({
      action: "resetChat",
      orgid: orgDetails?.id
    });
  };

  const tryAbortingWorkflow = () => {
    if (!currentWorkflow?.executionArn) {
      return;
    }
    const jsonMessage = {
      action: "stopStateMachine",
      connected_to: currentWorkflow?.connectionId || "",
      execution_arn: currentWorkflow.executionArn,
      orgid: orgDetails?.id,
      message: {
        replies: {}
      }
    };

    webSocketContext?.sendJsonMessage(jsonMessage);
  };

  const resetChat = () => {
    clearChatCache();
    tryAbortingWorkflow();
    logEvent(RESET_CHAT_EVENT);
    dispatch(abortAudioRecording());
    chatActionFn.resetConversation(webSocketContext?.sendJsonMessage);
  };

  const autoExpand = () => {
    if (!onStandaloneWeb || isExpanded || !hasSpaceToExpand) {
      return;
    }
    handleResize();
  };

  useEffect(collapseWidget, [isExpanded, canResizeWidget]);
  useEffect(autoExpand, [platform, hasSpaceToExpand, isExpanded, onStandaloneWeb]);

  return (
    <NavbarContainer background={customizations?.navbarConfig?.custom?.background}>
      <Tooltip title={tooltips?.resetChat} placement="bottom-start">
        <LeftEndButton onClick={resetChat}>
          <RefreshIcon sx={{ fontSize: theme.xl }} />
        </LeftEndButton>
      </Tooltip>
      {customizations?.navbarConfig?.logo
        ? (
          <Logo
            windowDimensions={windowDimensions}
            src={`${assetUrls?.images}/${customizations?.navbarConfig?.logo}`} />
        ) : (
          <Title windowDimensions={windowDimensions}>
            {localizeText(customizations?.navbarConfig?.title || "")}
          </Title>
        )}
      <RightEndButtons>
        {(canResizeWidget && !onStandaloneWeb) && (
          <Tooltip
            title={isExpanded
              ? tooltips?.narrowWidget
              : tooltips?.expandWidget}
            placement="bottom-start"
          >
            <StyledButton onClick={handleResize}>
              {isExpanded
                ? (
                  <CloseFullscreenIcon sx={{ fontSize: theme.lg }} />
                ) : (
                  <OpenInFullIcon sx={{ fontSize: theme.lg }} />
                )}
            </StyledButton>
          </Tooltip>)}
        {(!onStandaloneWeb) && (
          <Tooltip title={tooltips?.hideWidget} placement="bottom-start">
            <StyledButton onClick={closeWidget}>
              <CloseIcon sx={{ fontSize: theme.xl }} />
            </StyledButton>
          </Tooltip>
        )}
      </RightEndButtons>
    </NavbarContainer>
  );
}

export default Navbar;
