import uuid from "react-uuid";

import {
  createChatRecord,
  createEventRecord,
  createTicket,
  startConnectChat
} from "../../api";
import { clearSimilarity } from "./contentActions";
import {
  cancelUtterance,
  loadAudio,
  speakUtterance
} from "./speechSynthesisActions";
import { processArticleMeta } from "../../lib/processArticleMeta";
import { censorPII } from "../../lib/censorPII";
import {
  GPT_MISHIT_REGEX,
  GPT_ASK_AGAIN_REGEX,
  VALID_URL_REGEX
} from "../../constants/regex";
import {
  ASK_AGAIN_PHRASE,
  PLEASE_WAIT_PHRASE,
  AI_FAILED_PHRASE,
  TICKET_CREATED_PHRASE,
  WORKFLOWS_FOUND,
  WORKFLOW_SUCCESS,
  WORKFLOW_START,
  WORKFLOWS_MATCHED,
  NEED_HELP_INTENT,
  URGENT_QUERY,
  AGENT_LEFT_PHRASE,
  AUTO_SUBMISSION,
  AGENT_CHAT_ENDED,
  CONNECTED_TO_AGENT_PHRASE
} from "../../constants/phrases";
import { AGENT, AI_BOT, END_USER, SYSTEM } from "../../constants/user";
import {
  MESSAGE_RECEIVED,
  TICKET_CREATED,
  NO_TICKET_CREATED,
  MESSAGE_IS_FORM,
  CHAT_ON_SLACK,
  CHAT_ON_AMAZON_CONNECT,
  AMZ_CONNECT_EVENT,
  AMZ_CONNECT_JOIN_EVENT,
  AMZ_CONNECT_LEAVE_EVENT,
  AMZ_CONNECT_CHAT_ENDED_EVENT,
  AMZ_CONNECT_MESSAGE,
  AMZ_CONNECT_CUSTOMER_ROLE,
  AMZ_CONNECT_TEXT_PLAIN,
  AMZ_CONNECT_TEXT_MARKDOWN,
  AMZ_CONNECT_AGENT_ROLE,
  WORKFLOW_START_NODE,
  WORKFLOW_END_NODE,
  WORKFLOW_ERROR_NODE,
  WORKFLOW_MESSAGE_NODE,
  TRIED_SWITCH_TO_AGENT,
  NO_INTERACTION,
  CHAT_WITH_AGENT,
  CHAT_WITH_BOT,
  LIVE_CHAT_MESSAGE,
  LIVE_CHAT_CONVERSATION_SUCCESS,
  LIVE_CHAT_CONVERSATION_FAILED,
  LIVE_CHAT_CONVERSATION_END,
  LIVE_CHAT_CONVERSATION_JOIN,
  LIVE_CHAT_CONVERSATION_LEFT,
  LIVE_CHAT_FILE_SHARE,
  LIVE_CHAT_EVENT_SUCCESS,
  LIVE_CHAT_EVENT_FAILED,
  INTERNAL_LIVE_CHAT_SYSTEMS,
  INTERACTED_WITH_AI
} from "../../constants/chat";
import {
  ESCALATION_EVENT,
  WORKFLOW_SUCCESS_EVENT,
  WORKFLOW_FAILED_EVENT,
  WORKFLOW_SUB_EVENT,
  WORKFLOW_START_EVENT,
  FORM_VIEW_EVENT,
  WORKFLOW_AUTO_EXECUTE,
  TICKET_CREATED_EVENT,
  TICKET_SUB_EVENT,
  TICKET_FAILED_EVENT,
  MISHIT_EVENT,
  INTERACT_SUB_EVENT
} from "../../constants/events";
import {
  BASE_API_URL,
  CREATE_CHAT_RECORD_V2,
  LIVE_CHAT_SOCKET_URL,
  SMARTFLOWS_SOCKET_URL,
  SOCKET_URL
} from "../../constants/endpoints";
import { SWITCH_TO_AGENT } from "../../constants/replies";
import { ON_END_USER_EMAIL_CHANGE } from "../../constants/workflow";
import { endUserDetails, mergeChatRecords } from "../../lib/chat/stringify";
import { processWorkflows } from "../../lib/processWorkflows";
import { clearActiveForm } from "./formActions";
import { parseUserDetails } from "../../lib/chat/parseUserDetails";
import { getConversationType } from "../../lib/chat/conversationType";
import { getConfigValueByPersona } from "../../lib/chat/getConfigValue";
import { hasTalkedToAgent } from "../../lib/chat/hasTalkedToAgent";
import { withinBusinessHours } from "../../lib/chat/businessHours";
import {
  buildDynamicPhrase,
  populateDynamicProperties
} from "../../lib/populateDynamicValues";
import { sendBeacon } from "../../lib/sendBeacon";
import messageParent from "../../lib/messageParent";
import { USER_QUERY_EVENT } from "../../constants/widget";
import { extractTextbyLang } from "../../lib/intl";
import { END_USER_PROPS, LANGUAGE_PROP } from "../../constants/qna";
import { updateLanguage } from "./orgActions";

export const chatActions = {
  UPDATE_CHAT_WITH: "UPDATE_CHAT_WITH",
  UPDATE_CHAT_MEDIUM: "UPDATE_CHAT_MEDIUM",

  UPDATE_QUERY: "UPDATE_QUERY",
  ADD_CONVERSATION: "ADD_CONVERSATION",
  MARK_MESSAGE_AS_READ: "MARK_MESSAGE_AS_READ",
  SET_ACTIVE_REPLIES: "SET_ACTIVE_REPLIES",
  SET_ADDITIONAL_DETAILS: "SET_ADDITIONAL_DETAILS",

  INTERACT_WITH_AI_BEGIN: "INTERACT_WITH_AI_BEGIN",
  INTERACT_WITH_AI_SUCCESS_TRACK: "INTERACT_WITH_AI_SUCCESS_TRACK",
  INTERACT_WITH_AI_ERROR: "INTERACT_WITH_AI_ERROR",

  EXECUTE_WORKFLOW_BEGIN: "EXECUTE_WORKFLOW_BEGIN",
  EXECUTE_WORKFLOW_ERROR: "EXECUTE_WORKFLOW_ERROR",
  EXECUTE_WORKFLOW_END: "EXECUTE_WORKFLOW_END",
  SET_WORKFLOW_DETAILS: "SET_WORKFLOW_DETAILS",

  ADD_PENDING_TRIGGER: "ADD_PENDING_TRIGGER",
  CLEAR_PENDING_TRIGGERS: "CLEAR_PENDING_TRIGGERS",

  QNA_BEGIN: "BEGIN_QNA",
  QNA_NEXT: "QNA_NEXT",
  QNA_CURRENT_QUESTION: "QNA_CURRENT_QUESTION",
  QNA_SUCCESS: "QNA_SUCCESS",
  QNA_FAILURE: "QNA_FAILURE",
  QNA_ERROR: "QNA_ERROR",
  QNA_CLEAR: "QNA_CLEAR",

  SWITCH_TO_AGENT_BEGIN: "SWITCH_TO_AGENT_BEGIN",
  SWITCH_TO_AGENT_SUCCESS: "SWITCH_TO_AGENT_SUCCESS",
  SWITCH_TO_AGENT_ERROR: "SWITCH_TO_AGENT_ERROR",
  SWITCH_TO_AGENT_CANCEL: "SWITCH_TO_AGENT_CANCEL",

  SWITCH_BACK_TO_AI: "SWITCH_BACK_TO_AI",

  ARCHIVE_AMAZON_CONNECT_CHAT: "ARCHIVE_AMAZON_CONNECT_CHAT",

  SET_CREATE_TICKET: "SET_CREATE_TICKET",

  SET_END_USER_DETAILS: "SET_END_USER_DETAILS",

  CHAT_STARTED_TRACK: "CHAT_STARTED_TRACK",
  CHAT_ENDED_TRACK: "CHAT_ENDED_TRACK",
  CLEAR_CHAT_TRACK: "CLEAR_CHAT_TRACK",

  CREATE_TICKET_BEGIN: "CREATE_TICKET_BEGIN",
  CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
  CREATE_TICKET_ERROR: "CREATE_TICKET_ERROR",

  CREATE_CHAT_RECORD_BEGIN: "CREATE_CHAT_RECORD_BEGIN",
  CREATE_CHAT_RECORD_SUCCESS: "CREATE_CHAT_RECORD_SUCCESS",
  CHAT_RECORD_MIDCONVO_SUCCESS: "CHAT_RECORD_MIDCONVO_SUCCESS",
  CREATE_CHAT_RECORD_ERROR: "CREATE_CHAT_RECORD_ERROR",

  RECORD_CHAT_EVENT: "RECORD_CHAT_EVENT",
  RESET_CHAT_EVENTS: "RESET_CHAT_EVENTS",

  UPDATE_ACTIVITY_TIMESTAMP: "UPDATE_ACTIVITY_TIMESTAMP",

  BOT_TYPING: "BOT_TYPING"
};

export const getChatActions = (dispatch) => ({
  interact: (content, sendJsonMessage) =>
    dispatch(interact(content, sendJsonMessage)),
  beginQnaFlow: (qna) => dispatch(beginQnaFlow(qna)),
  handleQnaResponse: (query) => dispatch(handleQnaResponse(query)),
  greetEndUser: (sendJsonMessage) => dispatch(greetEndUser(sendJsonMessage)),
  creatingTicket: () => dispatch(creatingTicket()),
  creatingChatRecord: (chatRecordId) => dispatch(creatingChatRecord(chatRecordId)),
  resetConversation: (sendJsonMessage) => dispatch(resetConversation(sendJsonMessage)),
  sessionTimeout: (sendJsonMessage) => dispatch(sessionTimeout(sendJsonMessage)),
  persistConversationDetails: () => dispatch(persistConversationDetails()),
  switchToAgent: () => dispatch(switchToAgent()),
  switchToAgentFailed: () => dispatch(switchToAgentFailed()),
  switchBackToBot: () => dispatch(switchBackToBot()),
  handleLiveChatConnection: (sendJsonMessage) =>
    dispatch(handleLiveChatConnection(sendJsonMessage)),
  handleLiveChatEnd: (sendJsonMessage) =>
    dispatch(handleLiveChatEnd(sendJsonMessage)),
  alertAgentDisconnected: () => dispatch(alertAgentDisconnected()),
  alertAgentConnected: () => dispatch(alertAgentConnected()),
  handleMessageFromAgent: (messageData, chatMedium, sendJsonMessage) =>
    dispatch(handleMessageFromAgent(messageData, chatMedium, sendJsonMessage)),
  handleSocketOpen: (event, chatWith, chatMedium, sendJsonMessage) =>
    dispatch(handleSocketOpen(event, chatWith, chatMedium, sendJsonMessage)),
  handleSocketReconnectStop: (event, chatMedium) =>
    dispatch(handleSocketReconnectStop(event, chatMedium)),
  handleLiveChatMessage: (messageData, sendJsonMessage) =>
    dispatch(handleLiveChatMessage(messageData, sendJsonMessage)),
  sendLiveChatMessage: (content, sendJsonMessage) =>
    dispatch(sendLiveChatMessage(content, sendJsonMessage)),
  logEventRecord:
    (query, id, eventId, eventType, subtype, value, title = "", recordId = null, meta = null) =>
      dispatch(logEventRecord(query, id, eventId, eventType, subtype, value, title, recordId, meta)),
  systemMessage: (response, sendJsonMessage) => dispatch(systemMessage(response, sendJsonMessage)),
  handleAmazonConnectMessage:
    (data, sendMessage) => dispatch(handleAmazonConnectMessage(data, sendMessage)),
  executePendingTriggers:
    (sendJsonMessage) => dispatch(executePendingTriggers(sendJsonMessage))
});

export const updateActivity = (type) => {
  return {
    type: chatActions.UPDATE_ACTIVITY_TIMESTAMP,
    payload: type
  };
};

export const setChatWith = (payload) => {
  return {
    type: chatActions.UPDATE_CHAT_WITH,
    payload
  };
};

export const setChatMedium = (payload) => {
  return {
    type: chatActions.UPDATE_CHAT_MEDIUM,
    payload
  };
};

export const setBotTyping = (payload) => {
  return {
    type: chatActions.BOT_TYPING,
    payload
  };
};

export const updateQuery = (newQuery) => {
  return (dispatch, getState) => {
    try {
      const reduxState = getState();
      const platform = reduxState?.org?.platform;
      const parentOrigin = reduxState?.org?.parentOrigin;
      const callback = (c) => ({
        id: c?.id,
        type: c?.type,
        subtype: c?.subtype,
        message: c?.message,
        timestamp: c?.timestamp
      });
      const conversation = reduxState?.chat?.conversation;

      messageParent(JSON.stringify({
        type: USER_QUERY_EVENT,
        payload: {
          query: newQuery,
          conversation: conversation?.map(callback) ?? []
        }
      }), platform, parentOrigin);
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to send user query event!")
    }
    
    dispatch({
      type: chatActions.UPDATE_QUERY,
      payload: newQuery
    });
  }
};

export const setActiveReplies = (replies) => {
  return {
    type: chatActions.SET_ACTIVE_REPLIES,
    payload: replies
  };
};

export const chatStarted = () => {
  return {
    type: chatActions.CHAT_STARTED_TRACK
  };
};

export const clearChat = (closeChat = true) => {
  return {
    type: chatActions.CLEAR_CHAT_TRACK,
    payload: {
      closeChat: closeChat
    }
  };
};

export const chatEnded = () => {
  return {
    type: chatActions.CHAT_ENDED_TRACK
  };
};

export const addConversation = (payload) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const customizations = reduxState?.org?.customizations;
    const textToSpeechConfig = customizations?.textToSpeechConfig;

    if (typeof(payload?.message) !== "string") {
      const targetLang = reduxState?.org?.lang;
      const defaultLang = customizations?.localizationConfig?.default;
      payload.message = extractTextbyLang(payload.message, targetLang, defaultLang);
    }
    if (textToSpeechConfig?.chat && payload?.autoTextToSpeech) {
      payload.id = uuid();
      dispatch(loadAudio(payload.id));
      dispatch(speakUtterance(payload.id, payload.message));
    }
    dispatch({
      type: chatActions.ADD_CONVERSATION,
      payload
    });
  }
};

export const markMessageAsRead = (messageId) => {
  return {
    type: chatActions.MARK_MESSAGE_AS_READ,
    payload: { messageId }
  };
};

export const setCreateTicket = (payload) => {
  return {
    type: chatActions.SET_CREATE_TICKET,
    payload
  };
};

export const setEndUserDetails = (payload, sendJsonMessage = null) => {
  return (dispatch, getState) => {
    const endUser = getState()?.chat?.endUser;

    if (payload?.email && payload?.email !== endUser?.email) {
      dispatch(triggerWorkflow(
        ON_END_USER_EMAIL_CHANGE,
        sendJsonMessage,
        { endUser: { ...endUser, ...payload } },
      ));
    }

    dispatch({
      type: chatActions.SET_END_USER_DETAILS,
      payload
    });
  };
};

export const clearQnaResults = () => {
  return {
    type: chatActions.QNA_CLEAR
  };
};

export const resetChatEvents = (subtype) => {
  return {
    type: chatActions.RESET_CHAT_EVENTS,
    payload: subtype
  };
};

export const workflowExecutionStart = (
  id,
  version,
  title,
  type,
  runInBackground = false
) => {
  return {
    type: chatActions.EXECUTE_WORKFLOW_BEGIN,
    payload: { id, version, title, type, runInBackground }
  };
};

export const setWorkflowDetails = (payload) => {
  return {
    type: chatActions.SET_WORKFLOW_DETAILS,
    payload
  };
};

export const workflowExecutionFailed = () => {
  return {
    type: chatActions.EXECUTE_WORKFLOW_ERROR
  };
};

export const workflowExecutionFinished = () => {
  return {
    type: chatActions.EXECUTE_WORKFLOW_END
  };
};

export const archiveAmazonConnectChat = () => {
  return {
    type: chatActions.ARCHIVE_AMAZON_CONNECT_CHAT
  };
};

export const setAdditionalDetails = (details, options = {}) => {
  return {
    type: chatActions.SET_ADDITIONAL_DETAILS,
    payload: { details, options }
  }
};

export const greetEndUser = (sendJsonMessage) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const endUser = reduxState?.chat?.endUser;
    const conversation = reduxState?.chat?.conversation;
    const orgDetails = reduxState?.org?.details;
    const customizations = reduxState?.org?.customizations;
    const targetLang = reduxState?.org?.lang;
    const defaultLang = customizations?.localizationConfig?.default;

    if (!customizations?.chatConfig?.opener || conversation?.length) {
      return;
    }

    const opener = getConfigValueByPersona(
      customizations.chatConfig,
      "opener",
      []
    );

    const dynamicPhrase = buildDynamicPhrase(
      extractTextbyLang(opener?.dynamicPhrase?.phrase, targetLang, defaultLang),
      endUser,
      Array.isArray(opener?.dynamicPhrase?.deps)
        ? opener.dynamicPhrase.deps
        : []
    );

    const replies = Array.isArray(opener?.replies)
      ? opener.replies
      : [];

    if (!endUser?.personas?.length && Array.isArray(opener?.personas)) {
      dispatch(setEndUserDetails({ personas: opener.personas }));
    }

    if (typeof(sendJsonMessage) === "function") {
      sendJsonMessage({
        action: "updateConversation",
        orgid: orgDetails?.id,
        botAnswer: extractTextbyLang(
          dynamicPhrase || opener.phrase,
          targetLang,
          defaultLang
        ),
        role: END_USER,
        personas: Array.isArray(endUser?.personas)
          ? endUser?.personas
          : []
      });
    }

    dispatch(addConversation({
      type: AI_BOT,
      subtype: MESSAGE_RECEIVED,
      message: dynamicPhrase || opener.phrase,
      repliesHelpText: opener?.repliesHelpText,
      replies: replies?.map((r) => ({ ...r, opener: true }))
    }));
  };
};

const escalateToAgent = (endUser, query, chatConfig) => {
  return (dispatch, getState) => {
    dispatch({ type: chatActions.INTERACT_WITH_AI_SUCCESS_TRACK });
    dispatch(beginSwitchToAgent());
    dispatch(creatingChatRecord(getState()?.chat?.chatRecordId));
    const emailQna = getConfigValueByPersona(
      chatConfig,
      "switchToAgentQna",
      endUser?.personas
    );
    dispatch(setBotTyping(false));
    dispatch(beginQnaFlow(emailQna));
    dispatch(logEventRecord(
      query,
      uuid(),
      uuid(),
      ESCALATION_EVENT,
      INTERACT_SUB_EVENT,
      true,
      ""
    ));
  };
};

export const executePendingTriggers = (sendJsonMessage) => {
  return (dispatch, getState) => {
    const triggers = [...getState()?.chat?.pendingTriggers];

    if (!Array.isArray(triggers) || !triggers?.length) {
      return;
    }

    const intervalRef = setInterval(() => {
      if (triggers.length === 0) {
        clearInterval(intervalRef);
        dispatch({
          type: chatActions.CLEAR_PENDING_TRIGGERS
        });
        return;
      }
      const workflow = triggers.shift();
      dispatch(
        triggerWorkflow(workflow?.trigger, sendJsonMessage, workflow?.data)
      );
    }, 1_500);
  }
}

export const triggerWorkflow = (trigger, sendJsonMessage, data = {}) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const chatOn = reduxState?.chat?.chatOn;
    const chatWith = reduxState?.chat?.chatWith;

    if (chatWith === CHAT_WITH_AGENT) {
      console.warn("Readyly ChatAssist: cannot trigger workflow during live agent!");
      return;
    }
    if (!chatOn) {
      dispatch({
        type: chatActions.ADD_PENDING_TRIGGER,
        payload: { trigger, data }
      });
      return;
    }

    if (typeof(sendJsonMessage) !== "function") {
      return;
    }

    const orgDetails = reduxState?.org?.details;
    const customizations = reduxState?.org?.customizations;
    const workflows = customizations?.workflowTriggers;
    const endUser = reduxState?.chat?.endUser;

    if (!workflows?.[trigger]?.["workflowId"]) {
      return;
    }

    const workflowData = workflows[trigger]

    sendJsonMessage({
      endUser, // can be overriden by updated data
      ...data,
      action: "stateMachine",
      orgid: orgDetails?.id,
      workflowid: workflowData?.workflowId,
    });

    dispatch(workflowExecutionStart(
      workflowData?.workflowId,
      workflowData?.version ?? "",
      workflowData?.title,
      workflowData?.workflowType,
      !!workflowData?.runInBackground
    ));

    dispatch(logEventRecord(
      workflowData?.query,
      null,
      uuid(),
      WORKFLOW_AUTO_EXECUTE,
      WORKFLOW_SUB_EVENT,
      true,
      `Workflow Name: ${workflowData?.title || "<Missing Title>"}`,
      null,
      {
        id: workflowData?.workflowId,
        type: workflowData?.workflowType,
        title: workflowData?.title,
        version: workflowData?.version
      }
    ));
  };
};

const autoExecuteWorkflow = (workflowData, orgDetails, endUser, sendJsonMessage) => {
  return (dispatch) => {
    sendJsonMessage({
      action: "stateMachine",
      orgid: orgDetails?.id,
      workflowid: workflowData?.workflowId,
      endUser
    });
    dispatch({ type: chatActions.INTERACT_WITH_AI_SUCCESS_TRACK });
    const workflowTitle = workflowData?.title || workflowData?.query;
    dispatch(workflowExecutionStart(
      workflowData?.workflowId,
      workflowData?.version,
      workflowTitle,
      workflowData?.workflowType
    ));
    dispatch(logEventRecord(
      workflowData?.query,
      null,
      uuid(),
      WORKFLOW_AUTO_EXECUTE,
      WORKFLOW_SUB_EVENT,
      true,
      `Workflow Name: ${workflowTitle || "<Missing Title>"}`,
      null,
      {
        id: workflowData?.workflowId,
        type: workflowData?.workflowType,
        title: workflowTitle,
        version: workflowData?.version
      }
    ));
  };
};

const handleUrgentQuery = (endUser, currentQuery, chatConfig) => {
  return (dispatch) => {
    dispatch({ type: chatActions.INTERACT_WITH_AI_SUCCESS_TRACK });
    dispatch(setBotTyping(false));
    const urgentQuery = getConfigValueByPersona(
      chatConfig,
      "urgentQuery",
      endUser?.personas
    );
    if (!urgentQuery?.response) {
      dispatch(escalateToAgent(
        endUser,
        currentQuery,
        chatConfig
      ));
      return;
    }
    dispatch(addConversation({
      type: AI_BOT,
      subtype: MESSAGE_RECEIVED,
      message: urgentQuery?.response,
      repliesHelpText: urgentQuery?.repliesHelpText,
      replies: urgentQuery?.replies
    }));
  };
};

export const interact = (content, sendJsonMessage) => {
  return async (dispatch, getState) => {
    dispatch({ type: chatActions.INTERACT_WITH_AI_BEGIN });
    try {
      const reduxState = getState();
      const currentQuery = reduxState?.chat?.query?.toLowerCase();
      const showPromptAfterAnswer = !reduxState?.org?.details?.enableSmartflows;
      const customizations = reduxState?.org?.customizations;
      const chatConfig = customizations?.chatConfig;
      const lastSavedChatRecord = reduxState?.chat?.lastSavedChatRecord;
      const noRecordSaved = !lastSavedChatRecord?.messages;

      const tempIntent = content?.intent?.payload?.response;
      const autoTextToSpeech = !!tempIntent?.autoTextToSpeech;
      const workflows = Array.isArray(content?.workflows)
        ? content.workflows
        : [];

      let intent = {
        ...tempIntent,
        question: tempIntent?.name,
      };

      delete intent["name"];

      if (!intent?.meta) {
        intent["meta"] = {
          formattedText: intent?.result,
        };
      }

      if (intent?.result === URGENT_QUERY) {
        dispatch(handleUrgentQuery(
          reduxState?.chat?.endUser,
          currentQuery,
          chatConfig,
        ));
        return;
      }

      const workflowConfig = customizations?.workflowConfig || {};
      const executingWorkflow = !!reduxState?.chat?.currentWorkflow?.id;
      const { decentWorkflows, highConfidenceWorkflows } = processWorkflows(
        workflows,
        executingWorkflow,
        workflowConfig
      );

      const hasWorkflows = decentWorkflows?.length > 0;
      const hasHighConfidenceWorkflows = highConfidenceWorkflows?.length > 0;
      const replyWithWorkflows = {
        autoTextToSpeech,
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: hasHighConfidenceWorkflows
          ? WORKFLOWS_MATCHED
          : WORKFLOWS_FOUND,
        replies: hasHighConfidenceWorkflows
          ? highConfidenceWorkflows
          : decentWorkflows
      };

      if (workflowConfig?.autoExecution
          && hasHighConfidenceWorkflows
          && typeof(sendJsonMessage) === "function") {
        const sortCallback = (w1, w2) =>
            w2?.data?.confidence - w1?.data?.confidence;
        const highConfCopy = highConfidenceWorkflows.sort(sortCallback);
        dispatch(autoExecuteWorkflow(
          highConfCopy[0]['data'],
          reduxState?.org?.details,
          reduxState?.chat?.endUser,
          sendJsonMessage
        ));
        if (noRecordSaved) {
          dispatch(creatingChatRecord(reduxState?.chat?.chatRecordId));
        }
        return;
      }

      // check for help phrases
      if (intent?.result === NEED_HELP_INTENT) {
        dispatch(escalateToAgent(
          reduxState?.chat?.endUser,
          currentQuery,
          chatConfig
        ));
        return;
      }

      const isMishit = typeof(intent?.result) !== "string"
        || intent.result.length === 0
        || intent.result === AI_FAILED_PHRASE
        || GPT_MISHIT_REGEX.test(intent.result);

      intent.result = GPT_ASK_AGAIN_REGEX.test(intent.result || '')
        ? ASK_AGAIN_PHRASE
        : intent.result;

      if (isMishit) {
        if (hasHighConfidenceWorkflows) {
          dispatch(addConversation(replyWithWorkflows));
        } else {
          const onMishit = getConfigValueByPersona(chatConfig, "onMishit", []);
          dispatch(
            addConversation({
              autoTextToSpeech,
              type: AI_BOT,
              subtype: MESSAGE_RECEIVED,
              message: onMishit?.phrase,
              repliesHelpText: onMishit?.repliesHelpText,
              replies: onMishit?.replies
            })
          );
          if (hasWorkflows) {
            dispatch(addConversation(replyWithWorkflows));
          }
        }
        dispatch(setBotTyping(false));
      } else if (intent?.result === PLEASE_WAIT_PHRASE) {
        dispatch(
          addConversation({
            autoTextToSpeech,
            type: AI_BOT,
            subtype: MESSAGE_RECEIVED,
            message: intent?.result
          })
        );
      } else {
        const sources = processArticleMeta(intent?.meta);
        const isSourceConfident = (s) =>
          s?.confidence >= customizations?.responseConfig?.sourceConfidenceThreshold;
        if (hasHighConfidenceWorkflows) {
          dispatch(addConversation(replyWithWorkflows));
        } else {
          dispatch(
            addConversation({
              autoTextToSpeech,
              type: AI_BOT,
              subtype: MESSAGE_RECEIVED,
              sources: sources?.filter(isSourceConfident),
              message: intent?.result,
              collectFeedback: true
            })
          );
          if (hasWorkflows) {
            dispatch(addConversation(replyWithWorkflows));
          }
          const promptAfterAnswer = getConfigValueByPersona(
            chatConfig,
            "promptAfterAnswer",
            reduxState?.chat?.endUser?.personas
          );
          if (showPromptAfterAnswer && promptAfterAnswer?.phrase) {
            const callback = (r) => r?.type !== SWITCH_TO_AGENT
              ? r
              : {
                  ...r,
                  data: {
                    ...r?.data,
                    previousQuery: reduxState?.chat?.query
                  }
              };
            dispatch(
              addConversation({
                type: AI_BOT,
                subtype: MESSAGE_RECEIVED,
                message: promptAfterAnswer?.phrase,
                repliesHelpText: promptAfterAnswer?.repliesHelpText,
                replies: promptAfterAnswer?.replies?.map(callback)
              })
            );
          }
        }
        dispatch(setBotTyping(false));
      }

      dispatch({ type: chatActions.INTERACT_WITH_AI_SUCCESS_TRACK });
      if (noRecordSaved) {
        dispatch(creatingChatRecord(reduxState?.chat?.chatRecordId));
      }

      if (isMishit) {
        dispatch(logEventRecord(
          currentQuery,
          uuid(),
          uuid(),
          MISHIT_EVENT,
          INTERACT_SUB_EVENT,
          1,
          ""
        ));
      }
    } catch (error) {
      dispatch({ type: chatActions.INTERACT_WITH_AI_ERROR });
    }
  };
};

const skipQuestion = (ques, state) => {
  if (!ques?.q) {
    return true;
  }

  const updates = ques?.updates;
  const endUser = state?.chat?.endUser;

  return typeof(updates) === "string"
    && updates.startsWith(END_USER_PROPS)
    && !!endUser?.[updates.replace(END_USER_PROPS, "")];
};

const askNextQuestion = (answer = "") => {
  return (dispatch, getState) => {
    const state = getState();
    const qna = state?.chat?.qna;
    const currentQuesIndex = state?.chat?.currentQna;
    let nextQuesIndex = currentQuesIndex + 1;

    while (nextQuesIndex < qna.length) {
      const ques = qna[nextQuesIndex];
      if (skipQuestion(ques, state)) {
        nextQuesIndex += 1;
        continue;
      }
      dispatch({
        type: chatActions.QNA_CURRENT_QUESTION,
        payload: nextQuesIndex
      });
      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: ques?.q || "",
        repliesHelpText: ques?.repliesHelpText,
        replies: Array.isArray(ques?.replies)
          ? ques?.replies
          : []
      }));
      return;
    }

    dispatch({
      type: chatActions.QNA_SUCCESS,
      payload: answer
    });
  };
};

export const beginQnaFlow = (payload) => {
  return (dispatch) => {
    if (!Array.isArray(payload)) {
      return;
    }
    dispatch({
      type: chatActions.QNA_BEGIN,
      payload: payload
    });
    dispatch(askNextQuestion());
  };
};

const overwriteSessionParameters = (field, value, dispatch) => {
  if (typeof(field) !== "string" || typeof(value) !== "string") {
    return;
  }
  if (field === LANGUAGE_PROP) {
    dispatch(updateLanguage(value.toLowerCase()));
    return;
  }
  if (field.startsWith(END_USER_PROPS)) {
    const actualField = field.replace(END_USER_PROPS, "");
    dispatch(
      setEndUserDetails(parseUserDetails({[actualField]: value}, false), () => {})
    );
    return;
  }
  console.warn(`Readyly ChatAssist: parameter "${field}" does not exist!`);
};

export const handleQnaResponse = (query) => {
  return (dispatch, getState) => {
    try {
      const reduxState = getState();
      const chatConfig = reduxState?.org?.customizations?.chatConfig;
      const currentQna = Number.isNaN(reduxState?.chat?.currentQna)
        ? 0
        : reduxState?.chat?.currentQna;
      const qna = reduxState?.chat?.qna[currentQna];
      const qnaRetries = Number.isNaN(parseInt(qna.retries))
        ? 0
        : parseInt(qna.retries);

      if (qnaRetries <= 0) {
        dispatch({
          type: chatActions.QNA_ERROR
        });
        dispatch(addConversation({
          type: AI_BOT,
          subtype: MESSAGE_RECEIVED,
          message: chatConfig?.qnaFailedPhrase
        }));
        return;
      }
      if (typeof(qna?.validation?.regex) === "string"
        && !(new RegExp(qna.validation.regex)).test(query)
      ) {
        dispatch(addConversation({
          type: AI_BOT,
          subtype: MESSAGE_RECEIVED,
          message: qna.help || chatConfig?.qnaDefaultHelpPhrase,
          repliesHelpText: qna?.repliesHelpText,
          replies: Array.isArray(qna.replies)
            ? qna.replies
            : []
        }));
        dispatch({ type: chatActions.QNA_FAILURE });
        return;
      }
      const fieldToOverwrite = qna?.overwrites || qna?.updates;
      if (fieldToOverwrite) {
        overwriteSessionParameters(fieldToOverwrite, query, dispatch);
      }
      dispatch({
        type: chatActions.QNA_NEXT,
        payload: query
      });
      dispatch(askNextQuestion(query));
    } catch (error) {
      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: "Unexpected error, please try again later."
      }));
      dispatch({
        type: chatActions.QNA_ERROR
      });
    }
  };
};

export const creatingChatRecord = (chatRecordId) => {
  return async (dispatch, getState) => {
    try {
      const reduxState = getState();

      const lastSavedChatRecord = reduxState?.chat?.lastSavedChatRecord;
      const conversation = reduxState?.chat?.conversation;

      if (conversation.length <= lastSavedChatRecord?.messages) {
        return;
      }

      const orgDetails = reduxState?.org?.details;
      const profile = reduxState?.org?.profile;
      const platform = reduxState?.org?.platform;
      const origin  = reduxState?.org?.parentOrigin;
      const endUser = reduxState?.chat?.endUser;
      const chatStage = reduxState?.chat?.chatStage;
      const aiChat = reduxState?.chat?.aiChatRecord;
      const leftDetails = reduxState?.chat?.leftDetailsRecord;
      const agentChat = reduxState?.chat?.agentChatRecord;

      const record = mergeChatRecords(aiChat, agentChat, leftDetails);

      dispatch({
        type: chatActions.CREATE_CHAT_RECORD_BEGIN,
        payload: {
          messages: conversation.length,
          recordLength: record.length
        }
      });

      const chatEventsRecord = reduxState?.chat?.chatEventsRecord;
      const eventsRecord = Array.isArray(chatEventsRecord)
        ? chatEventsRecord?.map((id) => ({ id }))
        : [];

      const response = await createChatRecord(
        orgDetails?.id,
        chatRecordId || reduxState?.chat?.chatRecordId,
        censorPII(record),
        endUser?.email || "anonymous",
        {},
        eventsRecord,
        getConversationType(chatStage),
        "",
        endUser,
        profile,
        platform,
        origin,
        {
          orgName: reduxState?.org?.subdomain,
          conversationId: chatRecordId || reduxState?.chat?.chatRecordId,
          parentOrigin: origin,
          platform,
          profile
        }
      );

      dispatch({
        type: chatActions.CREATE_CHAT_RECORD_SUCCESS
      });
    } catch(error) {
      dispatch({
        type: chatActions.CREATE_CHAT_RECORD_ERROR
      });
    }
  }
};

export const creatingTicket = () => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const chatRecordId = reduxState?.chat?.chatRecordId;

    try {
      dispatch({
        type: chatActions.CREATE_TICKET_BEGIN
      });

      const orgDetails = reduxState?.org?.details;
      const profile = reduxState?.org?.profile;
      const customizations = reduxState?.org?.customizations;
      const endUser = reduxState?.chat?.endUser;

      const aiChat = reduxState?.chat?.aiChatRecord;
      const leftDetails = reduxState?.chat?.leftDetailsRecord;
      const agentChat = reduxState?.chat?.agentChatRecord;

      const details = reduxState?.chat?.additionalDetails;

      const comment = mergeChatRecords(aiChat, agentChat, leftDetails);

      const response = await createTicket(
        details?.length > 0
          ? comment + `\n\n${details}`
          : comment,
        orgDetails?.ticketingSystem,
        endUser?.email,
        orgDetails?.id,
        orgDetails?.ticketing?.senderEmail,
        orgDetails?.ticketing?.supportEmail,
        profile,
        endUser,
        {
          orgName: reduxState?.org?.subdomain,
          conversationId: chatRecordId,
          parentOrigin: reduxState?.org?.parentOrigin,
          platform: reduxState?.org?.parentOrigin,
          profile
        }
      );

      const ticketCreated  = getConfigValueByPersona(
        customizations?.chatConfig,
        "ticketCreated",
        endUser?.personas
      );
      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: ticketCreated?.phrase
          || TICKET_CREATED_PHRASE
      }));

      const storedStr = localStorage.getItem(orgDetails?.id);

      dispatch({
        type: chatActions.CREATE_TICKET_SUCCESS
      });

      dispatch(logEventRecord(
        null,
        null,
        uuid(),
        TICKET_CREATED_EVENT,
        TICKET_SUB_EVENT,
        true,
        "",
        chatRecordId
      ));

      if (storedStr) {
        const storedObj = JSON.parse(storedStr);

        localStorage.setItem(orgDetails?.id, JSON.stringify({
          ...storedObj,
          chatStage: TICKET_CREATED
        }));
      }
    } catch (error) {
      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: "Failed to create a ticket, please try again later."
      }));
      dispatch({
        type: chatActions.CREATE_TICKET_ERROR
      });
      dispatch(logEventRecord(
        null,
        null,
        uuid(),
        TICKET_FAILED_EVENT,
        TICKET_SUB_EVENT,
        true,
        "",
        chatRecordId
      ));
    } finally {
      dispatch(setBotTyping(false));
      dispatch(setCreateTicket(false));
    }
  };
};

export const resetConversation = (sendJsonMessage) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const chatStage = reduxState?.chat?.chatStage;

    dispatch(cancelUtterance());

    if (chatStage < INTERACTED_WITH_AI) {
      return;
    }

    const amzContactId = reduxState?.chat?.amzContactId;
    const liveChatSocketUrl = reduxState?.chat?.liveChatSocketUrl;

    if (amzContactId) {
      dispatch(archiveAmazonConnectChat());
    }
    if (liveChatSocketUrl) {
      dispatch(handleLiveChatEnd(sendJsonMessage));
    }

    dispatch(creatingChatRecord(reduxState?.chat?.chatRecordId));
    // We are deliberately re-opening the web socket connection
    // so that we don't receive any pending messages after the reset
    dispatch(clearChat());
    dispatch(clearActiveForm());
    dispatch(clearSimilarity());
    dispatch(setBotTyping(false));
  };
};

export const sessionTimeout = (sendJsonMessage) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const orgDetails = reduxState?.org?.details;
    const customizations = reduxState?.org?.customizations;
    const noAutoTicketAfterHandoff = customizations?.noAutoTicketAfterHandoff;

    const endUser = reduxState?.chat?.endUser;
    const conversation = reduxState?.chat?.agentChatRecord;
    const chatStage = reduxState?.chat?.chatStage;
    
    const amzContactId = reduxState?.chat?.amzContactId;
    const liveChatSocketUrl = reduxState?.chat?.liveChatSocketUrl;

    const shouldCreateTicket =
      !(noAutoTicketAfterHandoff && hasTalkedToAgent(conversation));

    if (liveChatSocketUrl) {
      dispatch(handleLiveChatEnd(sendJsonMessage));
    }
    if (endUser?.email
      && chatStage >= TRIED_SWITCH_TO_AGENT
      && chatStage < TICKET_CREATED
    ) {
      dispatch(chatEnded());
      dispatch(clearQnaResults());
      if (shouldCreateTicket) {
        dispatch(setBotTyping(true));
        dispatch(creatingTicket(AUTO_SUBMISSION));
      }
    }
    if (amzContactId) {
      dispatch(chatEnded());
      dispatch(archiveAmazonConnectChat());
    }
    if (chatStage >= INTERACTED_WITH_AI) {
      dispatch(creatingChatRecord(reduxState?.chat?.chatRecordId));
    }
    localStorage.removeItem(orgDetails?.id);
  };
};

export const persistConversationDetails = () => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const subdomain = reduxState?.org?.subdomain;
    const profile = reduxState?.org?.profile;
    const platform = reduxState?.org?.platform;
    const origin  = reduxState?.org?.parentOrigin;
    const orgDetails = reduxState?.org?.details;

    const endUser = reduxState?.chat?.endUser;

    const chatStage = reduxState?.chat?.chatStage;
    const conversation = reduxState?.chat?.conversation;
    const lastSavedChatRecord = reduxState?.chat?.lastSavedChatRecord;
    const chatRecordId = reduxState?.chat?.chatRecordId;
    const aiChatRecord = reduxState?.chat?.aiChatRecord;
    const agentChatRecord = reduxState?.chat?.agentChatRecord;
    const leftDetailsRecord = reduxState?.chat?.leftDetailsRecord;
    const chatEventsRecord = reduxState?.chat?.chatEventsRecord;

    const amzContactId = reduxState?.chat?.amzContactId;
    const amzParticipantId = reduxState?.chat?.amzParticipantId;
    const amzParticipantToken = reduxState?.chat?.amzParticipantToken;

    const sessionData = {
      ticketingSystem: orgDetails?.ticketingSystem,
      ticketing: orgDetails?.ticketing,
      chatStage,
      lastSavedChatRecord,
      email: endUser?.email,
      platform,
      origin,
      profile,
      endUser,
      chatRecordId,
      amzContactId,
      amzParticipantId,
      amzParticipantToken,
      amzRegion: orgDetails?.amazon_connect?.region
    };

    if (chatStage === NO_INTERACTION) {
      return;
    }
    if (conversation.length <= lastSavedChatRecord.messages) {
      localStorage.setItem(orgDetails?.id, JSON.stringify(sessionData));
      return;
    }

    const record = mergeChatRecords(
      aiChatRecord, agentChatRecord, leftDetailsRecord
    );
    let beaconSent = false;

    try {
      const session = window.sessionStorage.getItem(`${subdomain}_${chatRecordId}`);
      const { accessToken, exp } = JSON.parse(session) ?? {};
      const isTokenValid = accessToken?.length > 0 && Date.now() < exp;

      beaconSent = isTokenValid && sendBeacon(`${BASE_API_URL}${CREATE_CHAT_RECORD_V2}`, {
        method: "POST",
        body: JSON.stringify({
          orgId: orgDetails?.id,
          recordId: chatRecordId,
          record,
          userEmail: endUser?.email || "anonymous",
          feedback: {},
          interactions: [],
          tags: [],
          conversation_type: getConversationType(chatStage),
          connectionType: "",
          endUser,
          profile,
          platform,
          origin
        }),
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "X-Parent-Origin": origin ?? ""
        }
      });
    } catch(error) {
      console.warn("Readyly ChatAssist: Failed to send conversation record beacon!");
    }

    if (beaconSent) {
      dispatch({
        type: chatActions.CREATE_CHAT_RECORD_BEGIN,
        payload: {
          messages: reduxState?.chat?.conversation?.length,
          recordLength: record.length
        }
      });
    } else {
      sessionData["aiChatRecord"] = aiChatRecord;
      sessionData["agentChatRecord"] = agentChatRecord;
      sessionData["leftDetailsRecord"] = leftDetailsRecord;
      sessionData["chatEventsRecord"] = chatEventsRecord;
    }

    localStorage.setItem(orgDetails?.id, JSON.stringify(sessionData));
  };
};

export const beginSwitchToAgent = () => {
  return {
    type: chatActions.SWITCH_TO_AGENT_BEGIN
  };
};

export const cancelSwitchToAgent = () => {
  return {
    type: chatActions.SWITCH_TO_AGENT_CANCEL
  };
};

export const switchToAgentFailed = (responseConfig = null) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const chatConfig = reduxState?.org?.customizations?.chatConfig;
    const endUser = reduxState?.chat?.endUser;
    const bypassTicketFlow =
      reduxState?.org?.customizations?.noTicketAfterHandoffFailure;

    if (!responseConfig?.phrase) {
      responseConfig = getConfigValueByPersona(
        chatConfig,
        "switchToAgentFailed",
        reduxState?.chat?.endUser?.personas
      );
    }

    if (responseConfig?.phrase) {
      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: responseConfig?.phrase
      }));
    }
    dispatch({
      type: chatActions.SWITCH_TO_AGENT_ERROR
    });

    if (!bypassTicketFlow) {
      const ticketQna = getConfigValueByPersona(
        chatConfig,
        "createTicketQna",
        endUser?.personas
      );
      dispatch(setCreateTicket(true));
      dispatch(beginQnaFlow(ticketQna));
    }
  };
};

const chatOnAmazonConnect = async  (amazonConnect, endUser, dispatch) => {
  const requestBody = populateDynamicProperties(
    amazonConnect?.requestBody,
    endUser
  );
  const response = await startConnectChat(
    amazonConnect.endpoint,
    requestBody
  );
  const result = populateDynamicProperties(
    amazonConnect?.responseBody,
    response
  );
  if (!result?.ContactId
      || !result?.ParticipantId
      || !result?.ParticipantToken) {
    throw new Error(
      "Readyly ChatAssist: missing/malformed response from Amazon Connect endpoint!"
    );
  }
  dispatch({
    type: chatActions.SWITCH_TO_AGENT_SUCCESS,
    payload: {
      chatMedium: CHAT_ON_AMAZON_CONNECT,
      amzContactId: result?.ContactId,
      amzParticipantId: result?.ParticipantId,
      amzParticipantToken: result?.ParticipantToken
    }
  });
};

const handleLiveChatConnection = (sendJsonMessage) => {
  return (dispatch, getState) => {
    try {
      const reduxState = getState();
      const system = reduxState?.chat?.chatMedium;
      const orgId = reduxState?.org?.details?.id;
      const endUser = reduxState?.chat?.endUser;
      const conversationId = reduxState?.chat?.chatRecordId;

      sendJsonMessage({
        action: "createChat",
        data: { system, orgId, endUser, conversationId }
      });
      dispatch(setBotTyping(true));
    } catch(error) {
      dispatch(setChatWith(CHAT_WITH_BOT));
      dispatch(switchToAgentFailed());
    }
  }
}

const handleLiveChatEnd = (sendJsonMessage) => {
  return (dispatch, getState) => {
    try {
      const reduxState = getState();
      const system = reduxState?.chat?.chatMedium;
      const orgId = reduxState?.org?.details?.id;
      const endUser = reduxState?.chat?.endUser;
      const conversationId = reduxState?.chat?.chatRecordId;

      sendJsonMessage({
        action: "endChat",
        data: { system, orgId, endUser, conversationId }
      });
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to archive live chat");
    }
  }
}

export const switchToAgent = () => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const profile = reduxState?.org?.profile;
    const orgDetails = reduxState?.org?.details;
    const amazonConnect = orgDetails?.amazon_connect;
    const liveChatConfig = orgDetails?.liveChatConfig;
    const endUser = reduxState?.chat?.endUser;
    const liveChatSystem = getConfigValueByPersona(
      liveChatConfig,
      "system",
      Array.isArray(endUser?.personas)
        ? [profile, ...endUser.personas]
        : [profile]
    );

    const businessHours = orgDetails?.businessHours;

    try {
      const withinHours = withinBusinessHours(businessHours); 

      if (!withinHours) {
        const chatConfig = reduxState?.org?.customizations?.chatConfig;
        const escalationOutsideBusinessHours = getConfigValueByPersona(
          chatConfig,
          "escalationOutsideBusinessHours",
          endUser?.personas
        );
        setTimeout(() => {
          dispatch(switchToAgentFailed(escalationOutsideBusinessHours));
        }, 1_250);
        return;
      }

      if (amazonConnect?.endpoint) {
        await chatOnAmazonConnect(amazonConnect, endUser, dispatch);
      } else if (
        INTERNAL_LIVE_CHAT_SYSTEMS.some((sys) => sys === liveChatSystem)
      ) {
        dispatch({
          type: chatActions.SWITCH_TO_AGENT_SUCCESS,
          payload: {
            liveChatSocketUrl: LIVE_CHAT_SOCKET_URL,
            chatMedium: liveChatSystem
          }
        });
      } else {
        throw new Error("Tried to start chat on an unrecognized system!");
      }
    } catch (error) {
      console.warn(`Readyly ChatAssist: failed to start live chat - ${error.message}`)
      dispatch(switchToAgentFailed());
    } finally {
      dispatch(setBotTyping(false));
    }
  };
};

export const alertAgentDisconnected = () => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const chatStage = reduxState?.chat?.chatStage;

    // This check handles cases where user clicks the reset btn
    if (chatStage < TRIED_SWITCH_TO_AGENT) {
      return;
    }

    dispatch(addConversation({
      type: AI_BOT,
      subtype: MESSAGE_RECEIVED,
      message: AGENT_CHAT_ENDED
    }));
  };
};

export const switchBackToBot = () => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const chatStage = reduxState?.chat?.chatStage;
    const endUser = reduxState?.chat?.endUser;
    const chatConfig = reduxState?.org?.customizations?.chatConfig;

    // This check handles cases where user clicks the reset btn
    if (chatStage < TRIED_SWITCH_TO_AGENT) {
      return;
    }

    const amzContactId = reduxState?.chat?.amzContactId;

    dispatch(chatEnded());

    if (chatStage < NO_TICKET_CREATED) {
      const ticketQna = getConfigValueByPersona(
        chatConfig,
        "createTicketQna",
        endUser?.personas
      );
      dispatch(setCreateTicket(true));
      dispatch(beginQnaFlow(ticketQna));
    }

    if (amzContactId) {
      dispatch(archiveAmazonConnectChat());
    }
  };
};

export const logEventRecord = (
  query,
  id,
  eventId,
  eventType,
  subtype,
  value,
  title = "",
  recordId = null,
  meta = null
) => {
  return async (dispatch, getState) => {
    try {
      const reduxState = getState();

      const orgDetails = reduxState?.org?.details;
      const profile = reduxState?.org?.profile;
      const platform = reduxState?.org?.platform;
      const parentOrigin = reduxState?.org?.parentOrigin;
      const chatRecordId = recordId || reduxState?.chat?.chatRecordId;
      const endUser = reduxState?.chat?.endUser;
      const lastQuery = reduxState?.chat?.query ?? "";

      if (value) {
        const reponse = await createEventRecord(
          orgDetails?.id,
          eventType,
          subtype,
          eventId,
          chatRecordId,
          endUser?.email || "anonymous",
          title,
          censorPII(query || lastQuery),
          meta,
          endUser,
          profile,
          platform,
          parentOrigin,
          {
            orgName: reduxState?.org?.subdomain,
            conversationId: chatRecordId,
            parentOrigin,
            platform,
            profile
          }
        );
      }

      dispatch({
        type: chatActions.RECORD_CHAT_EVENT,
        payload: {
          eventType,
          subtype,
          eventId,
          value,
          id
        }
      });
    } catch (error) {
      console.warn("Readyly ChatAssist: failed to log event record!");
    }
  };
}

export const systemMessage = (response, sendJsonMessage) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const orgDetails = reduxState?.org?.details;
    const endUser = reduxState?.chat?.endUser;
    const currentWorkflow = reduxState?.chat?.currentWorkflow;

    const logEvent = (type, formName = "") => {
      const workflowTitle = currentWorkflow?.title || "<Missing Title>";
      const title = formName?.length > 0
        ? `Workflow Name: ${workflowTitle}\nForm Name: ${formName}`
        : `Workflow Name: ${workflowTitle}`;

      dispatch(logEventRecord(
        "",
        null,
        uuid(),
        type,
        WORKFLOW_SUB_EVENT,
        true,
        title,
        null,
        {
          id: currentWorkflow?.id,
          type: currentWorkflow?.type,
          title: currentWorkflow?.title,
          version: currentWorkflow?.version
        }
      ));
    };

    if (!currentWorkflow?.runInBackground
      && typeof(response?.chatMessage) === "string"
    ) {
      if (response?.chatMessage !== WORKFLOW_START
          && response?.chatMessage !== WORKFLOW_SUCCESS
          && response?.chatMessage?.length > 0) {
        dispatch(addConversation({
          type: SYSTEM,
          subtype: MESSAGE_RECEIVED,
          message: response?.chatMessage,
          repliesHelpText: response?.messageData?.repliesHelpText,
          replies: Array.isArray(response?.messageData?.replies)
            ? response.messageData.replies
            : []
        }));
        if (typeof(sendJsonMessage) === "function") {
          sendJsonMessage({
            action: "updateConversation",
            orgid: orgDetails?.id,
            botAnswer: response?.chatMessage,
            role: END_USER,
            personas: Array.isArray(endUser?.personas)
              ? endUser?.personas
              : []
          });
        }
      }
      dispatch(setBotTyping(false));
    }
    if (response?.messageData?._userDetails) {
      const details = response?.messageData?._userDetails;
      dispatch(setEndUserDetails(parseUserDetails(details, !!details?.personas)));
    }
    if (typeof(response?.messageData?.replies_expected) === "string") {
      const expectedReplies = JSON.parse(response?.messageData?.replies_expected);

      if (expectedReplies?.length) {
        const formTitle = response?.messageData?.formName || "Secure Form";
        dispatch(addConversation({
          type: SYSTEM,
          subtype: MESSAGE_IS_FORM,
          connectionId: response?.messageData?.my_connection_id,
          executionArn: response?.messageData?.execution_arn,
          message: formTitle,
          name: formTitle,
          fields: expectedReplies
        }));
        logEvent(FORM_VIEW_EVENT, formTitle);
        dispatch(setWorkflowDetails({
          connectionId: response?.messageData?.my_connection_id,
        }));
      }
      dispatch(setBotTyping(false));
    }
    switch(response?.messageData?.node_type) {
      case WORKFLOW_START_NODE:
        logEvent(WORKFLOW_START_EVENT);
        dispatch(setWorkflowDetails({
          executionArn: response?.messageData?.execution_arn
        }));
        break;
      case WORKFLOW_END_NODE:
        logEvent(WORKFLOW_SUCCESS_EVENT);
        dispatch(workflowExecutionFinished());
        break;
      case WORKFLOW_ERROR_NODE:
        logEvent(WORKFLOW_FAILED_EVENT);
        dispatch(clearActiveForm());
        dispatch(workflowExecutionFailed());
        break;
      case WORKFLOW_MESSAGE_NODE:
        break;
      default:
        break;
    }
  }
};

export const handleAmazonConnectMessage = (data, sendMessage) => {
  return (dispatch, getState) => {
    try {
      if (data?.Type !== AMZ_CONNECT_MESSAGE
          && data?.Type !== AMZ_CONNECT_EVENT) {
        return;
      }
      if (data?.ParticipantRole === AMZ_CONNECT_CUSTOMER_ROLE) {
        return;
      }

      switch(data?.ContentType) {
        case AMZ_CONNECT_JOIN_EVENT:
          const record = getState()?.chat?.aiChatRecord;
          if (record?.length > 0) {
            sendMessage(
              record.length > 1024 ? `${record.slice(0, 1020)}...` : record,
              AMZ_CONNECT_TEXT_MARKDOWN
            );
          }
          dispatch(addConversation({
            type: AI_BOT,
            subtype: MESSAGE_RECEIVED,
            message: `${data?.DisplayName} has joined the chat.`
          }));
          return;
        case AMZ_CONNECT_LEAVE_EVENT:
          dispatch(addConversation({
            type: AI_BOT,
            subtype: MESSAGE_RECEIVED,
            message: `${data?.DisplayName} has left the chat.`
          }));
          return;
        case AMZ_CONNECT_CHAT_ENDED_EVENT:
          dispatch(switchBackToBot());
          return;
        case AMZ_CONNECT_TEXT_PLAIN:
        case AMZ_CONNECT_TEXT_MARKDOWN:
          if (data?.Content?.length > 0) {
            dispatch(addConversation({
              type: data?.ParticipantRole === AMZ_CONNECT_AGENT_ROLE
                ? AGENT
                : SYSTEM,
              realName: data?.ParticipantRole === AMZ_CONNECT_AGENT_ROLE
                ? data?.DisplayName
                : null,
              subtype: MESSAGE_RECEIVED,
              message: data?.Content
            }));
          }
          dispatch(setBotTyping(false));
          return;
        default:
          return;
      }
    } catch(error) {
      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: "Malformed message received"
      }));
    }
  };
};

export const sendLiveChatMessage = (content, sendJsonMessage) => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const system = reduxState?.chat?.chatMedium;
    const orgId = reduxState?.org?.details?.id;
    const endUser = reduxState?.chat?.endUser;
    const conversationId = reduxState?.chat?.chatRecordId;
    const slackUserIcon = reduxState?.chat?.slackUserIcon;

    const data = {
      system,
      orgId,
      endUser,
      conversationId,
      event: {
        type: "message",
        content
      }
    };

    if (system === CHAT_ON_SLACK) {
      data.displayName = endUser?.firstName || endUser?.email?.split("@")?.[0];
      data.avatarIcon = slackUserIcon;
    }

    try {
      sendJsonMessage({ action: "endUserEvent", data });
    } catch(error) {
      console.warn(`Readyly ChatAssist: failed to send message "${error.message}"`);
    }
  }
};

export const alertAgentConnected = () => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const customizations = reduxState?.org?.customizations;

    const connectedToAgent = getConfigValueByPersona(
      customizations?.chatConfig,
      "connectedToAgent",
      []
    );
    dispatch(addConversation({
      type: AI_BOT,
      subtype: MESSAGE_RECEIVED,
      message: connectedToAgent?.phrase
        || CONNECTED_TO_AGENT_PHRASE
    }));
  };
};

export const handleLiveChatMessage = (messageData, sendJsonMessage) => {
  return (dispatch, getState) => {
    try {
      const reduxState = getState();
      const data = messageData?.data;
      const event = data?.event;
      const participant = data?.type;
      const type = event?.type;

      if (participant !== AGENT && participant !== SYSTEM) {
        console.warn(
          `Readyly ChatAssist: message received from unrecognized participant "${participant}"`
        );
        return;
      }

      switch(type) {
        case LIVE_CHAT_MESSAGE:
          if (typeof(event?.content) !== "string") {
            break;
          }
          dispatch(addConversation({
            type: participant,
            avatarUrl: VALID_URL_REGEX.test(`${data?.avatarUrl}`)
              ? data.avatarUrl
              : null,
            realName: typeof(data?.realName) === "string"
              ? data.realName
              : null,
            subtype: MESSAGE_RECEIVED,
            message: event?.content
          }));
          break;
        case LIVE_CHAT_CONVERSATION_SUCCESS:
          dispatch(setBotTyping(false));
          if (event?.content?.isNew) {
            dispatch(alertAgentConnected());
            const aiChatRecord = reduxState?.chat?.aiChatRecord;
            const endUser = reduxState?.chat?.endUser;
            dispatch(sendLiveChatMessage(endUserDetails(endUser), sendJsonMessage));
            dispatch(sendLiveChatMessage(aiChatRecord, sendJsonMessage));
          }
          break;
        case LIVE_CHAT_CONVERSATION_FAILED:
          dispatch(setBotTyping(false));
          dispatch(switchToAgentFailed());
          break;
        case LIVE_CHAT_CONVERSATION_END:
          const agentLeft = getConfigValueByPersona(
            reduxState?.org?.customizations?.chatConfig,
            "agentLeft",
            reduxState?.chat?.endUser?.personas
          );
          dispatch(addConversation({
            type: AI_BOT,
            subtype: MESSAGE_RECEIVED,
            message: agentLeft?.phrase || AGENT_LEFT_PHRASE,
            repliesHelpText: agentLeft?.repliesHelpText,
            replies: Array.isArray(agentLeft?.replies)
              ? agentLeft.replies
              : []
          }));
          break;
        case LIVE_CHAT_CONVERSATION_JOIN:
          // dispatch(addConversation({
          //   type: AI_BOT,
          //   subtype: MESSAGE_RECEIVED,
          //   message: `${data?.realName || "Agent"} has joined the chat.`,
          // }));
          break;
        case LIVE_CHAT_CONVERSATION_LEFT:
          // dispatch(addConversation({
          //   type: AI_BOT,
          //   subtype: MESSAGE_RECEIVED,
          //   message: `${data?.realName || "Agent"} has left the chat.`,
          // }));
          break;
        case LIVE_CHAT_FILE_SHARE:
          dispatch(addConversation({
            type: participant,
            avatarUrl: VALID_URL_REGEX.test(`${data?.avatarUrl}`)
              ? data.avatarUrl
              : null,
            realName: typeof(data?.realName) === "string"
              ? data.realName
              : null,
            subtype: MESSAGE_RECEIVED,
            message: event?.content?.name || "download",
            attachment: {
              ...event?.content,
              system: data?.system,
              local: false
            }
          }));
          break;
        case LIVE_CHAT_EVENT_SUCCESS:
          break;
        case LIVE_CHAT_EVENT_FAILED:
          break;
        default:
          console.warn(
            `Readyly ChatAssist: cannot process message with unsupported type "${type}"`
          );
          break;
      }
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to parse live chat message");
    }
  };
};

export const handleSocketOpen = (
  event,
  chatWith,
  chatMedium,
  sendJsonMessage
) => {
  return (dispatch) => {
    if (chatWith !== CHAT_WITH_AGENT) {
      dispatch(executePendingTriggers(sendJsonMessage));
      return;
    }
    if (INTERNAL_LIVE_CHAT_SYSTEMS.some((sys) => sys === chatMedium)) {
      dispatch(handleLiveChatConnection(sendJsonMessage));
    }
  };
};

export const handleSocketReconnectStop = (event, chatMedium) => {
  return (dispatch, getState) => {
    const customizations = getState()?.org?.customizations;

    if (event?.target?.url?.startsWith(SOCKET_URL)
      || event?.target?.url?.startsWith(SMARTFLOWS_SOCKET_URL)
    ) {
      const botUnavailable = getConfigValueByPersona(
        customizations?.chatConfig,
        "onBotUnavailability",
        []
      );
      dispatch(addConversation({
        type: AI_BOT,
        subtype: MESSAGE_RECEIVED,
        message: botUnavailable?.phrase,
        repliesHelpText: botUnavailable?.repliesHelpText,
        replies: botUnavailable?.replies
      }));
      return;
    }
    if (chatMedium !== CHAT_ON_AMAZON_CONNECT) {
      dispatch(alertAgentDisconnected());
      dispatch(switchBackToBot());
    }
  };
};

export const handleMessageFromAgent = (messageData, chatMedium, sendJsonMessage) => {
  return (dispatch) => {
    if (INTERNAL_LIVE_CHAT_SYSTEMS.some((sys) => sys === chatMedium)) {
      dispatch(handleLiveChatMessage(messageData, sendJsonMessage));
    } else {
      console.warn("Readyly ChatAssist: received message from unknown system!");
    }
  }
}
