import { ARTICLE_IS_PDF } from "../constants/article";
import { END_USER, PUBLIC_ACCESS } from "../constants/user";

export function processArticleMeta(metaArray, limit = 5) {
  const ids = {};
  const result = [];

  const getId = (m) => `${m?.aid}-${m?.source}`;

  const appendSource = (meta) => {
    if (!meta?.aid
        || meta?.target !== END_USER
        || meta?.access !== PUBLIC_ACCESS
      ) {
      return;
    }
    if (getId(meta) in ids) {
      return;
    }
    ids[getId(meta)] = true;
    //! to support old ingested pdfs, new ones should have fileType set
    if (!Number.isNaN(parseInt(meta?.page_number))) {
      meta["fileType"] = ARTICLE_IS_PDF;
    }
    try {
      const url = new URL(meta?.source);
      result.push({
        ...meta,
        url: url.href,
        title: meta?.title ?? url.hostname
      });
    } catch(error) {
      result.push({ ...meta });
    }
  };

  if (Array.isArray(metaArray)) {
    for (const item of metaArray.slice(0, limit)) {
      appendSource(item);
    }
  } else {
    appendSource(metaArray);
  }
  return result;
};
