export const MOBILE_IOS = "mobile_ios";
export const TABLET_IOS = "tablet_ios";

export const MOBILE_ANDROID = "mobile_android";
export const TABLET_ANDROID = "tablet_android";

export const WEB = "web";
export const STANDALONE_WEB = "standalone_web";

export const SUPPORTED_PLATFORMS = [
  MOBILE_IOS,
  TABLET_IOS,
  MOBILE_ANDROID,
  TABLET_ANDROID,
  WEB,
  STANDALONE_WEB
];
