import axios  from "axios";

export const startConnectChat = async (
  url,
  data
) => {
  try {
    // sending JSON as plain/text to prevent preflight request
    const response = await axios.post(url, JSON.stringify(data));
    return response?.data;
  } catch (error) {
    throw error;
  }
};