const AUDIO_EVENTS = [
  "pause",
  "play",
  "ended",
  "abort",
  "error"
];

class TextToSpeechService {
  audio = null;

  $eventHandlers = {};

  constructor() {
    if (!TextToSpeechService.instance) {
      TextToSpeechService.instance = this;
    }
    return TextToSpeechService.instance;
  }

  get speaking() {
    return this.audio?.currentTime > 0
      && !this.audio?.paused
      && !this.audio?.ended
      && this.audio?.readyState > 2;
  }

  get paused() {
    return this.audio?.paused;
  }

  async speak(dataUrl, type, handlers = {}) {
    if (this.speaking || this.paused) {
      await this.cancel();
    }

    this.audio = new Audio(dataUrl);

    if (!this.audio.canPlayType(type)) {
      throw new Error(`Cannot play audio of type "${type}"`);
    }

    this.$addEventListeners(handlers);
    await this.$play();
  }

  async pause() {
    if (!this.audio || this.audio.paused) {
      return;
    }

    this.audio.removeEventListener("pause", this.$eventHandlers.pause);
    await this.audio.pause();
    this.audio.addEventListener("pause", this.$eventHandlers.pause);
  }

  async resume() {
    this.audio.removeEventListener("resume", this.$eventHandlers.resume);
    await this.$play();
    this.audio.removeEventListener("resume", this.$eventHandlers.resume);
  }

  async cancel() {
    if (!this.audio) {
      return;
    }

    this.$removeEventListeners();
    await this.audio.pause();
    this.$resetState();
  }

  async $play() {
    try {
      await this.audio.play();
    } catch(error) {
      this.$resetState();
      switch(error.name) {
        case "NotAllowedError":
          throw new Error("Permission denied! Cannot play audio at this time.");
        case "NotSupportedError":
          throw new Error("Cannot play the provided media.");
        default:
          throw new Error(`An error occured: "${error?.name}"`);
      }
    }
  }

  $resetState() {
    this.$eventHandlers = {};
    this.audio = null;
  }

  $addEventListeners(handlers = {}) {
    for (const event of AUDIO_EVENTS) {
      if (typeof(handlers?.[event]) !== "function") {
        continue;
      }
      this.$eventHandlers[event] = (evt) => {
        if (event !== "pause" && event !== "play") {
          this.$resetState();
        }
        if (typeof(handlers?.[event]) === "function") {
          handlers[event](evt);
        }
      }
      this.audio.addEventListener(event, this.$eventHandlers[event]);
    }
  }

  $removeEventListeners() {
    if (!this.audio) {
      return;
    }

    for (const event of Object.keys(this.$eventHandlers)) {
      this.audio.removeEventListener(event, this.$eventHandlers[event]);
    }
  }
}

const textToSpeechService = new TextToSpeechService()

export default textToSpeechService;
