import styled from "styled-components";
import { useTheme } from "styled-components";
import FormControl from "@mui/material/FormControl";
import NativeSelect from '@mui/material/NativeSelect';

import {
  StyledInputBase,
  StyledFormLabel
} from "../../../../../../common/formFields";
import FieldError from "../../../../../../common/FieldError";
import prepareLabel from "../../../../../../../lib/form/prepareLabel";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.75rem;
`;

const Wrapper = styled.div`
  width: 100%;
`;

function SelectField({ data, value, error, disabled, updateFieldState }) {
  const theme = useTheme();

  const handleChange = (event) => {
    updateFieldState(data?.field, event.target.value);
  };

  return (
    <Container>
      <StyledFormLabel
        customTheme={theme}
        title={data?.message}
        sx={{ display: "none" }}
      >
        {prepareLabel(data?.field)}
      </StyledFormLabel>
      <Wrapper>
        <FormControl fullWidth size={"small"}>
          <NativeSelect
            value={value}
            disabled={disabled}
            title={data?.message}
            onChange={handleChange}
            input={<StyledInputBase customTheme={theme} />}
          >
            <option aria-label="None" value="" disabled>
              {data?.message || prepareLabel(data?.field)}
            </option>
            {data?.values?.map((v) => <option key={v} value={v}>{v}</option>)}
          </NativeSelect>
        </FormControl>
        {error?.length > 0 && (
          <FieldError message={error} />
        )}
      </Wrapper>
    </Container>
  );
}

export default SelectField;
