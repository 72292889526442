export const REPLY_IS_QUERY = "query";
export const REPLY_IS_AUTO = "auto";
export const CUSTOM_ESCALATION = "customEscalation";
export const SWITCH_TO_AGENT = "switchToAgent";
export const CLOSE_CHAT = "closeChat";
export const REPLY_IS_QNA = "qna";
export const CREATE_TICKET = "createTicket";
export const EXECUTE_WORKFLOW = "executeWorkflow";
export const UPDATE_USER_DETAILS = "updateUserDetails";
export const CANCEL_QNA = "cancelQNA";
