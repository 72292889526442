import { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";

import { WebSocketContext } from "../../../../../../../App";
import useChat from "../../../../../../../hooks/chatHook";
import {
  REPLY_IS_AUTO,
  REPLY_IS_QNA,
  REPLY_IS_QUERY,
  SWITCH_TO_AGENT,
  CREATE_TICKET,
  CLOSE_CHAT,
  EXECUTE_WORKFLOW,
  UPDATE_USER_DETAILS,
  CANCEL_QNA,
  CUSTOM_ESCALATION
} from "../../../../../../../constants/replies";
import useLocalization from "../../../../../../../hooks/localization";

const RepliesContainer = styled(animated.div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-left: 2.5rem;
  max-width: min(100%, 580px);
`;

const Reply = styled.button`
  display: inline-flex;
  backface-visibility: hidden;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: ${({ theme }) => theme.fontColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.md};
  background-color: #fff;
  box-shadow: none;
  text-transform: none;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 1rem;
  cursor: pointer;
  transition: color 150ms ease-out,
    background-color 150ms ease-out;
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

function Replies({ replies, localizeText }) {
  const orgDetails = useSelector((state) => state?.org?.details);

  const webSocketContext = useContext(WebSocketContext);
  const { handleReplyOption } = useChat(webSocketContext, orgDetails, [], localizeText);

  const dropAnimation = useSpring({
    from: {
      y: -25,
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1
    }
  });

  const renderCallback = (reply) => {
    const query = localizeText(reply?.data?.query || "");

    switch(reply?.type) {
      case REPLY_IS_QUERY:
      case REPLY_IS_AUTO:
      case REPLY_IS_QNA:
      case CUSTOM_ESCALATION:
      case SWITCH_TO_AGENT:
      case CREATE_TICKET:
      case CLOSE_CHAT:
      case EXECUTE_WORKFLOW:
      case UPDATE_USER_DETAILS:
      case CANCEL_QNA:
        return (
          <Reply
            key={`${reply?.type}-${query}`}
            onClick={() => handleReplyOption(reply)}
          >
            {query}
          </Reply>
        );
      default:
        return null;
    }
  };

  return (
    <RepliesContainer style={dropAnimation}>
      {replies?.map(renderCallback)}
    </RepliesContainer>
  );
}

export default Replies;
