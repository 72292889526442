import {
  addConversation,
  setBotTyping
} from "../../redux/actions/chatActions";
import {
  loadSimilarityStart
} from "../../redux/actions/contentActions";
import { AI_BOT, END_USER } from "../../constants/user";
import { MESSAGE_RECEIVED } from "../../constants/chat";

export function chatWithAI(
  query,
  orgDetails,
  endUser,
  language,
  dispatch,
  sendJsonMessage,
  autoTextToSpeech = false
) {
  dispatch(setBotTyping(true));
  sendJsonMessage({
    action: "interact",
    orgid: orgDetails?.id,
    query,
    autoTextToSpeech,
    language,
    role: END_USER,
    endUser,
    personas: Array.isArray(endUser?.personas)
      ? endUser?.personas
      : []
  });
  dispatch(loadSimilarityStart());
  sendJsonMessage({
    action: "suggestions",
    orgid: orgDetails?.id,
    query,
    role: END_USER,
    endUser,
    personas: Array.isArray(endUser?.personas)
      ? endUser?.personas
      : []
  });
}

export function handleAutoReply(data, dispatch, autoTextToSpeech = false) {
  const response = data?.response;
  dispatch(setBotTyping(true));
  setTimeout(() => {
    dispatch(setBotTyping(false));
    dispatch(addConversation({
      autoTextToSpeech,
      type: AI_BOT,
      subtype: MESSAGE_RECEIVED,
      message: response,
      repliesHelpText: data?.repliesHelpText,
      replies: Array.isArray(data?.replies)
        ? data?.replies
        : []
    }));
  }, Math.round(Math.random() * 1_000) + 1_000);
}
