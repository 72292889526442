import { useRef, useEffect } from "react";

function useInfiniteScroll(currentCount, hasMore, isLoading, loadContent) {
  const rootRef = useRef(null);
  const lastElementRef = useRef(null);

  const setupIntersectionObserver = () => {
    if (isLoading || !hasMore) return;
    if (!currentCount) {
      loadContent();
      return;
    }
    if (!rootRef.current) return;

    const intersectionThreshold = 0.8;

    const intersectCallback = (entries) => {
      const entry = entries?.[0];
      if (!entry || !hasMore || isLoading) return;
      if (entry.intersectionRatio >= intersectionThreshold) {
        loadContent();
      }
    };

    const observer = new IntersectionObserver(intersectCallback, {
      root: rootRef.current,
      rootMargin: "0px",
      threshold: intersectionThreshold
    });

    if (lastElementRef.current) observer.observe(lastElementRef.current);

    return () => observer.disconnect();
  };

  useEffect(setupIntersectionObserver, [currentCount, hasMore, isLoading]);

  return {
    rootRef,
    lastElementRef
  };
}

export default useInfiniteScroll;
