import {
  MARKDOWN_LINKS,
  MARKDOWN_BOLD,
  MARKDOWN_ITALIC,
  MARKDOWN_EMPHASIS
} from "../constants/regex";

function handleBoldMarkdown(str) {
  const original = str;
  try {
    const matches = [...str.matchAll(MARKDOWN_BOLD)];
    for (const match of matches) {
      str = str.replace(match[0], `<strong>${match[1]}</strong>`);
    }
  } catch (error) {
    console.error(error);
    str = original;
  }
  return str;
}

function handleItalicMarkdown(str) {
  const original = str;
  try {
    const matches = [...str.matchAll(MARKDOWN_ITALIC)];
    for (const match of matches) {
      str = str.replace(match[0], `<em>${match[1]}</em>`);
    }
  } catch (error) {
    console.error(error);
    str = original;
  }
  return str;
}

function handleBoldAndItalicMarkdown(str) {
  const original = str;
  try {
    const matches = [...str.matchAll(MARKDOWN_EMPHASIS)];
    for (const match of matches) {
      str = str.replace(
        match[0],
        `<strong><em>${match[2] ?? match[3]}</em></strong>`
      );
    }
  } catch (error) {
    console.error(error);
    str = original;
  }
  return str;
}

function handleMarkdownLinks(str) {
  const original = str;
  try {
    const links = [...str.matchAll(MARKDOWN_LINKS)];
    for (const match of links) {
      str = str.replace(
        match[0],
        `<a target="_blank" rel="noreferrer" href="${match[2]}">${match[1]}</a>`
      );
    }
  } catch (error) {
    console.error(error);
    str = original;
  }
  return str;
}

export function markdownToHtml(str) {
  try {
    //* remove this once hallucinations are resolved
    str = str.replaceAll("support@sharkzen.com", "info@sharkzen.com");

    str = handleBoldAndItalicMarkdown(str);
    str = handleBoldMarkdown(str);
    str = handleItalicMarkdown(str);
    str = handleMarkdownLinks(str);
  } catch (error) {
    console.error(error);
  }
  return str;
};