import { useSelector } from "react-redux";

import Image from "./Image";
import DocumentCard from "./DocumentCard";

function Attachment({ id, url, name, mimeType, local }) {
  const file = useSelector((state) => state?.attachments?.files?.[id]);

  if (mimeType?.startsWith("image/")) {
    return (
      <Image
        status={file}
        url={url}
        name={name}
        local={local} />
    );
  }

  return (
    <DocumentCard
      status={file}
      url={url}
      name={name}
      local={local} />
  );;
}

export default Attachment;
