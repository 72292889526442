import axios from "axios";

import { BASE_AUTH_API_URL, AUTHORIZE_SESSION } from "../constants/endpoints";

export async function authorizeSession(
  orgName,
  conversationId,
  platform,
  profile,
  parentOrigin
) {
  const sessionKey = `${orgName}_${conversationId}`;
  try {
    window.sessionStorage.setItem(sessionKey, JSON.stringify({refreshing: true}));
  
    const response = await axios.post(
      `${BASE_AUTH_API_URL}/${AUTHORIZE_SESSION}`,
      {orgName, conversationId, profile, platform},
      {headers: {"X-Parent-Origin": parentOrigin ?? ""}}
    );
    const data = response?.data;

    window.sessionStorage.setItem(sessionKey, JSON.stringify({
      accessToken: data?.token,
      exp: Date.now() + (0.99 * 60 * 60 * 1000),
      refreshing: false
    }));
  } catch(error) {
    console.error(error);
    window.sessionStorage.setItem(sessionKey, JSON.stringify({refreshing: false}));
  }
}
