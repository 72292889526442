import styled from "styled-components";
import { useTheme } from "styled-components";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ErrorMessage = styled.p`
  display: flex;
  align-items: flex-start;
  padding-top: ${({ theme }) => theme.xxxs};
  color: ${({ theme }) => theme.red};
  font-size: ${({ theme }) => theme.sm};
  font-family: ${({ theme }) => theme.fontFamily};
`;

function FieldError({ message }) {
  const theme = useTheme();

  return (
    <ErrorMessage>
      <InfoOutlinedIcon sx={{ fontSize: theme.reg, color: "inherit", paddingTop: "0.125rem" }} />
      &nbsp;<span>{message}</span>
    </ErrorMessage>
  );
}

export default FieldError;
