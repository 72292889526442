export const CHAT_SECTION = "chat";
export const RESOURCES_SECTION = "resources";

export const CONTENT_IS_CHAT = "chat";
export const CONTENT_IS_ARTICLE = "article";

export const WIDGET_SIZE_MINIMAL = "minimal";
export const WIDGET_SIZE_EXPANDED = "expanded";

export const SMALL_PHONE = 390;
export const PHONE = 430;
export const TABLET = 600;
export const MIN_WIDTH_TO_EXPAND = 768;
export const LAPTOP = 1024;
export const WIDESCREEN = 1200;

export const READY_MESSAGE = "_ft_ready_";
export const OPEN_MESSAGE = "_ft_open_";
export const CLOSE_MESSAGE = "_ft_close_";
export const EXPAND_MESSAGE = "_ft_expand_";
export const MINIMIZE_MESSAGE = "_ft_minimize_";
export const LAUNCH_BTN_SIZE_MESSAGE = "_ft_launch_btn_size_";
export const USER_QUERY_EVENT = "_ft_user_query_";

export const ORIENTATION_RIGHT = "right";
export const ORIENTATION_LEFT = "left";
export const DEFAULT_ORIENTATION = ORIENTATION_RIGHT;
export const SUPPORTED_ORIENTATIONS = [
  ORIENTATION_LEFT,
  ORIENTATION_RIGHT
];