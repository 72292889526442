export const BASE_API_URL = `https://${
  process.env.REACT_APP_API_ID
}.execute-api.us-east-1.amazonaws.com/api`;

export const BASE_AUTH_API_URL = `https://${
  process.env.REACT_APP_AUTH_API_ID
}.execute-api.us-east-1.amazonaws.com/api`;

export const SOCKET_URL = `wss://${
  process.env.REACT_APP_WEBSOCKET_API_ID
}.execute-api.us-east-1.amazonaws.com/api`;
export const SMARTFLOWS_SOCKET_URL = `wss://${
  process.env.REACT_APP_SMARTFLOWS_WEBSOCKET_API_ID
}.execute-api.us-east-1.amazonaws.com/api`;

export const LIVE_CHAT_SOCKET_URL = `wss://${
  process.env.REACT_APP_LIVE_CHAT_WEBSOCKET_API_ID
}.execute-api.us-east-1.amazonaws.com/api`;

export const AUTHORIZE_SESSION = `/chat-assist/authorize`;

export const GET_IFRAME_WEBPAGE = `/get-iframe-webpage`;
export const UPLOAD_ATTACHMENT = `/upload-attachment`;
export const DOWNLOAD_ATTACHMENT = `/download-attachment`;

export const CREATE_TICKET = `/create-tickets`;

export const API_ORG_INFO = `/get-organisation-data`;
export const API_ORG_FT_ASSIST_CUSTOMIZATION_INFO = `/org-customizations/ft-assist`;

export const ARTICLES_V2 = `/articles`;

export const EXTERNAL_ARTICLES = `/external`;

export const CREATE_CHAT_RECORD_V2 = `/analytics/createChatRecord`;
export const CREATE_EVENT_RECORD_V2 = `/analytics/createEventRecord`;

export const SPEECH_OPERATIONS_API = `/speech`;
